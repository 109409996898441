(function () {
    'use strict';

    angular
        .module('pdgWp')
        .config(dashboardConfig);

    /** @ngInject */
    function dashboardConfig($stateProvider) {

        var home = {
            name   : 'dashboard',
            url    : '/dashboard',
            resolve: {
                ordersList: function (cartSrv) {

                    var configObj = {
                        orProStatusFtr: "ongoing",
                        orCreatedOrd  : 'ASC'
                    };

                    return cartSrv.getOrders(configObj)
                                  .then(function (newOrdersList) {
                                      return newOrdersList;
                                  });
                }
            },
            views  : {
                'main@': {
                    templateUrl : '/ng-app/admin-app/dashboard/dashboard.partial.html',
                    controller  : 'DashboardController',
                    controllerAs: 'dashCtrl'

                }
            }
        };

        $stateProvider.state(home);
    }

})();
