(function() {
  'use strict';

  angular
    .module('pdgWp')
    .controller('UsersListController', UsersListController);

  /** @ngInject */
  function UsersListController($log, $timeout, appConfig, usersSrv, $mdSidenav, $state) {

    var uListCtrl = this;

      uListCtrl.usersList = [];
      uListCtrl.limit = 25;
      uListCtrl.page = 1;
      uListCtrl.sort = {usFirstNameOdr:'ASC'};
      uListCtrl.filterBy = {};

      uListCtrl.$onInit = function(){

          uListCtrl.searchUsers();
      };

      uListCtrl.loadUser = function(usId){

          $state.go('users.userProfile', {usId:usId});

          if(! $mdSidenav('sideNavRight').isLockedOpen()){
              $mdSidenav('sideNavRight', true).close();
          }
      };

      uListCtrl.filterUsers = function(filterObj){

          uListCtrl.filterBy = filterObj;

          uListCtrl.updateSearch();
      };

      uListCtrl.sortBy = function(sortObj){

          uListCtrl.sort = lodash.forIn(uListCtrl.sort, function(value, key) {
              if(lodash.has(sortObj, key)){

                  uListCtrl.sort[key] = (value == 'ASC') ? 'DESC' : 'ASC';

              } else {
                  uListCtrl.sort = lodash.merge(uListCtrl.sort, sortObj);

                  delete uListCtrl.sort[key];
              }
          });

          if (lodash.isEmpty(uListCtrl.sort)) uListCtrl.sort = sortObj;

          uListCtrl.updateSearch();
      };

      uListCtrl.setPage = function (page){

          var page = 1 * page;

          if(-1 <= page <= 1){
              uListCtrl.page = (uListCtrl.page + page >= 1) ?  uListCtrl.page + page : 1;
          } else {
              uListCtrl.page = (page >= 1) ?  page : 1;
          }

          uListCtrl.searchUsers();
      };

      uListCtrl.updateSearch = function (searchText) {

          uListCtrl.searchText = (angular.isDefined(searchText)) ? searchText : uListCtrl.searchText;
          uListCtrl.page = 1;
          uListCtrl.searchUsers();
      };

      uListCtrl.searchUsers = function(page, limit){

          uListCtrl.limit = (angular.isDefined(limit)) ? limit : uListCtrl.limit;

          uListCtrl.page = (angular.isDefined(page)) ? page : uListCtrl.page;

          var configObj = {
              employees: true,
              usSearchFtr:uListCtrl.searchText,
              getPaging: true,
              limit: uListCtrl.limit,
              page: uListCtrl.page

          };

          configObj = lodash.merge(configObj, uListCtrl.sort, uListCtrl.filterBy);

          configObj.progressBar = 'sideNavRight@';

          return usersSrv.getUsers(configObj)
                         .then(function (usersList) {
                             uListCtrl.page = 1 * usersList.config.page;
                             uListCtrl.limit = 1 * usersList.config.limit;
                             uListCtrl.count = 1 * usersList.count;

                             uListCtrl.usersList = usersList.data ;

                             return uListCtrl.usersList.data;
                         });
      }



  }
})();
