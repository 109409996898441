(function () {
    'use strict';

    angular
        .module('pdgWp')
        .component('inlineInput', {
            template    : '<div tabindex="-1" id="{{ctrl.localIdString}}">{{ctrl.localValue}}</div>',
            bindings    : {
                inputValue   : '<',
                autoSelect   : '<',
                idString     : '@',
                onValueChange: '&'
            },
            controller  : inlineInputController,
            controllerAs: 'ctrl'

        });

    /** @ngInject */
    function inlineInputController($scope, $window, $element, $log, $compile, $timeout) {

        var ctrl = this;

        var inputElement;

        var inputTemplate = '<div tabindex="-1" id="{{ctrl.localIdString}}"><input type="text" ng-model="ctrl.localValue"></div>';

        var normalTemplate = '<div tabindex="-1" id="{{ctrl.localIdString}}">{{ctrl.localValue}}</div>';

        ctrl.$onChanges = function (changesObj) {

            if(changesObj.inputValue.currentValue !== changesObj.inputValue.previousValue){

                ctrl.localValue = angular.copy(ctrl.inputValue);
            }

        };

        ctrl.showInput = function () {

            clearBindings();


            $element.children().replaceWith(compileInput(inputTemplate));
            $timeout(function () {

                inputElement = $element.find('input');

                inputElement = inputElement[0];

                inputElement.focus();

                if (!$window.getSelection().toString() && ctrl.autoSelect) {
                    // Required for mobile Safari

                    inputElement.setSelectionRange(0, inputElement.value.length)
                }
            }, 0);
            $element.children().on('blur', ctrl.hideInput);
            $element.children().children().on('blur', ctrl.hideInput);
        };

        function clearBindings() {
            $element.children().off();
            $element.children().children().off();
        }

        ctrl.hideInput = function () {
            clearBindings();
            $element.children().replaceWith(compileInput(normalTemplate));
            // $element.children().on('click', ctrl.showInput);
            $element.children().on('focus', ctrl.showInput);

            if (ctrl.localValue != ctrl.inputValue) ctrl.change();

        };

        ctrl.$onInit = function () {
            ctrl.localValue = angular.copy(ctrl.inputValue);
            ctrl.autoSelect = ctrl.autoSelect || false;
            ctrl.localIdString = (angular.isDefined(ctrl.idString)) ? ctrl.idString : '';
            // $element.children().on('click', ctrl.showInput)
            $element.children().on('focus', ctrl.showInput)

            // ctrl.hideInput();
        };

        ctrl.$destroy = function () {
            // clearBindings();
        };

        function compileInput(template) {
            return $scope.$apply(function () {
                return $compile(template)($scope);
            })

        }

        ctrl.change = function () {
            ctrl.onValueChange({'value': ctrl.localValue});
        };


    }
})();
