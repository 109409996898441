(function () {
    'use strict';

    angular
        .module('pdgWp')
        .controller('ClientProfileController', ClientProfileController);

    /** @ngInject */
    function ClientProfileController($rootScope, $scope, $log, $state, appConfig, usersSrv, clientProfile, cartSrv, $mdDialog) {

        var cltProfileCtrl = this;

        cltProfileCtrl.currentUser = clientProfile;

        cltProfileCtrl.userSalutations = appConfig.userSalutations;

        cltProfileCtrl.countries = appConfig.countries;

        cltProfileCtrl.getUser = function () {

            var configObj = {
                clients    : true,
                usStatusFtr: 'all',
                getRow     : true,
                usIdFtr    : clientProfile.usId
            };

            usersSrv.getUsers(configObj)
                    .then(function (userObj) {

                        cltProfileCtrl.currentUser = userObj;

                        return userObj;
                    });
        };

        cltProfileCtrl.updateUsers = function (usersObj) {

            if (cltProfileCtrl.clientProfileForm.$invalid) return;

            usersObj = (cltProfileCtrl.clientProfileForm.$$success.parse.length > 1) ? cltProfileCtrl.currentUser : usersObj;

            if(lodash.has(usersObj, 'authRole')){
                usersObj.uscHistory = "Client Access Level changed to: " + usersObj['authRole'];
            }

            usersSrv.updateUsers(usersObj)
                    .then(function successCallback(usersObj) {

                        $rootScope.$broadcast('event:queue-message', 'Modifications has been saved');

                        return usersObj;
                    });
        };

        cltProfileCtrl.addHistoryNote = function (comment) {
            usersSrv.updateUsers({
                usId        : cltProfileCtrl.currentUser.usId,
                uscHistory: 'NOTE: ' + comment
            }).then(function successCallback() {
                cltProfileCtrl.getUser();
            });

        };

        // cltProfileCtrl.printHistory = function () {
        //
        //     $mdDialog.show({
        //         clickOutsideToClose: true,
        //         locals             : {orId: cltProfileCtrl.currentOrder.orId},
        //         bindToController   : true,
        //         templateUrl           : '/ng-app/admin-app/web-orders-mgr/orders/orders-details-tabs/orders-history-print.partial.html',
        //         controllerAs       : 'dlgCtrl',
        //         controller         : function DialogController($mdDialog, appConfig, $window, $document) {
        //
        //             var dlgCtrl = this;
        //
        //             dlgCtrl.print = function(){
        //                 var iframe = document.getElementById("ordersPrintHistoryIframe");
        //                 var iframeWindow = (iframe.contentWindow || iframe.contentDocument);
        //                 iframeWindow.print();
        //             };
        //
        //             dlgCtrl.width = ($window.innerWidth || $document.documentElement.clientWidth || $document.body.clientWidth) / 2;
        //
        //             dlgCtrl.height = ($window.innerHeight || $document.documentElement.clientHeight || $document.body.clientHeight) / 1.3;
        //
        //             dlgCtrl.url = appConfig.siteUrl + 'printer/orders_history/' + dlgCtrl.orId;
        //             dlgCtrl.closeDialog = function () {
        //                 $mdDialog.hide();
        //             }
        //         }
        //     });
        // };

        cltProfileCtrl.changePassword = function (isNewUser) {

            isNewUser = (angular.isDefined(isNewUser)) ? isNewUser : false;

            $mdDialog.show({
                clickOutsideToClose: true,
                locals             : (isNewUser) ? {isNewUser: true} : {
                    usId   : cltProfileCtrl.currentUser.usId,
                    usEmail: cltProfileCtrl.currentUser.usEmail
                },
                bindToController   : true,
                templateUrl        : '/ng-app/admin-app/website-admin/users/user-change-password.partial.html',
                controllerAs       : 'pwdChangeCtrl',
                controller         : function PasswordChangeController($mdDialog, $state, appConfig, usersSrv) {

                    var pwdChangeCtrl = this;

                    pwdChangeCtrl.changePassword = function () {

                        var usPassword = null;

                        if (angular.isDefined(pwdChangeCtrl.usPassword)) {
                            if (pwdChangeCtrl.usPassword == '' || pwdChangeCtrl.usPassword.length < 8) {
                                alert('Password not valid. Please select a new one.')
                                return;
                            } else {
                                usPassword = pwdChangeCtrl.usPassword;
                            }
                        }

                        var configObj = {
                            usEmail    : pwdChangeCtrl.usEmail,
                            usId       : pwdChangeCtrl.usId,
                            usPassword : usPassword,
                            uscStatus  : (isNewUser) ? '1' : null,
                            authRole   : (isNewUser) ? 'anonymous' : null,
                            usEmailSend: (pwdChangeCtrl.sendEmail) ? true : null,
                            uscHistory : (isNewUser) ? 'Client profile created.' : 'Password/email address updated'
                        };

                        usersSrv.updateUsers(configObj)
                                .then(function successCallback(usersObj) {
                                    $mdDialog.hide();

                                    $rootScope.$broadcast('event:queue-message', 'Modifications has been saved');

                                    if (isNewUser) {
                                        $state.go('clients.clientProfile', {usId: usersObj.usId});
                                    }

                                    return usersObj;
                                });

                    };

                    pwdChangeCtrl.cancel = function () {
                        $mdDialog.hide();
                    }
                }
            })
                     .then(function () {
                         cltProfileCtrl.getUser();
                     });
        }
        
        // ======================================================================
        // Client Order List
        // ======================================================================

        cltProfileCtrl.ordersList = [];
        cltProfileCtrl.limit = 8;
        cltProfileCtrl.page = 1;
        cltProfileCtrl.status = 'all';

        cltProfileCtrl.sortDirection = 'DESC';
        cltProfileCtrl.sortType = 'orCreatedOrd';

        cltProfileCtrl.sort = {orCreatedOrd: cltProfileCtrl.sortDirection};

        cltProfileCtrl.filter = {usIdFtr: cltProfileCtrl.currentUser.usId};

        cltProfileCtrl.loadOrder = function (orId) {

            $state.go('orders.orderDetails', {orId: orId});

            if (!$mdSidenav('sideNavRight').isLockedOpen()) {
                $mdSidenav('sideNavRight', true).close();
            }
        };

        cltProfileCtrl.sortBy = function (sortType, sortDirection) {

            lodash.forEach(cltProfileCtrl.sort, function (value, key) {
                delete cltProfileCtrl.sort[key];
            });

            cltProfileCtrl.sort[sortType] = sortDirection;

            cltProfileCtrl.updateSearch();
        };

        cltProfileCtrl.setPage = function (page) {

            var page = 1 * page;

            if (-1 <= page <= 1) {
                cltProfileCtrl.page = (cltProfileCtrl.page + page >= 1) ? cltProfileCtrl.page + page : 1;
            } else {
                cltProfileCtrl.page = (page >= 1) ? page : 1;
            }

            cltProfileCtrl.searchOrders();
        };

        cltProfileCtrl.filterSearch = function () {

            cltProfileCtrl.filter = {orProStatusFtr: cltProfileCtrl.status};

            cltProfileCtrl.searchOrders();

        };

        cltProfileCtrl.updateSearch = function (searchText) {

            cltProfileCtrl.searchText = (angular.isDefined(searchText)) ? searchText : cltProfileCtrl.searchText;
            cltProfileCtrl.page = 1;
            cltProfileCtrl.searchOrders();
        };

        cltProfileCtrl.searchOrders = function (page, limit) {

            cltProfileCtrl.limit = (angular.isDefined(limit)) ? limit : cltProfileCtrl.limit;

            cltProfileCtrl.page = (angular.isDefined(page)) ? page : cltProfileCtrl.page;

            var configObj = {
                orCartFlxFtr: cltProfileCtrl.searchText,
                getPaging   : true,
                limit       : cltProfileCtrl.limit,
                page        : cltProfileCtrl.page
            };

            if (configObj.orCartFlxFtr === '') delete configObj.orCartFlxFtr;

            configObj = lodash.merge(configObj, cltProfileCtrl.sort, cltProfileCtrl.filter);

            configObj.progressBar = 'main@';

            return cartSrv.getOrders(configObj)
                          .then(function (ordersList) {
                              cltProfileCtrl.page = 1 * ordersList.config.page;
                              cltProfileCtrl.limit = 1 * ordersList.config.limit;
                              cltProfileCtrl.count = 1 * ordersList.count;

                              cltProfileCtrl.ordersList = ordersList.data;

                              return cltProfileCtrl.ordersList.data;
                          });
        };

        cltProfileCtrl.sortOrderList = function () {

            switch (sortType) {
                case 'ordered_date':
                    // code block
                    break;
                case y:
                    // code block
                    break;
                default:
                // code block
            }

        };

        cltProfileCtrl.$onInit = function(){
            cltProfileCtrl.searchOrders();
        };
    }
})();
