(function () {
    'use strict';

    angular
        .module('pdgWp')
        .component('itemsMediaUpload', {
            templateUrl : '/ng-app/admin-app/components/items-media-upload/items-media-upload.partial.html',
            bindings    : {
                gpGid        : '<',
                onMediaChange: '&',
            },
            controller  : ItemsMediaUploadController,
            controllerAs: 'ctrl'
        });

    /** @ngInject */
    function ItemsMediaUploadController($log, $timeout, $q, $rootScope, mediaSrv) {

        var ctrl = this;

        ctrl.showSpinner = false;

        ctrl.getExtMedia = function (pastedUrl) {

            var regex = /^(?:https?:\/\/)/;

            ctrl.pastedUrl = '';

            if (!regex.test(pastedUrl)) {
                $rootScope.$broadcast('event:queue-message', {
                    type   : 'error',
                    message: 'URL missing "HTTPS://" at the beginning.'
                });

                return $q.reject();
            }

            if (!mediaSrv.isValidMedia(pastedUrl) && !mediaSrv.isYoutubeUrl(pastedUrl)) {

                $rootScope.$broadcast('event:queue-message', {
                    type   : 'error',
                    message: 'Invalid External Media. Please review.'
                });

                return $q.reject();
            }

            ctrl.showSpinner = true;

            return mediaSrv.getExtMedia({
                pastedUrl: pastedUrl,
                gpGid    : ctrl.gpGid
            })
                           .then(function successCallback(mediaObj) {
                               // console.info("ctrl.getExtMedia")

                               ctrl.uploadCompleted(mediaObj, 'The external media has been uploaded.');

                               return mediaObj;
                           }, function (response) {

                               ctrl.showSpinner = false;

                               $rootScope.$broadcast('event:queue-message', {
                                   type   : 'error',
                                   message: 'STATUS: ' + response.statusText + ' - ' + response.data
                               });
                               return Promise.reject(response);
                           });
        };

        ctrl.uploadItemMedia = function (uploadedFile) {

            ctrl.showSpinner = true;

            return ctrl.updateMedia({gpGid: ctrl.gpGid}, uploadedFile)
                       .then(function (mediaObj) {
                           // console.info("ctrl.uploadItemMedia")

                           ctrl.uploadCompleted(mediaObj, 'The local media has been uploaded.');

                           return mediaObj;
                       }, function (response) {

                           ctrl.showSpinner = false;

                           $rootScope.$broadcast('event:queue-message', {
                               type   : 'error',
                               message: 'STATUS: ' + response.statusText + ' - ' + response.data
                           });
                           return Promise.reject(response);
                       });
        };

        ctrl.uploadCompleted = function (mediaObj, msg) {
            // console.info("ctrl.uploadCompleted")

            ctrl.showSpinner = false;

            $rootScope.$broadcast('event:queue-message', msg);

            $rootScope.$broadcast('event:media-flow-changed', mediaObj);

            switch (mediaObj.mdType) {
                case 'photo':

                    $rootScope.$broadcast('event:photo-edit', mediaObj);

                    break;
                case 'youtube':

                    break;
                case 'document':

                    break;
                default:

            }
        };

        // ctrl.requestEdit = lodash.debounce(function (mediaObj) {
        //     console.info("ctrl.requestEdit");
        //     $timeout(function(){
        //         $rootScope.$broadcast('event:photo-edit', mediaObj);
        //     },500);
        //     // $rootScope.$broadcast('event:photo-edit', mediaObj);
        // }, 2000, {
        //     'leading' : true,
        //     'trailing': false
        // });


        ctrl.sendToBin = function (index, item, external, type) {

            if(item.mdIsCatalog === '1'){
                alert('Catalog images cannot be deleted. Please select a new catalog image first.');
                return false;
            }
            if(item.mdOrder === '0') {
                alert('The first image (used for search results) cannot be deleted. Please re-order the images first to designate the new search results image.');
                return false;
            }


            ctrl.updateMedia({
                mdId    : item.mdId,
                mdStatus: '0'
            })
                .then(function (mediaObj) {

                    $rootScope.$broadcast('event:queue-message', 'The media was sent to the Media Bin.');

                    $rootScope.$broadcast('event:media-flow-changed', mediaObj);
                });
        };

        ctrl.updateMedia = function (mediaObj, uploadedFile) {

            return mediaSrv.updateMedia(mediaObj, uploadedFile)
                           .then(function successCallback(mediaObj) {

                               return mediaObj;
                           });
        };

    } // ===================================================
})();
