(function () {
    'use strict';

    angular
        .module('pdgWp')
        .component('catalogPage', {
            template: '<div ng-include="ctrl.getTemplate()">',
            bindings    : {
                pageLayout           : '<',
                pageItems           : '<',
                pageTemplate           : '<'
            },
            controller  : CatalogPageController,
            controllerAs: 'ctrl'
        });

    /** @ngInject */
    function CatalogPageController($log, $element) {

        var ctrl = this;

        ctrl.currentItem = {};

        ctrl.$onInit = function () {
            // ctrl.getHeight();
        };

        ctrl.getTemplate = function () {
            // $log.info('/ng-app/admin-app/catalog-admin/catalog-templates/' + ctrl.pageTemplate + '.partial.html');
            return '/ng-app/admin-app/catalog-admin/catalog-templates/' + ctrl.pageTemplate + '.partial.html';
        };

    }
})();
