(function () {
    'use strict';

    angular
        .module('pdgWp')
        .controller('CatalogNavController', CatalogNavController);

    /** @ngInject */
    function CatalogNavController(catalogSrv, $mdSidenav, $state, $stateParams) {

        var catNavCtrl = this;

        // catNavCtrl.catalogs = {
        //     NULL         : null,
        //     catCatalogId : "1",
        //     catChapId    : null,
        //     catId        : "1",
        //     catLevel     : "0",
        //     catOrder     : null,
        //     catPageCount : null,
        //     catPageNumber: null,
        //     catParent    : null,
        //     catSectId    : null,
        //     catStatus    : "1",
        //     catTemplate  : null,
        //     catType      : "meta"
        // }

        catNavCtrl.currentCatObj = {};

        catNavCtrl.currentCatObj.catCatalogId = 1;

        catNavCtrl.navigation = [];

        catNavCtrl.foldingArray = [];

        catNavCtrl.$onInit = function () {

            // if(angular.isDefined($stateParams.catCatalogId) && $stateParams.catCatalogId != ""){
            //
            //     catNavCtrl.currentCatObj = lodash.find(catNavCtrl.catalogs, { 'catCatalogId': $stateParams.catCatalogId});
            //
            //
            // }


            catNavCtrl.getNav();
        };

        catNavCtrl.getNav = function () {

            var configObj = {
                catStructureName: true,
                catCatalogIdFtr : catNavCtrl.currentCatObj.catCatalogId
            };

            return catalogSrv.getCatalogObj(configObj)
                             .then(function (catalogNav) {

                                 catNavCtrl.navigation = catalogNav;

                                 // $state.go('catalogs', {catCatalogId:catNavCtrl.currentCatObj});

                                 return catalogNav;
                             });
        };

        catNavCtrl.loadPage = function (catId) {

            $state.go('catalog.page', {catId:catId});

            if(! $mdSidenav('sideNavRight').isLockedOpen()){
                $mdSidenav('sideNavRight', true).close();
            }


        };

        catNavCtrl.loadBuilder = function (catId) {

            $state.go('catalogs.builder', {catId:catId});

            if(! $mdSidenav('sideNavRight').isLockedOpen()){
                $mdSidenav('sideNavRight', true).close();
            }


        };

        catNavCtrl.loadLayout = function (catChapIdFtr) {

            $state.go('catalogs.layout', {catChapIdFtr:catChapIdFtr});

            // if(! $mdSidenav('sideNavRight').isLockedOpen()){
            //     $mdSidenav('sideNavRight', true).close();
            // }


        };

        catNavCtrl.loadNav = function () {

            $state.go('catalogs', {catCatalogId:catNavCtrl.currentCatObj.catCatalogId});

        };


        catNavCtrl.foldNav = function (id) {

            var idIndex = catNavCtrl.foldingArray.indexOf(id);

            if(idIndex == -1){

                catNavCtrl.foldingArray.push(id);

            } else {

                catNavCtrl.foldingArray.splice(idIndex, 1);
            }


            return true;
        };


        catNavCtrl.isExpended = function (id) {

            return (catNavCtrl.foldingArray.indexOf(id) !== -1);
        }

    }
})();
