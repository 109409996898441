(function () {
    'use strict';

    angular
        .module('pdgWp')
        .controller('ReportsController', ReportsController);

    /** @ngInject */
    function ReportsController() {

        var reportsCtrl = this;

    }

})();
