(function () {
    'use strict';

    angular
        .module('pdgWp')
        .controller('MsgController', MsgController);

    /** @ngInject */
    function MsgController($mdToast, msgObj  ) {

        var msgCtrl = this;

        msgCtrl.msgObj = msgObj;

        msgCtrl.showDetails = false;

        msgCtrl.close =function () {
            $mdToast.hide();
        }

    }
})();
