(function () {
    'use strict';

    angular
        .module('pdgWp')
        .controller('ItemDetailsController', ItemDetailsController);

    /** @ngInject */
    function ItemDetailsController($rootScope, $window, $timeout, $scope, $log, $state, $stateParams, appConfig, itemsSrv, itemDetails, $mdDialog, deepL, mediaSrv, structureSrv) {

        var itemDetailsCtrl = this;

        itemDetailsCtrl.__env = __env;

        itemDetailsCtrl.catalogUrl = itemDetailsCtrl.__env.catalogUrl + itemDetailsCtrl.__env.catalogFile;

        itemDetailsCtrl.catalogPdfUrl = itemDetailsCtrl.__env.catalogUrl + itemDetailsCtrl.__env.catalogPdfFile;

        itemDetailsCtrl.structureArray = structureSrv.fullArray;

        itemDetailsCtrl.itemUrl = itemDetailsCtrl.__env.baseUrl + 'articles/view/';

        itemDetailsCtrl.currentItem = itemDetails;

        itemDetailsCtrl.imgUrl = 'media/common/recycled-logo.jpg';

        itemDetailsCtrl.mediaPhotoObj = {};

        itemDetailsCtrl.catalogImage = {};

        itemDetailsCtrl.appConfig = appConfig;


        itemDetailsCtrl.mediaPhotoEnabled = true;
        itemDetailsCtrl.mediaYoutubeEnabled = true;
        itemDetailsCtrl.mediaDocumentEnabled = true;
        itemDetailsCtrl.mediaBinEnabled = false;

        itemDetailsCtrl.catalogPartial = '/ng-app/admin-app/catalogs-templates/pdg-catalog-2017-2018/single-template.partial.html';
        itemDetailsCtrl.catalogStyle = 'pdg-catalog-2017-2018';


        itemDetailsCtrl.loadDetails = function (gpGid) {

            var configObj = {
                expandGid  : true,
                gpStatusFtr: 'all',
                itStatusFtr: 'all',
                fullProfile: true, // itemsReq    : true,
                prices     : true,
                adminOnly  : true,
                media      : false,
                getRow     : true,
                gpGidFtr   : gpGid
            };

            configObj.progressBar = 'main@';

            return itemsSrv.getItems(configObj)
                           .then(function (itemObj) {

                               itemDetailsCtrl.currentItem = itemObj;

                               itemDetailsCtrl.currentItem.catalogImage = lodash.find(itemDetailsCtrl.currentItem.gpMediaJson.photo, ['mdIsCatalog', '1']);

                               return itemObj;
                           });


        };

        itemDetailsCtrl.updateItems = function (itemsObj, formObj) {

            if (angular.isDefined(formObj) && formObj.$invalid) return;

            itemsObj.progressBar = 'main@';

            itemsSrv.updateItems(itemsObj)
                    .then(function successCallback(response) {

                        itemDetailsCtrl.currentItem = lodash.merge(itemDetailsCtrl.currentItem, itemsObj);

                        $rootScope.$broadcast('event:queue-message', 'Modifications has been saved');

                        return response;
                    }, function () {

                        $state.reload();

                    });
        };

        itemDetailsCtrl.articlesDraft = function (article) {

            article.itDraftStatus = (article.itDraftStatus === '1') ? '0' : '1';

            itemsSrv.updateArticles({'itId':article.itId, 'itDraftStatus': article.itDraftStatus})
                    .then(function successCallback(response) {

                        // var tempObj = lodash.find(itemDetailsCtrl.currentItem.gpItems, ['itId', itemsObj.itId]);
                        //
                        // lodash.merge(tempObj, itemsObj);
                        //
                        // $rootScope.$broadcast('event:queue-message', 'Modifications has been saved');

                        return response;
                    }, function () {

                        // $state.reload();

                    });

        };


        itemDetailsCtrl.updateArticles = function (itemsObj, formObj) {

            if (angular.isDefined(formObj) && formObj.$invalid) return;

            itemsObj.progressBar = 'main@';

            itemsSrv.updateArticles(itemsObj)
                    .then(function successCallback(response) {

                        var tempObj = lodash.find(itemDetailsCtrl.currentItem.gpItems, ['itId', itemsObj.itId]);

                        lodash.merge(tempObj, itemsObj);

                            $rootScope.$broadcast('event:queue-message', 'Modifications has been saved');

                        return response;
                    }, function () {

                        $state.reload();

                    });
        };

        itemDetailsCtrl.clearFormat = function (obj, propertyName) {

            var div = document.createElement("div");
            var tempObj = {};

            div.innerHTML = obj[propertyName];
            tempObj[propertyName] = div.textContent || div.innerText || "";
            obj = lodash.merge(obj, tempObj);

            tempObj.gpGid = obj.gpGid;

            itemDetailsCtrl.updateItems(tempObj, itemDetailsCtrl.itemDetailsForm);

        };

        itemDetailsCtrl.reorderArticles = function (itemsObj) {

            itemsObj.progressBar = 'main@';

            itemsSrv.reorderArticles(itemsObj)
                    .then(function successCallback(response) {

                        itemDetailsCtrl.loadDetails(itemDetailsCtrl.currentItem.gpGid);

                        $rootScope.$broadcast('event:queue-message', 'Modifications has been saved');

                        return response;
                    }, function () {

                        $state.reload();

                    });
        };

        itemDetailsCtrl.createItem = function (item) {

            var a = $stateParams.gpGid;

            item = (angular.isDefined(item)) ? item : {};

            $mdDialog.show({
                // clickOutsideToClose: true,
                locals          : {item: item},
                fullscreen      : true,
                bindToController: true,
                templateUrl     : '/ng-app/admin-app/components/items-creator/items-creator-dlg.partial.html',
                controllerAs    : 'itemCreatorDlgCtrl',
                controller      : function ItemCreatorDlgController($mdDialog) {

                    var itemCreatorDlgCtrl = this;

                    itemCreatorDlgCtrl.itemModified = function () {
                        $mdDialog.hide();
                    };

                    itemCreatorDlgCtrl.cancel = function () {
                        $mdDialog.hide();
                    }
                }
            })
                     .then(function (response) {

                         if ($stateParams.gpGid === response.itemsObj.gpGid || $stateParams.gpGid === response.itemsObj.idxGidMod) {

                             itemDetailsCtrl.loadDetails(response.itemsObj.gpGid || response.itemsObj.idxGidMod);

                         }
                         else {

                             $state.go('items.itemDetails', {gpGid: response.itemsObj.gpGid || response.itemsObj.idxGidMod});
                         }

                     }, function (response) {

                     });
        };

        itemDetailsCtrl.translate = function (input, output, focusId) {

            focusId = focusId || false;

            deepL.translate(input);
            // .then(function (response) {
            //     var tempObj = {};
            //     tempObj[focusId] = response
            //     output = lodash.merge(output, tempObj)
            //     // output = response;
            //
            // }, function () {
            //
            //     if (focusId) itemDetailsCtrl.setFocus(focusId);
            // });
        };

        itemDetailsCtrl.titleCase = function (fieldName) {

            var tempObj = {};

            tempObj[fieldName] = itemsSrv.titleCase(itemDetailsCtrl.currentItem[fieldName]);

            itemDetailsCtrl.currentItem = lodash.merge(itemDetailsCtrl.currentItem, tempObj);

            if (fieldName == 'gpTitle_en') {

                itemDetailsCtrl.updateItems({
                    gpGid     : itemDetailsCtrl.currentItem.gpGid,
                    gpTitle_en: itemDetailsCtrl.currentItem.gpTitle_en
                }, itemDetailsCtrl.itemDetailsForm)

            }
            else {

                itemDetailsCtrl.updateItems({
                    gpGid     : itemDetailsCtrl.currentItem.gpGid,
                    gpTitle_fr: itemDetailsCtrl.currentItem.gpTitle_fr
                }, itemDetailsCtrl.itemDetailsForm)
            }
        };

        itemDetailsCtrl.upperCase = function (fieldName) {

            var tempObj = {};

            tempObj[fieldName] = itemDetailsCtrl.currentItem[fieldName].toUpperCase();

            itemDetailsCtrl.currentItem = lodash.merge(itemDetailsCtrl.currentItem, tempObj);

            if (fieldName == 'gpTitle_en') {

                itemDetailsCtrl.updateItems({
                    gpGid     : itemDetailsCtrl.currentItem.gpGid,
                    gpTitle_en: itemDetailsCtrl.currentItem.gpTitle_en
                }, itemDetailsCtrl.itemDetailsForm)

            }
            else {

                itemDetailsCtrl.updateItems({
                    gpGid     : itemDetailsCtrl.currentItem.gpGid,
                    gpTitle_fr: itemDetailsCtrl.currentItem.gpTitle_fr
                }, itemDetailsCtrl.itemDetailsForm)
            }
        };


        itemDetailsCtrl.setFocus = function (focusId) {

            var element = angular.element($window.document.getElementById(focusId));

            $timeout(function () {

                if (element) {

                    element = element[0];

                    element.focus();

                    $timeout(function () {

                        element.setSelectionRange(0, element.value.length);

                    }, 0);
                }

            }, 0);
        };


        itemDetailsCtrl.isItemDraftEnabled = function(gpItems){

            return ! lodash.some(gpItems, {'itDraftStatus' : '1'});
        };


        itemDetailsCtrl.editPhoto = function (mediaObj) {

            var configObj = {
                mdIdFtr : mediaObj.mdId,
                detailed: true,
                getRow  : true
            };
            mediaSrv.getMedia(configObj)
                    .then(function successCallback(response) {

                        var mediaUrl = './ci-uploads/photos/' + response.mdOrigName;

                        mediaSrv.imgCacheBuster([mediaUrl])
                                .then(function () {

                                    itemDetailsCtrl.loadCropper(mediaObj, mediaUrl);
                                });
                    })
        };

        itemDetailsCtrl.loadCropper = function (mediaObj, url) {

            $mdDialog.show({

                locals          : {mediaUrl: (angular.isDefined(url)) ? url : mediaObj.mdMediaUrl, mediaWidth:640, mediaHeight:480},
                fullscreen      : true,
                bindToController: true,
                templateUrl     : '/ng-app/admin-app/components/cropper-js/cropper-js-dlg.partial.html',
                controllerAs    : 'cropperJsDlg',
                controller      : function ($mdDialog) {

                    var cropperJsDlg = this;

                    cropperJsDlg.imgOut = '';

                    cropperJsDlg.imageModified = function (imgOut) {
                        cropperJsDlg.imgOut = imgOut;
                    };

                    cropperJsDlg.save = function () {
                        $mdDialog.hide(cropperJsDlg.imgOut);
                    };

                    cropperJsDlg.cancel = function () {
                        $mdDialog.cancel();
                    };
                }
            })
                     .then(function (response) {
                         itemDetailsCtrl.saveImage(mediaObj, response);
                     }, function (response) {

                     });

        };

        itemDetailsCtrl.editYoutube = function (mediaObj) {

            $mdDialog.show({
                // clickOutsideToClose: true,
                locals          : {mediaObj: mediaObj},
                fullscreen      : true,
                bindToController: true,
                templateUrl     : '/ng-app/admin-app/website-admin/items/youtube-edit-dlg.partial.html',
                controllerAs    : 'youtubeEditCtrl',
                controller      : function ($mdDialog) {

                    var youtubeEditCtrl = this;

                    youtubeEditCtrl.save = function () {
                        $mdDialog.hide(youtubeEditCtrl.mediaObj);
                    };

                    youtubeEditCtrl.cancel = function () {
                        $mdDialog.cancel();
                    }
                }
            })
                     .then(function (mediaObj) {
                         // itemDetailsCtrl.saveImage(mediaObj,response);
                         itemDetailsCtrl.saveMedia(mediaObj);
                     }, function (response) {

                     });

        };

        itemDetailsCtrl.getStProperty = function (stId, propertyName) {

            return (angular.isDefined(stId)) ? structureSrv.getProperty(stId, propertyName) : '';
        };


        itemDetailsCtrl.saveImage = function (mediaObj, imgOut) {

            mediaSrv.updateMedia(mediaObj, imgOut)
                    .then(function successCallback(mediaObj) {

                        var searchUrl = "/media/search/" + mediaObj.mdId + mediaObj.mdExt;

                        var mediaUrl = mediaObj.mdMediaUrl;

                        var currentItemSearch = itemDetailsCtrl.currentItem.gpSearchMediaUrl;

                        mediaSrv.imgCacheBuster([
                            searchUrl,
                            mediaUrl,
                            currentItemSearch
                        ]);

                    });
        };

        itemDetailsCtrl.updateMedia = function (mediaObj) {
            mediaSrv.updateMedia(mediaObj)
                    .then(function successCallback(mediaObj) {
                        $rootScope.$broadcast('event:queue-message', 'Modifications has been saved');
                    });
        };

        // ===================================================================================================

        itemDetailsCtrl.copyToCatalog = function () {

            var gpCatalogExtra_en = '';

            itemDetailsCtrl.currentItem.gpCatalogDescription_en = (lodash.isUndefined(itemDetailsCtrl.currentItem.gpTitle_en) || lodash.isEmpty(itemDetailsCtrl.currentItem.gpTitle_en)) ? '': angular.copy(itemDetailsCtrl.currentItem.gpTitle_en).trim().toUpperCase();

            gpCatalogExtra_en = (lodash.isUndefined(itemDetailsCtrl.currentItem.gpDetails_en) || lodash.isEmpty(itemDetailsCtrl.currentItem.gpDetails_en)) ? '': angular.copy(itemDetailsCtrl.currentItem.gpDetails_en).trim();

            itemDetailsCtrl.currentItem.gpCatalogExtra_en = htmlToPlainText(gpCatalogExtra_en);

            itemDetailsCtrl.currentItem.gpCatalogDescription_fr = (lodash.isUndefined(itemDetailsCtrl.currentItem.gpTitle_fr) || lodash.isEmpty(itemDetailsCtrl.currentItem.gpTitle_fr)) ? '': angular.copy(itemDetailsCtrl.currentItem.gpTitle_fr).trim().toUpperCase();

            itemDetailsCtrl.updateItems({
                gpGid                  : itemDetailsCtrl.currentItem.gpGid,
                gpCatalogDescription_en: itemDetailsCtrl.currentItem.gpCatalogDescription_en,
                gpCatalogExtra_en      : itemDetailsCtrl.currentItem.gpCatalogExtra_en,
                gpCatalogDescription_fr: itemDetailsCtrl.currentItem.gpCatalogDescription_fr
            }, itemDetailsCtrl.itemDetailsForm);
        };

        // ===================================================================================================

        itemDetailsCtrl.revertMedia = function (mediaObj) {
            mediaSrv.revertMedia(mediaObj.mdId)
                    .then(function successCallback(mediaObj) {

                        // mediaSrv.forceImgReload(mediaObj.mdMediaUrl, function () {
                        //     imagePrfDW.setMedia(mediaObj);
                        // });
                        //
                        // $rootScope.$broadcast('itemMediaUpdated', mediaObj);

                    })
        }


        itemDetailsCtrl.done = function () {

            $mdDialog.hide(false);
        };

        itemDetailsCtrl.cancel = function () {

            $mdDialog.cancel();
        };

        // ================================================
        // Events Listeners
        // ================================================


        var deReg1, deReg2, deReg3, deReg4, deReg10;

        deReg1 = $scope.$on('event:itemDetailsStale', function (even, gpGid) {

            itemDetailsCtrl.loadDetails(gpGid);

        });

        deReg2 = $scope.$on('event:media-flow-changed', function (event, mediaObj) {

            itemDetailsCtrl.loadDetails(itemDetailsCtrl.currentItem.gpGid);

        });

        deReg3 = $scope.$on('event:photo-edit', function (event, mediaObj) {

            // console.info("event:photo-edit")

            itemDetailsCtrl.editPhoto(mediaObj);

        });

        deReg4 = $scope.$on('event:itemDetailsLoad', function (e, gpGid) {

            if (angular.isDefined(gpGid) && gpGid !== null) {

                $state.go('items.itemDetails', {gpGid: gpGid});

            }
            else {

                $state.go('items');
            }

        });


        deReg10 = itemDetailsCtrl.$onDestroy = function handler() {
            deReg1();
            deReg2();
            deReg3();
            deReg4();
            deReg10();
        };


    }
})();
