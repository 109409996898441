/**
 * Created by Jonathan on 17/04/07.
 */

(function () {
    'use strict';

    angular
        .module('pdgWp')
        .value('appConfig', {
            sideNavLeft: {
                sections: [
                    {
                        label   : 'Dashboard',
                        expand  : false,
                        icon    : 'dashboard',
                        stateUrl: 'dashboard',
                        access: 'authWebOrders'
                    }, {
                        label   : 'Request Manager',
                        expand  : false,
                        icon    : 'shopping_cart',
                        stateUrl: 'orders',
                        access: 'authWebOrders'
                    }, {
                        label   : 'Clients Manager',
                        expand  : false,
                        icon    : 'group',
                        stateUrl: 'clients',
                        access: 'authWebOrders'
                    },
                    // {
                    //     label   : 'Web Orders Mgr',
                    //     expand  : false,
                    //     icon    : 'shopping_cart',
                    //     stateUrl: '',
                    //     actions : [
                    //         {
                    //             stateUrl: 'orders',
                    //             label   : 'Web Orders'
                    //         }, {
                    //             stateUrl: 'clients',
                    //             label   : 'Client Profiles'
                    //         }
                    //     ]
                    // },
                    {
                        label   : 'Website Admin',
                        expand  : false,
                        stateUrl: '',
                        access: 'authEditWebsite',
                        icon    : 'settings',
                        actions : [
                            {
                                stateUrl: 'items',
                                access: 'authEditWebsite',
                                label   : 'Items Manager'
                            }, {
                                stateUrl: 'reports',
                                access: 'authAdmin',
                                label   : 'Health Reports'
                            }, {
                                stateUrl: 'cp-orphans',
                                access: 'authEditWebsite',
                                label   : 'CP Orphans'
                            }, {
                                stateUrl: 'no-cp',
                                access: 'authEditWebsite',
                                label   : 'Web Orphans'
                            }, {
                                stateUrl: 'structure',
                                access: 'authAdmin',
                                label   : 'Items Structure'
                            }, {
                                stateUrl: 'users',
                                access: 'authAdmin',
                                label   : 'Users Profiles'
                            }
                        ]
                    }, {
                        label   : 'Catalog Admin',
                        expand  : false,
                        stateUrl: '',
                        access: 'authEditWebsite',
                        icon    : 'import_contacts',
                        actions : [
                            {
                                stateUrl: 'draft-catalog',
                                access: 'authEditWebsite',
                                label   : 'Draft Catalog Proofing'
                            }
                        ]
                    }
                ]
            },

        });
})();
