(function () {
    'use strict';

    angular
        .module('pdgWp')
        .controller('PinkiesListController', PinkiesListController);

    /** @ngInject */
    function PinkiesListController($scope, $mdSidenav, $mdDialog, $state, structureSrv, itemsSrv, appConfig, searchSvr) {

        var pinkiesListCtrl = this;

        pinkiesListCtrl.pinkiesList = [];
        pinkiesListCtrl.limit = 10;
        pinkiesListCtrl.page = 1;
        pinkiesListCtrl.searchObj = {
            sort             : {gpModOdr: 'DESC'},
            filter           : {
                gpStatusFtr       : '1',
                itStatusFtr       : '0',
                gpCatalogStatusFtr: 'all',
                gpDraftStatusFtr  : 'all',
                itDraftStatusFtr  : 'all',
                itBreStatusFtr  : 'all'
            },
            filteredStructure: {},
            structure: {
                gpChapId: '',
                gpSectId: '',
                gpCateId: ''
            },
            selectedTemplate: 'Default'
        };

        pinkiesListCtrl.structureArray = structureSrv.fullArray;

        pinkiesListCtrl.showImages = true;

        pinkiesListCtrl.$onInit = function () {

            if (lodash.isEmpty(appConfig.pinkiesState)){

                pinkiesListCtrl.searchItems();

            } else {
                pinkiesListCtrl.pinkiesList = appConfig.pinkiesState.pinkiesList;
                pinkiesListCtrl.limit = appConfig.pinkiesState.limit;
                pinkiesListCtrl.page = appConfig.pinkiesState.page;
                pinkiesListCtrl.searchObj = appConfig.pinkiesState.searchObj;
            }

        };

        pinkiesListCtrl.loadItem = function (gpGid) {

            $state.go('items.itemDetails', {gpGid: gpGid});

            if (!$mdSidenav('sideNavRight').isLockedOpen()) {
                $mdSidenav('sideNavRight', true).close();
            }
        };

        pinkiesListCtrl.setPage = function (page) {

            var page = 1 * page;

            if (-1 <= page <= 1) {
                pinkiesListCtrl.page = (pinkiesListCtrl.page + page >= 1) ? pinkiesListCtrl.page + page : 1;
            }
            else {
                pinkiesListCtrl.page = (page >= 1) ? page : 1;
            }

            pinkiesListCtrl.searchItems();
        };

        pinkiesListCtrl.updateSearch = function (searchText) {

            pinkiesListCtrl.searchText = (angular.isDefined(searchText)) ? searchText : pinkiesListCtrl.searchText;
            pinkiesListCtrl.page = 1;
            pinkiesListCtrl.searchItems();
        };

        pinkiesListCtrl.itemsSearchDlg = function () {

            $mdDialog.show({
                // clickOutsideToClose: true,
                locals          : {
                    searchObj: pinkiesListCtrl.searchObj
                },
                fullscreen      : true,
                bindToController: true,
                templateUrl     : '/ng-app/admin-app/website-admin/items/items-search-dlg/items-search-dlg.partial.html',
                controllerAs    : 'itemSearchDlgCtrl',
                controller      : 'ItemsSearchDlgController'
            })
                     .then(function (response) {

                         pinkiesListCtrl.searchItems(1);

                     }, function (response) {

                     });

        };

        pinkiesListCtrl.searchItems = function (page, limit) {

            pinkiesListCtrl.limit = (angular.isDefined(limit)) ? limit : pinkiesListCtrl.limit;

            pinkiesListCtrl.page = (angular.isDefined(page)) ? page : pinkiesListCtrl.page;

            var configObj = {
                search     : true,
                expandGid  : true,
                prices     : true, // itItemIdOdr: true,
                getPaging  : true,
                itStatusFtr: '0',
                limit      : pinkiesListCtrl.limit,
                page       : pinkiesListCtrl.page
            };

            configObj = lodash.merge(configObj, pinkiesListCtrl.searchObj.sort, pinkiesListCtrl.searchObj.filter, pinkiesListCtrl.searchObj.filteredStructure, pinkiesListCtrl.searchObj.other);

            if (pinkiesListCtrl.searchText === undefined || pinkiesListCtrl.searchText === '') {

            }
            else if (pinkiesListCtrl.searchText.substring(0, 1) === '=') {

                if (pinkiesListCtrl.searchText.length >= 3) {

                    configObj.gpCpIdFlxFtr = pinkiesListCtrl.searchText.substring(1);

                }
                else {

                    return;
                }

            }
            else if (pinkiesListCtrl.searchText.substring(0, 2) === 't:') {

                if (pinkiesListCtrl.searchText.length >= 5) {

                    configObj.itemStarFtr = pinkiesListCtrl.searchText.substring(2);

                }
                else {

                    return;
                }

            }
            else if (pinkiesListCtrl.searchText.substring(0, 2) === 'v:') {

                if (pinkiesListCtrl.searchText.length >= 5) {

                    configObj.cpStarFtr = pinkiesListCtrl.searchText.substring(2);

                }
                else {

                    return;
                }

            }
            else if (isFinite(pinkiesListCtrl.searchText) && pinkiesListCtrl.searchText.length <= 3) {
                configObj.gpCatPageFtr = pinkiesListCtrl.searchText;
            }
            else if (isFinite(pinkiesListCtrl.searchText) && pinkiesListCtrl.searchText.length >= 4 && pinkiesListCtrl.searchText.length <= 7) {
                configObj.itItemIdFlxFtr = pinkiesListCtrl.searchText;

            }
            else {
                configObj.gpTitleFtr = pinkiesListCtrl.searchText;
            }

            configObj.progressBar = 'sideNavRight@';

            return itemsSrv.getItems(configObj)
                           .then(function (pinkiesList) {
                               pinkiesListCtrl.page = 1 * pinkiesList.config.page;
                               pinkiesListCtrl.limit = 1 * pinkiesList.config.limit;
                               pinkiesListCtrl.count = 1 * pinkiesList.count;

                               pinkiesListCtrl.pinkiesList = pinkiesList.data;

                               return pinkiesListCtrl.pinkiesList.data;
                           });
        }


        var deReg1;

        deReg1 = $scope.$on('event:pinkiesListStale', function () {

            pinkiesListCtrl.searchItems();
        });


        pinkiesListCtrl.$onDestroy = function handler() {
            deReg1();

            appConfig.pinkiesState = {
                "pinkiesList" : pinkiesListCtrl.pinkiesList,
                "limit" : pinkiesListCtrl.limit,
                "page" : pinkiesListCtrl.page,
                "searchObj" : pinkiesListCtrl.searchObj
            };

        };

    }
})();
