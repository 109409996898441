(function () {
    'use strict';

    angular
        .module('pdgWp')
        .controller('UnitsConverter', UnitsConverter);

    /** @ngInject */
    function UnitsConverter() {

        var unitsConvCtrl = this;


        var lengthUnits,
            volumeUnits;

        lengthUnits = {
            mm     : {
                name  : 'Millimeters',
                abbr : 'mm',
                to_anchor: 1 / 1000,
                type     : 'metric'
            },
            cm     : {
                name  : 'Centimeters',
                abbr : 'cm',
                to_anchor: 1 / 100,
                type     : 'metric'
            },
            m      : {
                name  : 'Meters',
                abbr : 'm',
                to_anchor: 1,
                type     : 'metric'
            },
            'in'   : {
                name  : 'Inches',
                abbr : 'in',
                to_anchor: 1 / 12,
                type     : 'imperial'
            },
            yd     : {
                name  : 'Yards',
                abbr : 'yrd',
                to_anchor: 3,
                type     : 'imperial'
            },
            ft     : {
                name  : 'Feet',
                abbr : 'ft',
                to_anchor: 1,
                type     : 'imperial'
            }
        };

        unitsConvCtrl.lengthData = {
            lengthUnits  : lengthUnits,
            _anchors: {
                metric  : 3.28084,
                imperial:  1 / 3.28084
            }
        };

        volumeUnits = {
            ml: {
                abbr : 'ml',
                name: 'Millilitres',
                to_anchor: 1/1000,
                type: 'metric'
            }
            , cl: {
                abbr : 'cl',
                name: 'Centilitres',
                to_anchor: 1/100,
                type: 'metric'
            }
            , l: {
                abbr : 'l',
                name: 'Litres',
                to_anchor: 1,
                type: 'metric'
            }
            , 'fl-oz': {
                abbr : 'oz',
                name: 'Ounces OZ',
                to_anchor: 1,
                type: 'imperial'
            }
            , qt: {
                abbr : 'qrt',
                name: 'Quarts',
                to_anchor: 32,
                type: 'imperial'
            }
            , gal: {
                abbr : 'gal',
                name: 'Gallons',
                to_anchor: 128,
                type: 'imperial'
            }
        };

        unitsConvCtrl.volumeData = {
            volumeUnits  : volumeUnits,
            _anchors: {
                metric  : 33.8140226,
                imperial:  1/33.8140226
            }
        }

        unitsConvCtrl.convertLength = function(value, unitFrom, unitTo){

            if(isNaN(value)){
                unitsConvCtrl.lengthResult = '';
                return
            }

            if(unitFrom.type === unitTo.type){

                unitsConvCtrl.lengthResult = (value * unitFrom.to_anchor / unitTo.to_anchor).toFixed(2);

            } else {

                unitsConvCtrl.lengthResult = (value * unitsConvCtrl.lengthData._anchors[unitFrom.type] * unitFrom.to_anchor / unitTo.to_anchor).toFixed(2);
            }


        }

        unitsConvCtrl.convertVolume = function(value, unitFrom, unitTo){

            if(isNaN(value)){
                unitsConvCtrl.volumeResult = '';
                return
            }

            if(unitFrom.type === unitTo.type){

                unitsConvCtrl.volumeResult = (value * unitFrom.to_anchor / unitTo.to_anchor).toFixed(2);

            } else {

                unitsConvCtrl.volumeResult = (value * unitsConvCtrl.volumeData._anchors[unitFrom.type] * unitFrom.to_anchor / unitTo.to_anchor).toFixed(2);
            }
        }

    }
})();
