(function () {
    'use strict';

    angular
        .module('pdgWp')
        .controller('StructureController', StructureController);

    /** @ngInject */
    function StructureController($log, $timeout, $window, $scope, $mdDialog,$mdMenu, $rootScope, appConfig, structureSrv, structureObj, strData, deepL, mediaSrv) {

        var structureCtrl = this;

        structureCtrl.showSpinner = false;

        structureCtrl.deepL = deepL;

        structureCtrl.tree = {};

        structureCtrl.structureObj = structureObj;

        structureCtrl.strData = strData;

        structureCtrl.loadStructure = function () {

            var configObj = {
                editLang   : true,
                stGetBranch: true,
                forceArray : true,
                stStatusFtr: 'all'
            };

            return structureSrv.getStructure(configObj)
                               .then(function (structureObj) {
                                   structureCtrl.structureObj = structureObj;
                                   return structureObj;
                               });
        };

        structureCtrl.translate = function (strObj, inputId) {

            inputId = inputId || false;

            deepL.translate(strObj.stName_en)
                 .then(function (response) {

                     strObj.stName_fr = response;

                 }, function () {

                     if (inputId) structureCtrl.setFocus(inputId);
                 });
        };

        structureCtrl.setFocus = function (id) {

            var element = angular.element($window.document.getElementById(id));

            $timeout(function () {

                if (element) {
                    element.focus();
                }
            }, 0);
        };

        structureCtrl.submit = function (strObj, keyName, value) {

            if(angular.isDefined(value)){
                $scope.$apply(function () {
                    lodash.set(strObj, [keyName], value);
                });

            }

            var configObj = {
                stId     : strObj.stId //,
                // stName_en: (keyName === 'stName_en') ? strObj.stName_en : undefined,
                // stName_fr: (keyName === 'stName_fr') ? strObj.stName_fr : undefined
            };

            if(angular.isDefined(strObj[keyName])){
                configObj[keyName] = strObj[keyName];
            }

            structureSrv.updateStructure(configObj)
                        .then(function successCallback(structureObj) {

                            return structureObj;

                        });
        };

        structureCtrl.foldStructure = function (setFold) {

            setFold = setFold || false;

            lodash.forEach(structureCtrl.tree, function (value, key) {
                structureCtrl.tree[key] = setFold;
            });
        };

        structureCtrl.uploadItemMedia = function (stId, uploadedFile) {

            structureCtrl.showSpinner = true;

            return structureCtrl.updateMedia({stId: stId}, uploadedFile)
                       .then(function (mediaObj) {
                           // console.info("ctrl.uploadItemMedia")

                           structureCtrl.uploadCompleted(mediaObj, 'The local media has been uploaded.');

                           return mediaObj;
                       }, function (response) {

                           structureCtrl.showSpinner = false;

                           $rootScope.$broadcast('event:queue-message', {
                               type   : 'error',
                               message: 'STATUS: ' + response.statusText + ' - ' + response.data
                           });
                           return Promise.reject(response);
                       });
        };

        structureCtrl.uploadCompleted = function (mediaObj, msg) {
            // console.info("ctrl.uploadCompleted")

            structureCtrl.showSpinner = false;

            $rootScope.$broadcast('event:queue-message', msg);

            // $rootScope.$broadcast('event:media-flow-changed', mediaObj);

            $rootScope.$broadcast('event:structure-photo-edit', mediaObj);
        };

        structureCtrl.sendToBin = function (index, item, external, type) {

            structureCtrl.updateMedia({
                mdId    : item.mdId,
                mdStatus: '0'
            })
                .then(function (mediaObj) {

                    $rootScope.$broadcast('event:queue-message', 'The media was sent to the Media Bin.');

                    // $rootScope.$broadcast('event:media-flow-changed', mediaObj);
                });
        };

        structureCtrl.updateMedia = function (mediaObj, uploadedFile) {

            return mediaSrv.updateMedia(mediaObj, uploadedFile)
                           .then(function successCallback(mediaObj) {

                               return mediaObj;
                           });
        };


        structureCtrl.organizeStructure = function (chapterIndex, sectionIndex, index, item) {

            var tempObj = {};

            if(angular.isDefined(item.newItem)){

                if(sectionIndex !== false){

                    tempObj = {
                        stName_en : '[ New Category ]',
                        stType : 'category',
                        stLevel: 3
                    };
                } else if(chapterIndex !== false) {

                    tempObj = {
                        stName_en : '[ New Section ]',
                        stType : 'section',
                        stLevel: 2
                    };

                } else {

                    tempObj = {
                        stName_en : '[ New Chapter ]',
                        stType : 'chapter',
                        stLevel: 1
                    };
                }
            }

            var configObj = {
                stId     : item.stId,
                stOrder  : (index * 10) + 5,
                stChapId : (chapterIndex !== false) ? structureCtrl.structureObj.stItems[chapterIndex].stId : undefined,
                stSectId : (sectionIndex !== false) ? structureCtrl.structureObj.stItems[chapterIndex].stItems[sectionIndex].stId : undefined,
                srcParent: item.stParent,
                srcChapId: item.stChapId,
                srcSectId: item.stSectId
            };

            if(angular.isDefined(item.newItem)){


            } else if(sectionIndex !== false){

                if (configObj.stSectId != configObj.srcParent) {

                    alert("Item cannot be moved outside its parent container.")
                    return false;
                }

            } else if(chapterIndex !== false){

                if (configObj.stChapId != configObj.srcParent) {

                    alert("Item cannot be moved outside its parent container.")
                    return false;
                }

            } else {

            }

            configObj = lodash.merge(configObj, tempObj);

                // return;
            structureSrv.organizeStructure(configObj)
                        .then(function successCallback(structureObj) {

                            var a = structureObj;

                            // structureCtrl.structureObj = lodash.merge(structureCtrl.structureObj, structureObj)

                            // structureCtrl.structureObj = structureObj;


                            structureCtrl.loadStructure();
                            return true;

                        }, function errorCallback(structureObj) {

                            return false;
                        });

            return true;

        };

        structureCtrl.editPhoto = function (mediaObj) {

            var configObj = {
                mdIdFtr : mediaObj.mdId,
                detailed: true,
                getRow  : true
            };
            mediaSrv.getMedia(configObj)
                    .then(function successCallback(response) {

                        var mediaUrl = './ci-uploads/photos/' + response.mdOrigName;

                        mediaSrv.imgCacheBuster([mediaUrl])
                                .then(function () {

                                    structureCtrl.loadCropper(response, mediaUrl);
                                });
                    });
        };

        structureCtrl.loadCropper = function (mediaObj, url) {

            $mdDialog.show({

                locals          : {mediaUrl: (angular.isDefined(url)) ? url : mediaObj.mdMediaUrl, mediaWidth:1280, mediaHeight:960},
                fullscreen      : true,
                bindToController: true,
                templateUrl     : '/ng-app/admin-app/components/cropper-js/cropper-js-dlg.partial.html',
                controllerAs    : 'cropperJsDlg',
                controller      : function ($mdDialog) {

                    var cropperJsDlg = this;

                    cropperJsDlg.imgOut = '';

                    cropperJsDlg.imageModified = function (imgOut) {
                        cropperJsDlg.imgOut = imgOut;
                    };

                    cropperJsDlg.save = function () {
                        $mdDialog.hide(cropperJsDlg.imgOut);
                    };

                    cropperJsDlg.cancel = function () {
                        $mdDialog.cancel();
                    };
                }
            })
                     .then(function (response) {
                         structureCtrl.saveImage(mediaObj, response);
                     }, function (response) {

                     });

        };

        structureCtrl.saveImage = function (mediaObj, imgOut) {

            mediaSrv.updateMedia(mediaObj, imgOut)
                    .then(function successCallback(mediaObj) {

                        var configObj = {'stId':mediaObj.stId, 'stMediaId':mediaObj.mdId};

                        structureSrv.updateStructure(configObj)
                                    .then(function successCallback(structureObj) {

                                        structureCtrl.loadStructure();

                                        return structureObj;

                                    });
                    });
        };

        var deReg1, deReg10;

        deReg1 = $scope.$on('event:structure-photo-edit', function (event, mediaObj) {

            structureCtrl.editPhoto(mediaObj);

        });

        deReg10 = structureCtrl.$onDestroy = function handler() {
            deReg1();
            deReg10();
        };


        var testMouseOver = undefined;
        var throttleId = 0;
        var throttle = function (id) {
            if (throttleId == id) {
                $timeout(function(){
                    structureCtrl.tree['tree_' + id] = !structureCtrl.tree['tree_' + id];

                },0);

            }
            throttleId = id;
        };

        structureCtrl.dragover = lodash.throttle(throttle, 1000, {
            'leading' : true,
            'trailing': false
        });

        structureCtrl.clearMouse = function (e) {

            if (angular.isDefined(testMouseOver)) $timeout.cancel(testMouseOver);


        }

    }

})();
