(function () {
    'use strict';

    angular
        .module('pdgWp')
        .factory('deepL', deepL);

    /** @ngInject */
    function deepL($log, $window, $mdDialog, $sce, $q) {

        var deepL = this;

        deepL.translate = function (input) {

            if(angular.isUndefined(input)) return;

            $window.open(deepL.getUrl(input), 'DeepL');
 return;
            // return $mdDialog.show({
            //     clickOutsideToClose: true,
            //     multiple : true,
            //     locals             : {deepLUrl: deepL.getUrl(input)},
            //     fullscreen         : true,
            //     bindToController   : true,
            //     templateUrl        : '/ng-app/admin-app/services/deepl/deepl.iframe.parial.html',
            //     controllerAs       : 'deeplCtrl',
            //     controller         : function DeepLDlgController($mdDialog, $window) {
            //
            //         var deeplCtrl = this;
            //
            //         deeplCtrl.close = function () {
            //             $mdDialog.hide();
            //         }
            //     }
            // })
            //
            //     .then(function(response){
            //         return readClipboard();
            //     })
            //     .catch(function(errorMessage){
            //         return $q.reject(errorMessage)
            //     })
        };

        function readClipboard() {

            if (angular.isUndefined(navigator.clipboard)) {

                return $q.reject('Undefined clipboard');
            }
            return navigator.clipboard.readText()
                            .then(function (response) {

                                return response;
                            })
        }

        deepL.getUrl = function (text) {

            var div = document.createElement("div");
            div.innerHTML = text;
            text = div.textContent || div.innerText || "";

            return $sce.trustAsResourceUrl('https://www.deepl.com/translator#en/fr/' + encodeURI(text));
        };

        return deepL;
    }
})();
