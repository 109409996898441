(function () {
    'use strict';

    angular
        .module('pdgWp')
        .factory('itemsSrv', itemsSrv);

    /** @ngInject */
    function itemsSrv($http, msgSrv, appConfig) {

        var itemsSrv = this;

        itemsSrv.currentItem = {};

        itemsSrv.currentMedia = {};

        itemsSrv.filters = {};

// ===================================================================================================

        itemsSrv.getItems = function (configObj) {

            var httpConfig = {
                method: 'GET',
                url   : appConfig.apiUrl + 'items/',
                params: configObj
            };

            return $http(httpConfig)
                .then(function (response) {

                    if (angular.isDefined(httpConfig.params.getRow) && httpConfig.params.getRow) {

                        return itemsSrv.returnItemsObj(response)

                    } else {

                        if (angular.isDefined(httpConfig.params.getPaging)) {

                            return itemsSrv.returnItemsPagingList(response)

                        } else {
                            return itemsSrv.returnItemsList(response)
                        }
                    }
                }, function (response) {

                    return itemsSrv.errorCallback(response)

                })
        };

        itemsSrv.getFilterStructure = function (configObj) {

            var httpConfig = {
                method: 'GET',
                url   : appConfig.apiUrl + 'filter_structure/',
                params: configObj
            };

            return $http(httpConfig)
                .then(function (response) {

                    return itemsSrv.returnFilterStructure(response)

                }, function (response) {

                    return itemsSrv.errorCallback(response)
                })
        };

        itemsSrv.getCpOrphans = function (configObj) {

            var httpConfig = {
                method: 'GET',
                url   : appConfig.adminApiUrl + 'get_cp_orphans',
                params: configObj
            };

            return $http(httpConfig)
                .then(function (response) {

                    return itemsSrv.returnItemsList(response)

                }, function (response) {

                    return itemsSrv.errorCallback(response)
                })
        };


        itemsSrv.useToolbox = function (configObj) {

            var httpConfig = {
                method: 'GET',
                url   : appConfig.adminApiUrl + 'article_toolbox',
                params: configObj
            };

            return $http(httpConfig)
                .then(function (response) {

                    return itemsSrv.returnItemsList(response)

                }, function (response) {

                    return itemsSrv.errorCallback(response)
                })
        };

// ===================================================================================================

        itemsSrv.updateItemMediaOrder = function (data) {

            var httpConfig = {
                method: 'POST',
                url   : appConfig.adminApiUrl + 'update_media_order',
                data  : data,
                getRow: true
            };

            return $http(httpConfig)
                .then(function (response) {
                    return itemsSrv.returnItemsObj(response)
                }, function (response) {
                    return itemsSrv.errorCallback(response)
                })
        };

        itemsSrv.updateItems = function (itemsObj) {

            var gpGid = (angular.isDefined(itemsObj.gpGid)) ? itemsObj.gpGid : '';

            var httpConfig = {
                method: 'POST',
                url   : appConfig.apiUrl + 'items',
                data  : itemsObj
            };

            return $http(httpConfig)
                .then(function (response) {
                    return itemsSrv.returnItemsObj(response);
                }, function (response) {
                    return itemsSrv.errorCallback(response);
                });

        };

        itemsSrv.updateArticles = function (articlesObj) {

            var httpConfig = {
                method: 'POST',
                url   : appConfig.apiUrl + 'articles',
                data  : articlesObj
            };

            return $http(httpConfig)
                .then(function (response) {
                    return itemsSrv.returnItemsObj(response)
                }, function (response) {
                    return itemsSrv.errorCallback(Promise, response)
                })

        };

        itemsSrv.deleteOrgItems = function (gpId) {

            var httpConfig = {
                method: 'DELETE',
                url   : appConfig.adminApiUrl + 'org_items_delete',
                data  : {gpId: gpId}
            };

            return $http(httpConfig)
                .then(function (response) {
                    return
                }, function (response) {
                    return itemsSrv.errorCallback(Promise, response)
                })

        };

        itemsSrv.reorderArticles = function (articlesObj) {

            var httpConfig = {
                method: 'POST',
                url   : appConfig.adminApiUrl + 'items_reorder',
                data  : articlesObj
            };

            return $http(httpConfig)
                .then(function (response) {
                    return itemsSrv.returnItemsObj(response)
                }, function (response) {
                    return itemsSrv.errorCallback(Promise, response)
                })

        };


// ===================================================================================================

        itemsSrv.returnItemsObj = function (response) {

            var itemsObj = (angular.isDefined(response.data.data[0])) ? response.data.data[0] : response.data.data;

            itemsSrv.currentItem = lodash.merge(itemsSrv.currentItem, itemsObj);

            if(angular.isDefined(itemsSrv.currentItem.gpMediaJson)){

                lodash.forEach(itemsSrv.currentMedia, function(value, key) {
                    delete itemsSrv.currentMedia[key];
                });

                itemsSrv.currentMedia = lodash.merge(itemsSrv.currentMedia, itemsSrv.currentItem.gpMediaJson);
            }


            return itemsObj;
        };

// ===================================================================================================

        itemsSrv.titleCase = function (str) {

            if(angular.isUndefined(str)) return '';

            str = str.toLowerCase().split(' ');
            for (var i = 0; i < str.length; i++) {
                str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
            }
            return str.join(' ');
        };

// ===================================================================================================
//         itemsSrv.handlePastedData = function (e) {
//
//             // to be used with  ng-paste="itemsSrv.handlePastedData($event)"
//
//             if(typeof e.clipboardData !== "undefined"){
//                 var clipboardContent = e.clipboardData.getData('text/plain').toLowerCase();
//                 $timeout(function(){
//                     ctrl.localItem.gpTitle_en = clipboardContent;
//                 });
//             }
//         };


// ===================================================================================================


        itemsSrv.returnItemsList = function (response) {
            return response.data.data;
        };

// ===================================================================================================

        itemsSrv.returnItemsPagingList = function (response) {
            return response.data;
        };
// ========
// ===========================================================================================

        itemsSrv.returnFilterStructure = function (response) {

            lodash.forEach(itemsSrv.filters, function(value, key) {
                delete itemsSrv.filters[key];
            });

            itemsSrv.filters = lodash.merge(itemsSrv.filters, {structure: response.data.data});

            return response.data.data;
        };


// ===================================================================================================

        itemsSrv.errorCallback = function (promise, response) {

            return promise.reject(response);
        };

// ===================================================================================================


        return itemsSrv;
    }
})();
