(function () {
    'use strict';

    angular
        .module('pdgWp')
        .controller('OrderDetailsController', OrderDetailsController);

    /** @ngInject */
    function OrderDetailsController($rootScope, cartSrv, orderDetails, $mdDialog, webAuthSrv, appConfig) {

        var oDetailsCtrl = this;

        oDetailsCtrl.auth = webAuthSrv.auth;

        oDetailsCtrl.currentOrder = orderDetails;

        oDetailsCtrl.countries = appConfig.countries;

        oDetailsCtrl.showError = false;

        oDetailsCtrl.updateOrder = function (orderObj) {

            return cartSrv.updateOrders(orderObj)
                   .then(function successCallback(orderObj) {
                       return orderObj;
                   });
        };

        oDetailsCtrl.exportOrder = function (orId) {


            cartSrv.processOrder(orId)
                   .then(function successCallback(orderObj) {
                       oDetailsCtrl.currentOrder = lodash.merge(oDetailsCtrl.currentOrder, orderObj);
                       return orderObj;
                   }, function successCallback(orderObj) {
                       oDetailsCtrl.currentOrder = lodash.merge(oDetailsCtrl.currentOrder, orderObj);
                       return orderObj;
                   });
        };

        oDetailsCtrl.cancelOrder = function () {
            $mdDialog.show({
                clickOutsideToClose: false,
                templateUrl        : '/ng-app/admin-app/web-orders-mgr/orders/order-cancel-dlg.partial.html',
                controllerAs       : 'ctrl',
                controller         : function ($mdDialog) {

                    var ctrl = this;
                    ctrl.reason = '';

                    ctrl.cancel_order = function () {
                        $mdDialog.hide(ctrl.reason);
                    };

                    ctrl.closeDialog = function () {
                        $mdDialog.cancel();
                    }
                }
            })
                     .then(function (reason) {

                         oDetailsCtrl.updateOrderStatus('cxl', reason);
                     });

        };

        oDetailsCtrl.revertToPending = function () {
            $mdDialog.show({
                clickOutsideToClose: false,
                templateUrl        : '/ng-app/admin-app/web-orders-mgr/orders/order-reverted-pending-dlg.partial.html',
                controllerAs       : 'ctrl',
                controller         : function ($mdDialog) {

                    var ctrl = this;
                    ctrl.reason = '';

                    ctrl.revertToPending = function () {
                        $mdDialog.hide(ctrl.reason);
                    };

                    ctrl.closeDialog = function () {
                        $mdDialog.cancel();
                    }
                }
            })
                     .then(function (reason) {

                         oDetailsCtrl.updateOrderStatus('pending', reason);
                     });

        };

        oDetailsCtrl.updateOrderStatus = function (newStatus, comment) {

            oDetailsCtrl.currentOrder.orProStatus = newStatus;
            oDetailsCtrl.currentOrder.orProComments = comment || '';
            oDetailsCtrl.currentOrder.orProBy = oDetailsCtrl.auth.data.usFirstName + ' ' + oDetailsCtrl.auth.data.usLastName;

            oDetailsCtrl.updateOrder({
                orId         : oDetailsCtrl.currentOrder.orId,
                orProStatus  : oDetailsCtrl.currentOrder.orProStatus,
                orProComments: oDetailsCtrl.currentOrder.orProComments,
                orProBy      : oDetailsCtrl.currentOrder.orProBy,
                orProDateTime: true,
                orProHistory : 'Status changed to ' + oDetailsCtrl.currentOrder.orProStatus.toUpperCase() + '. ' + oDetailsCtrl.currentOrder.orProComments
            }).then(function successCallback() {
                oDetailsCtrl.loadDetails();
                $rootScope.$broadcast('event:orderListStale');
            });

        };

        oDetailsCtrl.loadDetails = function(orId){

            orId = orId || oDetailsCtrl.currentOrder.orId;

            var configObj = {
                orStatusFtr: 'all',
                orCartJson : true,
                getRow     : true,
                orIdFtr    : orId
            };

            return cartSrv.getOrders(configObj)
                          .then(function (orderObj) {
                              lodash.merge(oDetailsCtrl.currentOrder, orderObj);
                              return orderObj;
                          });
        };

        oDetailsCtrl.addHistoryNote = function (comment) {
            oDetailsCtrl.updateOrder({
                orId        : oDetailsCtrl.currentOrder.orId,
                orProHistory: 'NOTE: ' + comment
            }).then(function successCallback() {
                oDetailsCtrl.loadDetails();
            });

        };

        oDetailsCtrl.printOrder = function () {

            $mdDialog.show({
                clickOutsideToClose: true,
                locals             : {orId: oDetailsCtrl.currentOrder.orId},
                bindToController   : true,
                templateUrl           : '/ng-app/admin-app/web-orders-mgr/orders/orders-details-tabs/orders-items-print.partial.html',
                controllerAs       : 'dlgCtrl',
                controller         : function DialogController($mdDialog, appConfig, $window, $document) {

                    var dlgCtrl = this;

                    dlgCtrl.print = function(){
                        var iframe = document.getElementById("ordersPrintIframe");
                        var iframeWindow = (iframe.contentWindow || iframe.contentDocument);
                        iframeWindow.print();
                    };

                    dlgCtrl.width = ($window.innerWidth || $document.documentElement.clientWidth || $document.body.clientWidth) / 2;

                    dlgCtrl.height = ($window.innerHeight || $document.documentElement.clientHeight || $document.body.clientHeight) / 1.3;

                    dlgCtrl.url = appConfig.siteUrl + 'printer/orders_items/' + dlgCtrl.orId;
                    dlgCtrl.closeDialog = function () {
                        $mdDialog.hide();
                    }
                }
            });
        };

        oDetailsCtrl.printHistory = function () {

            $mdDialog.show({
                clickOutsideToClose: true,
                locals             : {orId: oDetailsCtrl.currentOrder.orId},
                bindToController   : true,
                templateUrl           : '/ng-app/admin-app/web-orders-mgr/orders/orders-details-tabs/orders-history-print.partial.html',
                controllerAs       : 'dlgCtrl',
                controller         : function DialogController($mdDialog, appConfig, $window, $document) {

                    var dlgCtrl = this;

                    dlgCtrl.print = function(){
                        var iframe = document.getElementById("ordersPrintHistoryIframe");
                        var iframeWindow = (iframe.contentWindow || iframe.contentDocument);
                        iframeWindow.print();
                    };

                    dlgCtrl.width = ($window.innerWidth || $document.documentElement.clientWidth || $document.body.clientWidth) / 2;

                    dlgCtrl.height = ($window.innerHeight || $document.documentElement.clientHeight || $document.body.clientHeight) / 1.3;

                    dlgCtrl.url = appConfig.siteUrl + 'printer/orders_history/' + dlgCtrl.orId;
                    dlgCtrl.closeDialog = function () {
                        $mdDialog.hide();
                    }
                }
            });
        };

        // ================================================
        // Events Listeners
        // ================================================


        var deReg1;

        deReg1 = $rootScope.$on('event:orderDetailsStale', function (even, orId) {

            oDetailsCtrl.loadDetails(orId);

        });

        oDetailsCtrl.$onDestroy = function handler() {
            deReg1();
        };


    }
})();
