(function () {
    'use strict';

    angular
        .module('pdgWp')
        .controller('CatalogBuilderController', CatalogBuilderController);

    /** @ngInject */
    function CatalogBuilderController($log, $rootScope, $state, catalogSrv, itemsSrv, catObj) {

        var catBuilderCtrl = this;
        
        catBuilderCtrl.flow = catObj.catDataJson.flow;

        catBuilderCtrl.loadedItems = [];

        catBuilderCtrl.getPageItems = function(){

            var flatLayout = lodash.flatMapDeep(catBuilderCtrl.flow.items, function(n){

                return [[n]];
            });

            var items = lodash
                .chain(flatLayout)
                .filter('gpId')
                .map('gpId')
                .join(',')
                .value();

            var configObj = {
                expandGid   : true,
                gpStatusFtr : 'all',
                itStatusFtr : 'all',
                detailed    : true,
                prices      : true,
                limit       : 'all',
                media       : true,
                gpTitle_en  : true,
                gpTitle_fr  : true,
                gpDetails_en: true,
                gpDetails_fr: true,
                gpBatchIdFtr: items
            };

            configObj.progressBar = 'main@';

            return itemsSrv.getItems(configObj)
                           .then(function (itemObj) {

                               catBuilderCtrl.loadedItems = itemObj;

                               return itemObj;
                           });
        };

        catBuilderCtrl.getPageItems();

        catBuilderCtrl.orderLayout = function (index, layoutObj) {

            layoutObj.splice(index, 1);

            saveCatalogObjDebounce({catId:catObj.catId, catDataJson: catObj.catDataJson});
        };

        catBuilderCtrl.saveCatalogObj = function (catalogObj) {

            catalogObj.progressBar = 'main@';

            return catalogSrv.saveCatalogObj(catalogObj)
                    .then(function successCallback(catalogObj) {

                        $rootScope.$broadcast('event:queue-message', 'Modifications has been saved');

                        return catalogObj;
                    }, function () {

                        // $state.reload();

                    })
        };

        catBuilderCtrl.getCopy = function (item){

            return angular.copy(item)
        };

        catBuilderCtrl.itemChanged = function (item, itemObj) {

            lodash.remove(item, function(n) {
                return true;
            });

            lodash.merge(item, itemObj);
            saveCatalogObjDebounce({catId:catObj.catId, catDataJson: catObj.catDataJson})
                       // .then(function(){
                       //         // $state.reload();
                       // },
                       //     function(){
                       //
                       //     });
            // $log.info(item);
        };

        var saveCatalogObjDebounce = lodash.debounce(catBuilderCtrl.saveCatalogObj, 250)

    }
})();
