(function () {
    'use strict';

    angular
        .module('pdgWp')
        .component('catalogContainer', {
            templateUrl : '/ng-app/admin-app/catalog-admin/catalog-templates/catalog-container.component.html',
            bindings    : {
                items           : '=',
            },
            controller  : CatalogContainerController,
            controllerAs: 'ctrl'
        });

    /** @ngInject */
    function CatalogContainerController($log, $element) {

        var ctrl = this;


        ctrl.$onInit = function () {
            // ctrl.getHeight();
        };

    }
})();
