(function () {
    'use strict';

    angular
        .module('pdgWp')
        .factory('msgSrv', messagesSrv);

    /** @ngInject */
    function messagesSrv($log, $timeout, $rootScope, $injector) {

        var msgSrv = this;

        var eventQueueMessage;
        var debouncedShowToast = lodash.debounce(showToast, 1000, {
            leading : true,
            trailing: false
        });

        msgSrv.toastQueue = [];
        msgSrv.progressQueue = {};

        msgSrv.progMainDisabled = true;
        msgSrv.progRightSideDisabled = true;

        // Need because $mdToast cause a Circular Dependency in "$httpProvider.interceptors"
        var $mdToast;
        lodash.defer(function () {

            try {
                $mdToast = $injector.get('$mdToast');
            } catch (err) {
                $mdToast = null;
            }
        });

        msgSrv.queueMessages = function (messages) {

            if (lodash.isArray(messages)) {

                lodash.forEach(messages, function (messageObj) {

                    msgSrv.toastQueue.push(messageObj);
                });

            } else if (lodash.isPlainObject(messages)) {

                msgSrv.toastQueue.push(messages);
            } else {

                msgSrv.toastQueue.push({
                    message: messages,
                    status : 200,
                    type   : "info"
                });
            }
            if (msgSrv.toastQueue.length > 0) debouncedShowToast();
        };

        msgSrv.setProgress = function (location) {

            var d = new Date();

            var id = d.getTime();

            msgSrv.progressQueue[id] = location;

            updateProgressBar();

            return function () {
                clearProgress(id);
            }
        };

        function showToast() {

            if (msgSrv.toastQueue.length === 0) return;

            var msgObj = msgSrv.toastQueue.shift();

            var toastObj;

            switch (msgObj.type) {
                case 'error':
                    toastObj = {
                        templateUrl : '/ng-app/admin-app/toast/toast-error.partial.html',
                        position    : 'right top',
                        toastClass  : 'toast-error',
                        hideDelay   : false,
                        autoWrap    : false,
                        controller  : 'MsgController',
                        controllerAs: 'msgCtrl',
                        locals      : {msgObj: msgObj}
                    };
                    break;
                default:
                    toastObj = $mdToast.simple()
                                       .textContent(msgObj.message)
                                       .position('right top')
                                       .hideDelay(msgObj.delay || 1000);
            }

            $mdToast.show(toastObj)
                    .then(function () {
                        $timeout(function () {
                            if (msgSrv.toastQueue.length > 0) showToast();
                        }, 500);
                    });
        }

        function clearProgress(id) {
            delete(msgSrv.progressQueue[id]);
            updateProgressBar();
        }

        function updateProgressBar() {
            var tempObj = {
                progRightSideDisabled: !lodash.includes(msgSrv.progressQueue, 'sideNavRight@'),
                progMainDisabled     : !lodash.includes(msgSrv.progressQueue, 'main@')
            };

            msgSrv = lodash.merge(msgSrv, tempObj);
        }

        eventQueueMessage = $rootScope.$on('event:queue-message', function (event, msgObj) {
            msgSrv.queueMessages(msgObj);
        });

        return msgSrv;
    }
})();
