(function () {
    'use strict';

    angular
        .module('pdgWp')
        .config(config);

    /** @ngInject */
    function config($logProvider, $mdThemingProvider, $stateProvider, $urlRouterProvider, $httpProvider) {
        // Enable log
        $logProvider.debugEnabled(false);

        $httpProvider.interceptors.push(function ($log, appConfig, $rootScope, $q, msgSrv) {
            return {
                'request': function (requestConfig) {

                    if(angular.isDefined(requestConfig.params) && angular.isDefined(requestConfig.params.progressBar)){

                        requestConfig.progressBar = msgSrv.setProgress(requestConfig.params.progressBar);

                        delete(requestConfig.params.progressBar);

                    }

                    if(angular.isDefined(requestConfig.data) && angular.isDefined(requestConfig.data.progressBar)){

                        requestConfig.progressBar = msgSrv.setProgress(requestConfig.data.progressBar);

                        delete(requestConfig.data.progressBar);

                    }

                    $rootScope.$emit('event:httpProvider-request', requestConfig);

                    return lodash.merge(requestConfig, {params: {lang: appConfig.language}});

                },
                'response': function(response) {

                    if(angular.isDefined(response.config.progressBar)){

                        response.config.progressBar();
                    }

                    return response;
                },
                'responseError': function(rejection) {

                    if(angular.isDefined(rejection.data) && angular.isDefined(rejection.data.messages)){

                        $rootScope.$broadcast('event:queue-message', rejection.data.messages);
                    }

                    if(angular.isDefined(rejection.config.progressBar)){

                        rejection.config.progressBar();
                    }

                    return $q.reject(rejection);
                }
            };
        });

        $mdThemingProvider.theme('default').primaryPalette('grey');

        $urlRouterProvider.otherwise('/dashboard');

    }

})();
