(function () {
    'use strict';

    angular
        .module('pdgWp')
        .component('cropperJs', {
            templateUrl : '/ng-app/admin-app/components/cropper-js/cropper-js.partial.html',
            bindings    : {
                mediaUrl: '<',
                mediaWidth: '<',
                mediaHeight: '<',
                onChange: '&'
            },
            controller  : cropperJsController,
            controllerAs: 'ctrl'
        });

    /** @ngInject */
    function cropperJsController($scope, $document, $timeout, $element) {

        var ctrl = this;
        ctrl.imgOut = null;
        ctrl.scaleX = 1;
        ctrl.scaleY = 1;

        ctrl.imageData = {};

        var cropper;

        var cropperJsImg;

        ctrl.$onInit = function () {

            cropperJsImg = $element[0].querySelector('#cropperJsImg');

            ctrl.finalWidth = ctrl.mediaWidth;
            ctrl.finalHeight = ctrl.mediaHeight;

            // ctrl.loadCropper();
        };

        ctrl.loadCropper = function () {

            $timeout(function () {

                cropper = new Cropper(cropperJsImg, {
                    aspectRatio             : ctrl.finalWidth / ctrl.finalHeight,
                    viewMode                : 0,
                    toggleDragModeOnDblclick: false,
                    cropBoxMovable          : false,
                    cropBoxResizable        : false,
                    dragMode                : 'move',
                    preview                 : '.preview', // minCropBoxHeight: 480,

                    ready: function () {

                        var canvas = cropper.getCanvasData();

                        $scope.$apply(function () {
                            ctrl.imageData = cropper.getImageData();
                        });

                        cropper.setCropBoxData({
                            width : ctrl.finalWidth,
                            height: ctrl.finalHeight,
                            left:(canvas.width/2) - (ctrl.finalWidth/2),
                            top:(canvas.height/2) - (ctrl.finalHeight/2)
                        });

                    },
                    crop : function (event) {

                        var croppedObj = cropper.getCroppedCanvas({
                            width : ctrl.finalWidth,
                            height: ctrl.finalHeight,
                            fillColor: '#fff'
                        });
                        ctrl.imageData = cropper.getImageData();

                        var mimeType = (ctrl.mediaUrl.slice(ctrl.mediaUrl.length - 4) == '.png') ? 'image/png' : 'image/jpeg';

                        ctrl.onChange({'imgOut': croppedObj.toDataURL(mimeType)});
                    }
                });


            }, 0);
        };

        ctrl.rotate = function () {

            cropper.rotate(-90);

        };

        ctrl.scaleImage = function (isHorizontal) {

            if (isHorizontal) {

                ctrl.scaleX = ctrl.scaleX * -1;

                cropper.scaleX(ctrl.scaleX);

            } else {

                ctrl.scaleY = ctrl.scaleY * -1;

                cropper.scaleY(ctrl.scaleY);
            }
        };

        ctrl.resetImage = function () {

            cropper.reset();
        };

    } // ===================================================
})();
