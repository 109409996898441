(function () {
    'use strict';

    angular
        .module('pdgWp')
        .factory('structureSrv', structureSrv);

    /** @ngInject */
    function structureSrv($rootScope, $http, msgSrv, appConfig) {

        var structureSrv = this;

        structureSrv.fullTree = [];
        structureSrv.fullArray = [];

// ===================================================================================================

        structureSrv.getStructure = function (configObj) {

            var httpConfig = {
                method: 'GET',
                url   : appConfig.adminApiUrl + 'structure_get/',
                params: configObj
            };

            return $http(httpConfig)
                .then(function (response) {

                    if (angular.isDefined(httpConfig.params.stGetBranch) && httpConfig.params.stGetBranch) {
                        return structureSrv.returnBranchObj(response)
                    } else if (angular.isDefined(httpConfig.params.getRow) && httpConfig.params.getRow) {
                        return structureSrv.returnStructureObj(response)
                    } else {
                        return structureSrv.returnStructureList(response)
                    }

                }, function (response) {
                    return structureSrv.errorCallback(Promise, response)
                })
        };

// ===================================================================================================

        structureSrv.loadFullTree = function () {

            structureSrv.getStructure({
                stId       : '1',
                stGetBranch: true
            })
                        .then(function (stBranchObj) {

                            lodash.remove(structureSrv.fullTree, function () {
                                return true;
                            });

                            structureSrv.fullTree = lodash.merge(structureSrv.fullTree, stBranchObj);
                        })
        };

// ===================================================================================================

        structureSrv.loadFullArray = function () {

            return structureSrv.getStructure({
                full : true,
                editLang : true,
                limit: 'all'
            })
                        .then(function (stBranchObj) {

                            lodash.remove(structureSrv.fullTree, function () {
                                return true;
                            });

                            structureSrv.fullArray = lodash.merge(structureSrv.fullArray, stBranchObj);

                            return structureSrv.fullArray;
                        })
        };


// ===================================================================================================

        structureSrv.updateStructure = function (structureObj) {

            var httpConfig = {
                method: 'POST',
                url   : appConfig.adminApiUrl + 'structure_mod/',
                data  : structureObj
            };

            return $http(httpConfig)
                .then(function (response) {
                    return structureSrv.returnStructureObj(response)
                }, function (response) {
                    return structureSrv.errorCallback(Promise, response)
                })

        };

        structureSrv.organizeStructure = function (structureObj) {

            var httpConfig = {
                method: 'POST',
                url   : appConfig.adminApiUrl + 'organize_structure/',
                data  : structureObj
            };

            return $http(httpConfig)
                .then(function (response) {
                    return structureSrv.returnStructureObj(response)
                }, function (response) {
                    return structureSrv.errorCallback(Promise, response)
                })
        };

// ===================================================================================================

        structureSrv.getProperty = function (stId, propertyName) {

            if(angular.isUndefined(structureSrv.fullArray[stId])) return undefined;

            propertyName = (angular.isUndefined(propertyName)) ? 'stName' : propertyName;

            return lodash.find(structureSrv.fullArray, ['stId', stId])[propertyName];
        };



// ===================================================================================================

        structureSrv.returnBranchObj = function (response) {

            return (angular.isDefined(response.data.data[0])) ? response.data.data[0] : response.data.data;
        };


// ===================================================================================================

        structureSrv.returnStructureObj = function (response) {

            return (angular.isDefined(response.data.data[0])) ? response.data.data[0] : response.data.data;
        };

// ===================================================================================================

        structureSrv.returnStructureList = function (response) {

            return response.data.data;
        };

// ===================================================================================================

        structureSrv.errorCallback = function (promise, response) {

            return promise.reject(response);
        };

// ===================================================================================================
        return structureSrv;
    }
})();
