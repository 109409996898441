(function () {
    'use strict';

    angular
        .module('pdgWp')
        .component('pictoEdit', {
            templateUrl: '/ng-app/admin-app/components/picto/picto.partial.html',

            bindings    : {
                itemsPicto    : '<',
                onPictoChange : '&'
            },
            controller  : pictoController,
            controllerAs: 'pictoCtrl'

        });

    /** @ngInject */
    function pictoController($scope, $http, $window) {

        var pictoCtrl = this;

        pictoCtrl.$onInit = function () {

            pictoCtrl.localPicto = angular.copy(pictoCtrl.itemsPicto);

            getPicto();
        };

        // pictoCtrl.$onChanges = function(changesObj){
        //
        //     if(angular.isDefined(changesObj.itemsPicto)){
        //         pictoCtrl.localPicto = angular.copy(changesObj.itemsPicto.currentValue);
        //     }
        // };

        pictoCtrl.pictoChange = function (updateObj) {

            var tempObj = angular.copy(updateObj);

            lodash.forEach(tempObj, function(value){
                lodash.unset(value, 'useTag');
            });

            pictoCtrl.onPictoChange({'updateObj': tempObj});
        };

        function getPicto(){

            var httpConfig = {
                method: 'GET',
                url   : $window.__env.baseUrl + 'assets/picto/picto-sprite.svg'
            };

            return $http(httpConfig)
                .then(function (response) {

                    var svgContent = response.data;

                    var freshPictoObj = {};
                    var container = document.getElementById('svgTemp');

                    container.innerHTML = svgContent;

                    var useTags = container.querySelectorAll('use');

                    useTags.forEach(function (useTag) {

                        var id = useTag.getAttribute('id');

                        freshPictoObj[id] = {
                            'id'    : id,
                            'status': false,
                        };
                    });
                    pictoCtrl.localPicto = lodash.merge(freshPictoObj, pictoCtrl.localPicto);

                        lodash.forEach(pictoCtrl.localPicto, function(value) {
                            console.log(value);
                            value.useTag = "<use xmlns:xlink=http://www.w3.org/1999/xlink xlink:href='assets/picto/picto-sprite.svg#" + value.id + "'></use>";
                        });


                    return pictoCtrl.localPicto;

                    }, function (response) {

                    return catalogSrv.errorCallback(Promise, response)
                });
        }

        // ================================================
        // Events Listeners
        // ================================================

    }
})();
