(function () {
    'use strict';

    angular
        .module('pdgWp')
        .directive('elementHeight', elementHeight);

    /** @ngInject */
    function elementHeight($timeout) {
        return {
            restrict: 'A',
            scope   : {
                elementHeight: '=elementHeight'
            },
            link    : function (scope, element) {

                var container = element[0];

                scope.onResizeDebounced = lodash.debounce(function(){

                    scope.elementHeight = container.offsetHeight;

                    scope.$apply();

                }, 300);

                scope.$watch(function() { return container.offsetHeight; },scope.onResizeDebounced())

            }
        };
    }
})();
