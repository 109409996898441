(function() {
  'use strict';

  angular
    .module('pdgWp')
    .controller('OrdersListController', OrdersListController);

  /** @ngInject */
  function OrdersListController($rootScope, $timeout, cartSrv, $mdSidenav, $state, $stateParams) {

    var oListCtrl = this;

      oListCtrl.ordersList = [];
      oListCtrl.limit = 25;
      oListCtrl.page = 1;
      oListCtrl.sortDirection = 'ASC';
      oListCtrl.sortType = 'orCreatedOrd';

      oListCtrl.sort = {orCreatedOrd: 'ASC'};
      oListCtrl.filter = {orProStatusFtr: "ongoing"};


      oListCtrl.$onInit = function(){

          oListCtrl.searchOrders();
      };

      oListCtrl.loadOrder = function(orId){

          $state.go('orders.orderDetails', {orId:orId});

          if(! $mdSidenav('sideNavRight').isLockedOpen()){
              $mdSidenav('sideNavRight', true).close();
          }
      };

      // oListCtrl.sortBy = function(sortObj){
      //
      //     oListCtrl.sort = lodash.forIn(oListCtrl.sort, function(value, key) {
      //         if(lodash.has(sortObj, key)){
      //
      //             oListCtrl.sort[key] = (value == 'ASC') ? 'DESC' : 'ASC';
      //
      //         } else {
      //             oListCtrl.sort = lodash.merge(oListCtrl.sort, sortObj);
      //
      //             delete oListCtrl.sort[key];
      //         }
      //     });
      //
      //     if (lodash.isEmpty(oListCtrl.sort)) oListCtrl.sort = sortObj;
      //
      //     oListCtrl.updateSearch();
      // };

      oListCtrl.sortBy = function(sortType, sortDirection){

          lodash.forEach(oListCtrl.sort, function(value, key) {
                      delete oListCtrl.sort[key];
                  });

          oListCtrl.sort[sortType] = sortDirection;

          oListCtrl.updateSearch();
      };

      oListCtrl.setPage = function (page){

          var page = 1 * page;

          if(-1 <= page <= 1){
              oListCtrl.page = (oListCtrl.page + page >= 1) ?  oListCtrl.page + page : 1;
          } else {
              oListCtrl.page = (page >= 1) ?  page : 1;
          }

          oListCtrl.searchOrders();
      };

      oListCtrl.filterSearch = function (){

          oListCtrl.filter = {orProStatusFtr:oListCtrl.status};

          oListCtrl.searchOrders();

      };

      oListCtrl.updateSearch = function (searchText) {

          oListCtrl.searchText = (angular.isDefined(searchText)) ? searchText : oListCtrl.searchText;
          oListCtrl.page = 1;
          oListCtrl.searchOrders();
      };

      oListCtrl.searchOrders = function(page, limit){

          oListCtrl.limit = (angular.isDefined(limit)) ? limit : oListCtrl.limit;

          oListCtrl.page = (angular.isDefined(page)) ? page : oListCtrl.page;

          var configObj = {
              orCartFlxFtr:oListCtrl.searchText,
              getPaging: true,
              limit: oListCtrl.limit,
              page: oListCtrl.page
          };

          if (configObj.orCartFlxFtr === '') delete configObj.orCartFlxFtr;

          configObj = lodash.merge(configObj, oListCtrl.sort, oListCtrl.filter);

          configObj.progressBar = 'sideNavRight@';

          return cartSrv.getOrders(configObj)
                         .then(function (ordersList) {
                             oListCtrl.page = 1 * ordersList.config.page;
                             oListCtrl.limit = 1 * ordersList.config.limit;
                             oListCtrl.count = 1 * ordersList.count;

                             oListCtrl.ordersList = ordersList.data ;

                             return oListCtrl.ordersList.data;
                         });
      };

      oListCtrl.sortOrderList = function(){

          switch(sortType) {
              case 'ordered_date':
                  // code block
                  break;
              case y:
                  // code block
                  break;
              default:
              // code block
          }

      };

      // ================================================
      // Events Listeners
      // ================================================


      var deReg1;

      deReg1 = $rootScope.$on('event:orderListStale', function (even) {

          oListCtrl.searchOrders();

      });

      oListCtrl.$onDestroy = function handler() {
          deReg1();
      };


  }
})();
