(function () {
    'use strict';

    angular
        .module('pdgWp')
        .directive('itemid', itemid);

    /** @ngInject */
    function itemid($q, $timeout, itemsSrv) {
        return {
            require: 'ngModel',
            link   : function (scope, elm, attrs, ctrl) {

                ctrl.$asyncValidators.itemid = function (modelValue, viewValue) {

                    ctrl.itemidDynamicError = undefined;

                    if (ctrl.$isEmpty(modelValue)) return $q.resolve();

                    var configObj = {
                        search     : true,
                        itemsReq   : true,
                        itStatusFtr: 'all'
                    };

                    if (isFinite(modelValue) && modelValue.length >= 5 && modelValue.length <= 6) {

                        configObj.itItemIdFtr = modelValue.toString();

                    } else {

                        ctrl.itemidDynamicError = 'Invalid Item ID.'

                        return $q.reject();
                    }


                    return itemsSrv.getItems(configObj)
                                   .then(function (itemsList) {

                                       if(itemsList.length === 0){
                                           ctrl.itemidDynamicError = undefined;

                                           return $q.resolve()
                                       } else {
                                           ctrl.itemidDynamicError = 'This item ID already exists in the system.'
                                           return $q.reject()
                                       }
                                   });
                };
            }
        }
    }
})();
