(function () {
    'use strict';

    angular
        .module('pdgWp')
        .component('cpOrphans', {
            templateUrl: '/ng-app/admin-app/website-admin/reports/cp-orphans/cp-orphans.partial.html',
            controller  : cpOrphans,
            controllerAs: 'ctrl'
        });

    /** @ngInject */
    function cpOrphans($rootScope, appConfig, itemsSrv,$stateParams, $http, $mdDialog, $state) {

        var ctrl = this;
        
        ctrl.__env = __env;

        ctrl.itemUrl = ctrl.__env.baseUrl + 'articles/view/';

        ctrl.results = [];

        ctrl.showHidden = false;

        ctrl.itemsFilter = '';

        ctrl.$onInit = function(){
            ctrl.getReport();
        };

        ctrl.getReport = function(showHidden){

            showHidden = (angular.isDefined(showHidden) && showHidden === true) ? '1/':'0/' ;

            return $http.get(ctrl.__env.siteUrl + 'reports/cp_orphans/' + showHidden)
                        .then(
                            function(response){
                                ctrl.items = response.data.data;

                                return response.data;
                            },
                            function(response){
                                alert('Report Error!');
                            }
                        )
        };

        ctrl.toggleCpOrphans = function(item_id){

            return $http.get(ctrl.__env.siteUrl + 'reports/toggle_cp_orphans/' + item_id)
                        .then(
                            function(response){
                                ctrl.items = response.data.data;

                                return response.data;
                            },
                            function(response){
                                alert('Report Error!');
                            }
                        )
        };


        ctrl.updateItems = function (itemsObj) {

            itemsSrv.updateArticles(itemsObj)
                    .then(function successCallback(itemsObj) {
                        return itemsObj;
                    });
        };

        ctrl.createItem = function (item) {

            item = (angular.isDefined(item)) ? item : {};

            $mdDialog.show({
                // clickOutsideToClose: true,
                locals          : {item: item},
                fullscreen      : true,
                bindToController: true,
                templateUrl     : '/ng-app/admin-app/components/items-creator/items-creator-dlg.partial.html',
                controllerAs    : 'itemCreatorDlgCtrl',
                controller      : function ItemCreatorDlgController($mdDialog) {

                    var itemCreatorDlgCtrl = this;

                    itemCreatorDlgCtrl.itemModified = function () {
                        $mdDialog.hide();
                    };


                    itemCreatorDlgCtrl.cancel = function () {
                        $mdDialog.hide();
                    }
                }
            })
                     .then(function (response) {

                         if(response.openInManager) {
                             $state.go('items.itemDetails', {gpGid: response.itemsObj.gpGid | response.itemsObj.idxGidMod});
                         } else {
                             ctrl.getReport();
                         }

                     }, function (response) {

                     });
        }


    }
})();
