(function () {
    'use strict';

    angular
        .module('pdgWp')
        .component('catalogItem', {
            templateUrl: '/ng-app/admin-app/catalog-admin/catalog-templates/catalog-item.component.html',
            bindings    : {
                catItem           : '<',
                loadedItems: '<',
                onItemChanged : '&'
            },
            controller  : CatalogItemController,
            controllerAs: 'ctrl'
        });

    /** @ngInject */
    function CatalogItemController($log, $element, $mdDialog, itemsSrv, catalogSrv) {

        var ctrl = this;

        ctrl.currentItem = {};

        ctrl.$onInit = function () {
            // ctrl.getHeight();
        };

        ctrl.$destroy = function () {
            // clearBindings();
        };

        ctrl.itemChanged = function (photos) {

            ctrl.currentCatItem = lodash.merge(ctrl.currentCatItem, {photos: photos});

            ctrl.onItemChanged({itemObj: ctrl.currentCatItem});
        };

        ctrl.$onChanges = function (changesObj) {

            ctrl.currentCatItem = angular.copy(ctrl.catItem);

            ctrl.currentItem = lodash.find(catalogSrv.preloadedItems, ['gpId', ctrl.currentCatItem.gpId]);

        };

        ctrl.selectItem = function ($event) {

            var parentEl = angular.element(document.body);

            $mdDialog.show({
                parent     : parentEl,
                targetEvent: $event,
                templateUrl: '/ng-app/admin-app/catalog-admin/catalog-templates/select-item-dlg.partial.html',
                controller : DialogController,
                controllerAs: 'dlgCtrl'
            })
                .then(
                    function (gpId) {
                        var configObj = {
                            gpStatusFtr : 'all',
                            itStatusFtr : 'all',
                            media       : true,
                            getRow: true,
                            gpIdFtr: gpId
                        };

                        itemsSrv.getItems(configObj)
                                .then(function (itemObj) {

                                    var photos = [];

                                    if(angular.isDefined(itemObj.gpMediaJson.photo)){
                                        photos = lodash.map(itemObj.gpMediaJson.photo, function(value){
                                            return {
                                                url:value.mdMediaUrl,
                                                zoom: 100,
                                                top: 0,
                                                left: 0
                                            };
                                        })
                                    }

                                    var newObj = {
                                        type: 'item',
                                        gpId: itemObj.gpId,
                                        photos: photos
                                    };

                                    ctrl.onItemChanged({itemObj: newObj});

                                });


                    },
                    function () {

                    }
                );

            function DialogController($mdDialog) {

                var dlgCtrl = this;

                dlgCtrl.gpId = '';

                dlgCtrl.submit = function () {

                    $mdDialog.hide(dlgCtrl.gpId);

                };

                dlgCtrl.closeDialog = function () {

                    $mdDialog.cancel();
                }
            }
        }
    }
})();
