(function () {
    'use strict';

    angular
        .module('pdgWp')
        .component('draftItem', {
            templateUrl: '/ng-app/admin-app/catalog-admin/draft-catalog/draft-item-component/draft-item.component.html',
            bindings    : {
                onSpecsChange : '&',
                onItemChange : '&',
                item           : '<',
                showInfo           : '<'
            },
            controller  : DraftItemController,
            controllerAs: 'ctrl'
        });

    /** @ngInject */
    function DraftItemController(itemsSrv, appConfig) {

        var ctrl = this;

        ctrl.currentItem = {};

        ctrl.appConfig = appConfig;


        ctrl.specsChange = function (updateObj) {
            ctrl.onSpecsChange({'updateObj': updateObj});
        };

        ctrl.updateItems = function (updateObj) {
            ctrl.onItemChange({'updateObj': updateObj});
        };

        ctrl.areThereProblems = function (item) {

            var errorMessage = '';

            if(item.gpItems.length < 1){
                errorMessage = 'Missing Articles!';
            }



            if(lodash.reduce(item.gpItems, function(bool, item) {
                return (bool)? bool : !item.gpQty && !item.gpPrice;
            }, false)){
                errorMessage += 'No CP Data!';
            }

            return errorMessage;
        };

        ctrl.$onInit = function () {
            ctrl.currentItem = ctrl.item;

            ctrl.currentItem.catalogImage = lodash.find(ctrl.currentItem.gpMediaJson.photo, ['mdIsCatalog', '1']);
var a = 2;
        };

        ctrl.$destroy = function () {
            // clearBindings();
        };

    }
})();
