(function () {
    'use strict';

    angular
        .module('pdgWp')
        .component('breEdit', {
            templateUrl: '/ng-app/admin-app/components/bre/bre.partial.html',

            bindings    : {
                gid           : '<',
                itemsSpecs    : '<',
                onSpecsChange : '&'
            },
            controller  : breController,
            controllerAs: 'breCtrl'

        });

    /** @ngInject */
    function breController($scope, $rootScope, $element, $log, $mdDialog, webAuthSrv,appConfig) {

        var breCtrl = this;

        breCtrl.isAuth = webAuthSrv.isAuth;

        breCtrl.$onInit = function () {
            breCtrl.localgid = angular.copy(breCtrl.gid);
            breCtrl.localSpecs = angular.copy(breCtrl.itemsSpecs);
        };

        breCtrl.$onChanges = function(changesObj){

            if(angular.isDefined(changesObj.itemsSpecs)){
                breCtrl.localSpecs = angular.copy(changesObj.itemsSpecs.currentValue);
            }
        };

        breCtrl.specsChange = function (updateObj) {
            breCtrl.onSpecsChange({'updateObj': updateObj});
        };


        // ================================================
        // Events Listeners
        // ================================================

    }
})();
