(function () {
    'use strict';

    angular
        .module('pdgWp')
        .config(catalogConfig);

    /** @ngInject */
    function catalogConfig($stateProvider) {

        var catalogs = {
            name   : 'catalogs',
            url    : '/catalogs',
            resolve: {
                catalogs: function (catalogSrv) {

                    var configObj = {
                        catLevelFtr: 0,
                        catCatalogIdFtr: 1
                    };

                    return catalogSrv.getCatalogObj(configObj)
                                     .then(function (catalogNav) {
                                         return catalogNav;
                                     });
                }
            },
            views  : {
                'sideNavRight@': {
                    templateUrl : '/ng-app/admin-app/catalog-admin/catalog-maker/catalog-nav.partial.html',
                    controller  : 'CatalogNavController',
                    controllerAs: 'catNavCtrl'
                },
                'main@'        : {
                    templateUrl: '/ng-app/admin-app/catalog-admin/catalog-maker/catalog-landing.partial.html'
                }
            }
        };

        var catalogs_builder = {
            name   : 'catalogs.builder',
            url    : '/builder/{catId}',
            resolve: {
                catObj: function (catalogSrv, $stateParams) {

                    var configObj = {
                        getRow     : true,
                        catDataJson: true,
                        preloadItems: true,
                        catIdFtr   : $stateParams.catId
                    };

                    configObj.progressBar = 'main@';

                    return catalogSrv.getCatalogObj(configObj)
                                     .then(function (catObj) {
                                         return catObj;
                                     });
                }
            },
            views  : {
                'main@': {
                    templateUrl : '/ng-app/admin-app/catalog-admin/catalog-maker/catalog-builder.partial.html',
                    controller  : 'CatalogBuilderController',
                    controllerAs: 'catBuilderCtrl'
                }
            }
        };

        var catalogs_layout = {
            name   : 'catalogs.layout',
            url    : '/layout/{catChapIdFtr}',
            resolve: {
                chapterFlow: function (catalogSrv, $stateParams) {

                    var configObj = {
                        catDataJson : true,
                        preloadItems: true,
                        catLevelFtr: '3',
                        catCatalogIdFtr : $stateParams.catCatalogId,
                        catOrderOrd : 'ASC',
                        catChapIdFtr: $stateParams.catChapIdFtr
                    };

                    configObj.progressBar = 'main@';

                    return catalogSrv.getCatalogObj(configObj)
                                     .then(function (flowObj) {
                                         return flowObj;
                                     });
                }
            },
            views  : {
                'main@': {
                    templateUrl : '/ng-app/admin-app/catalog-admin/catalog-maker/catalog-layout.partial.html',
                    controller  : 'CatalogLayoutController',
                    controllerAs: 'catLayoutCtrl'
                }
            }
        };


        // var catalogPage = {
        //     name   : 'catalog.page',
        //     url    : '/{catId}',
        //     resolve: {
        //         catalogPage: function (catalogSrv, $stateParams) {
        //
        //             var configObj = {
        //                 getRow     : true,
        //                 catIdFtr    : $stateParams.catId
        //             };
        //
        //             configObj.progressBar = 'main@';
        //
        //             return catalogSrv.getCatalogObj(configObj)
        //                              .then(function (catPageObj) {
        //                                  return catPageObj;
        //                              });
        //         }
        //     },
        //     views  : {
        //         'main@': {
        //             templateUrl : '/ng-app/admin-app/catalog-admin/catalog-maker/catalog-page.partial.html',
        //             controller  : 'CatalogPageController',
        //             controllerAs: 'catPageCtrl'
        //         }
        //     }
        // };


        $stateProvider.state(catalogs);

        $stateProvider.state(catalogs_builder);

        $stateProvider.state(catalogs_layout);

        // $stateProvider.state(catalogPage);


    }

})();
