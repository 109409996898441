(function () {
    'use strict';

    angular
        .module('pdgWp')
        .controller('NoCpController', NoCpController);

    /** @ngInject */
    function NoCpController() {

        var noCpCtrl = this;

    }

})();
