(function () {
    'use strict';

    angular
        .module('pdgWp')
        .component('specsEdit', {
            templateUrl: '/ng-app/admin-app/components/specs-edit/specs-edit.partial.html',

            bindings    : {
                gid           : '<',
                specsHeaders  : '<',
                itemsSpecs    : '<',
                onHeaderChange: '&',
                onSpecsChange : '&',
                onSpecsReorder: '&'
            },
            controller  : specsEditController,
            controllerAs: 'specsEditCtrl'

        });

    /** @ngInject */
    function specsEditController($scope, $rootScope, $element, $log, $mdDialog, webAuthSrv,appConfig) {

        var specsEditCtrl = this;

        specsEditCtrl.isAuth = webAuthSrv.isAuth;
        specsEditCtrl.specsHeaderNames = appConfig.specsHeaderNames;
        specsEditCtrl.colPlaceHolder = [];
        specsEditCtrl.isOpen = false;

        specsEditCtrl.$onInit = function () {
            specsEditCtrl.localgid = angular.copy(specsEditCtrl.gid);
            specsEditCtrl.localHeaders = (lodash.isArray(specsEditCtrl.specsHeaders)) ? angular.copy(specsEditCtrl.specsHeaders) : [];
            specsEditCtrl.localSpecs = angular.copy(specsEditCtrl.itemsSpecs);
            specsEditCtrl.placeMark = [{id: 1000}];
            updatePlaceHolder();
        };

        specsEditCtrl.$onChanges = function(changesObj){

            if(angular.isDefined(changesObj.itemsSpecs)){
                specsEditCtrl.localSpecs = angular.copy(changesObj.itemsSpecs.currentValue);
            }
        }

        specsEditCtrl.headerChange = function (updateObj) {
            specsEditCtrl.onHeaderChange({'updateObj': updateObj});
        };

        specsEditCtrl.specsChange = function (updateObj) {
            specsEditCtrl.onSpecsChange({'updateObj': updateObj});
        };

        specsEditCtrl.specsReorder = function (index) {

            specsEditCtrl.localSpecs.splice(index, 1);

            var updateObj = lodash.map(specsEditCtrl.localSpecs, function (item, index) {

                return {
                    idxItId     : item.idxItId,
                    itGroupIndex: index
                };
            });

            specsEditCtrl.onSpecsReorder({'updateObj': {'itOrderObj': updateObj}});

        };

        specsEditCtrl.testType = function (type, typeNeeded) {
            return (type === typeNeeded);
        };

        specsEditCtrl.columnReorder = function () {

            var updateObj = lodash.map(specsEditCtrl.localSpecs, function (item) {

                return {
                    itId      : item.itId,
                    itItemJson: item.itItemJson
                };
            });

            specsEditCtrl.onHeaderChange({
                'updateObj': {
                    gpGid       : specsEditCtrl.localgid,
                    gpHeaderJson: specsEditCtrl.localHeaders
                }
            });

            specsEditCtrl.onSpecsReorder({'updateObj': {'itOrderObj': updateObj}});

        };


        specsEditCtrl.deleteColumn = function (index) {

            specsEditCtrl.colPlaceHolder.splice(index, 1);

            specsEditCtrl.localHeaders.splice(index, 1);

            lodash.forEach(specsEditCtrl.localSpecs, function (item) {
                item.itItemJson.splice(index, 1);
            });

            specsEditCtrl.columnReorder();

            updatePlaceHolder();

        };

        specsEditCtrl.addFirstColumn = function () {

            // if (!lodash.isEmpty(specsEditCtrl.localHeaders) && specsEditCtrl.localHeaders.length !== 0) return;


            specsEditCtrl.listModified(specsEditCtrl.localHeaders.length, 'copy');
        };


        specsEditCtrl.listModified = function (index, dropEffect) {

            if (dropEffect !== 'copy') return true;

            if (lodash.isEmpty(specsEditCtrl.localHeaders)) {
                specsEditCtrl.localHeaders = [];
            }

            specsEditCtrl.localHeaders.splice(index, 0, '');

            lodash.forEach(specsEditCtrl.localSpecs, function (item, iIndex) {

                if (lodash.isEmpty(item.itItemJson)) {
                    item.itItemJson = [];
                }

                item.itItemJson.splice(index, 0, '');
            });


            specsEditCtrl.columnReorder();
            updatePlaceHolder();

            // $scope.$apply(
            //     function(){
            //     }
            // );
        };

        specsEditCtrl.colModified = function (index, dndObj) {

            specsEditCtrl.colPlaceHolder.splice(index, 1);

            var newIndex = lodash.findIndex(specsEditCtrl.colPlaceHolder, {id: dndObj.id});

            var tempHeader = specsEditCtrl.localHeaders[specsEditCtrl.colPlaceHolder[newIndex].id];

            var tempSpecs = lodash.map(specsEditCtrl.localSpecs, function (item) {
                return item.itItemJson[specsEditCtrl.colPlaceHolder[newIndex].id];
            });

            if (newIndex >= index) specsEditCtrl.localHeaders.splice(index, 1);
            specsEditCtrl.localHeaders.splice(newIndex, 0, tempHeader);
            if (newIndex < index) specsEditCtrl.localHeaders.splice(index, 1);

            lodash.forEach(specsEditCtrl.localSpecs, function (item, iIndex) {
                if (newIndex >= index) item.itItemJson.splice(index, 1);
                item.itItemJson.splice(newIndex, 0, tempSpecs[iIndex]);
                if (newIndex < index) item.itItemJson.splice(index, 1);
            });


            specsEditCtrl.columnReorder();

            updatePlaceHolder();

        };

        function updatePlaceHolder() {

            specsEditCtrl.colPlaceHolder = [];

            lodash.forEach(specsEditCtrl.localHeaders, function (item, index) {
                specsEditCtrl.colPlaceHolder.push({id: index});
            });

            specsEditCtrl.columnCount = specsEditCtrl.colPlaceHolder.length;
        }

        specsEditCtrl.flattenColumns = function (item) {

            item.itItemJson.splice(0, 1000, item.itItemJson.join(' '));

            specsEditCtrl.specsChange({
                itId      : item.itId,
                itItemJson: item.itItemJson
            });

        };

        specsEditCtrl.fixColumns = function (item, totalLength) {

            if (angular.isUndefined(item.itItemJson)) {
                item.itItemJson = [];
            }

            var count = totalLength - item.itItemJson.length;
            var i;

            for (i = 0; i < count; i++) {
                item.itItemJson.push('');
            }

            specsEditCtrl.specsChange({
                itId      : item.itId,
                itItemJson: item.itItemJson
            });
        };

        specsEditCtrl.resetHeaders = function (item, headers) {

            var count = item.itItemJson.length;

            lodash.remove(specsEditCtrl.localHeaders, function (n) {
                return true;
            });

            var i;

            for (i = 0; i < count; i++) {
                specsEditCtrl.localHeaders.push('');
            }

            specsEditCtrl.onHeaderChange({
                'updateObj': {
                    gpGid       : specsEditCtrl.localgid,
                    gpHeaderJson: specsEditCtrl.localHeaders
                }
            });

            updatePlaceHolder();
        };

        specsEditCtrl.openToolbox = function (article) {

            $mdDialog.show({
                // clickOutsideToClose: true,
                locals          : {
                    article      : article,
                    articlesCount: specsEditCtrl.localSpecs.length
                },
                fullscreen      : true,
                bindToController: true,
                templateUrl     : '/ng-app/admin-app/components/specs-edit/article-toolbox.partial.html',
                controllerAs    : 'aToolboxDlgCtrl',
                controller      : function articleToolboxDlgController($mdDialog, itemsSrv) {

                    var aToolboxDlgCtrl = this;

                    aToolboxDlgCtrl.useToolbox = function (toolboxObj) {

                        if (! lodash.isEmpty(toolboxObj.gid) && ! /^(\d{1,4})$/.test(toolboxObj.gid)) {
                            alert("Invalid Group Id: Make sure to use a destination Group Id, NOT AN ARTICLE'S CATALOG NUMBER!!!");

                            return;
                        }

                        itemsSrv.useToolbox(toolboxObj)
                                .then(function successCallback(itemsObj) {

                                    $mdDialog.hide(itemsObj);

                                }, function (response) {

                                    $mdDialog.cancel(response);
                                })
                    };

                    aToolboxDlgCtrl.cancel = function () {

                        $mdDialog.hide(false);
                    }
                }
            })
                     .then(function (response) {

                         if (response === false) return;

                         $rootScope.$broadcast('event:queue-message', 'The action as been processed.');

                         $rootScope.$broadcast('event:itemListStale');

                         if(response.action === 'copy' || response.action === 'splitCopy'){

                             $rootScope.$broadcast('event:itemDetailsLoad',response.destGid);

                         } else {

                             if(angular.isDefined(response.srcGid) && response.srcGid !== null){

                                 $rootScope.$broadcast('event:itemDetailsStale',response.srcGid);

                             } else if(angular.isDefined(response.destGid) && response.destGid !== null){

                                 $rootScope.$broadcast('event:itemDetailsLoad',response.destGid);

                             } else {
                                 $rootScope.$broadcast('event:itemDetailsLoad',null);
                             }

                         }

                     }, function (response) {

                         $rootScope.$broadcast('event:queue-message', response);

                     });
        }


        // ================================================
        // Events Listeners
        // ================================================

    }
})();
