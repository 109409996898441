(function () {
    'use strict';

    angular
        .module('pdgWp')
        .factory('searchSvr', searchSvr);

    /** @ngInject */
    function searchSvr($location, $window, $httpParamSerializer, appConfig) {

        var searchSvr = this;

        searchSvr.__env = $window.__env;

        var previousKeywords = '';

        var previousStFilter = '';

        var previousCpStarFtr = '';

        var previousItemStarFtr = '';

        var filterStructure = false;

        var structureFlags = 6;

        searchSvr.paramObj = {};

        searchSvr.searchDefault = {
            page    : 1,
            limit   : 10,
            stFilter: '',
            keywords: ''
        };

        searchSvr.isSearchPage = false;

        searchSvr.init = function () {

            searchSvr.getParams();

            previousKeywords = searchSvr.paramObj.keywords || searchSvr.searchDefault.keywords;

            previousStFilter = searchSvr.paramObj.stFilter || searchSvr.searchDefault.stFilter;

            previousCpStarFtr = searchSvr.paramObj.cpStarFtr || searchSvr.searchDefault.cpStarFtr;

            previousItemStarFtr = searchSvr.paramObj.itemStarFtr || searchSvr.searchDefault.itemStarFtr;

            filterStructure = true;
        };

        searchSvr.setDefault = function (defaultObj, flags) {

            var searchDefault = angular.copy(searchSvr.searchDefault);

            searchSvr.searchDefault = lodash.merge(searchDefault, defaultObj);

            structureFlags = flags || structureFlags;
        };

        searchSvr.goSearch = function (paramObj) {

            var params = $httpParamSerializer(paramObj);

            var lang = (appConfig.language !== 'en') ? '?lang=' + appConfig.language : '';

            $window.location.href = searchSvr.__env.baseUrl + 'search/' + lang + '#!/?' + params;
        };


        searchSvr.setParams = function (paramObj) {

            var params;

            searchSvr.paramObj = lodash.merge(searchSvr.paramObj, paramObj);

            if (searchSvr.paramObj.keywords !== previousKeywords) {

                previousKeywords = searchSvr.paramObj.keywords;

                searchSvr.paramObj.page = searchSvr.searchDefault.page;

                searchSvr.paramObj.stFilter = searchSvr.searchDefault.stFilter;

                previousStFilter = searchSvr.paramObj.stFilter;

                filterStructure = true;
            }

            if (searchSvr.paramObj.stFilter !== previousStFilter) {

                previousStFilter = searchSvr.paramObj.stFilter;

                searchSvr.paramObj.page = searchSvr.searchDefault.page;
            }

            params = lodash.pickBy(searchSvr.paramObj, function (value, key) {

                return value !== searchSvr.searchDefault[key];
            });

            $location.search(params);
        };

        searchSvr.getParams = function () {

            var searchDefault = angular.copy(searchSvr.searchDefault);

            var locationParam = $location.search();

            lodash.forEach(searchSvr.paramObj, function (value, key) {
                delete searchSvr.paramObj[key];
            });

            searchSvr.paramObj = lodash.merge(searchSvr.paramObj, searchDefault, locationParam);

            return searchSvr.paramObj;
        };

        searchSvr.getConfig = function (configObj) {

            var config = {};

            var searchDefault = angular.copy(searchSvr.searchDefault);

            searchSvr.getParams();

            if (angular.isDefined(searchSvr.paramObj.keywords) && searchSvr.paramObj.keywords !== '') {

                if (isFinite(searchSvr.paramObj.keywords) && searchSvr.paramObj.keywords.length <= 3) {

                    config.gpCatPageFtr = searchSvr.paramObj.keywords;

                } else if (isFinite(searchSvr.paramObj.keywords) && searchSvr.paramObj.keywords.length >= 4 && searchSvr.paramObj.keywords.length <= 7) {

                    config.itItemIdFlxFtr = searchSvr.paramObj.keywords;

                } else if (searchSvr.paramObj.keywords.substring(0, 1) === '=') {

                    if (searchSvr.paramObj.keywords.length >= 3) {

                        config.gpCpIdFlxFtr = searchSvr.paramObj.keywords.substring(1);

                    } else {

                        return;
                    }

                } else if (searchSvr.paramObj.keywords.substring(0, 2) == 't:') {

                    if (searchSvr.paramObj.keywords.length >= 5) {

                        config.itemStarFtr = searchSvr.paramObj.keywords.substring(2);


                    } else {

                        return;
                    }

                } else {

                    config.gpTitleFtr = searchSvr.paramObj.keywords;

                    config.itRelevanceOdr = true;
                }
            }

            if (filterStructure) {

                config.filterStructure = structureFlags;

                filterStructure = false;
            }

            if (searchSvr.paramObj.stFilter !== '') config.gpStSearchFtr = searchSvr.paramObj.stFilter;

            return lodash.merge(searchDefault, configObj, config, searchSvr.paramObj);
        };

        return searchSvr;
    }
})();
