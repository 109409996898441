(function () {
    'use strict';

    angular
        .module('pdgWp')
        .controller('UserProfileController', UserProfileController);

    /** @ngInject */
    function UserProfileController($scope, $log, $state, appConfig, usersSrv, userProfile, $mdDialog) {

        var uProfileCtrl = this;

        uProfileCtrl.currentUser = userProfile;

        uProfileCtrl.userSalutations = appConfig.userSalutations;

        uProfileCtrl.getUser = function(){

            var configObj = {
                employees: true,
                usStatusFtr:'all',
                getRow:true,
                usIdFtr:userProfile.usId
            };

            usersSrv.getUsers(configObj)
                    .then(function (userObj) {

                        uProfileCtrl.currentUser = userObj;

                        return userObj;
                    });
        };

        uProfileCtrl.updateUsers = function (usersObj) {

            if($scope.userProfileForm.$invalid) return;

            usersObj = ($scope.userProfileForm.$$success.parse.length > 1) ? uProfileCtrl.currentUser : usersObj;

            usersSrv.updateUsers(usersObj)
                    .then(function successCallback(usersObj) {
                        return usersObj;
                    });
        };

        uProfileCtrl.changePassword = function (isNewUser) {

            isNewUser = (angular.isDefined(isNewUser)) ? isNewUser : false;

            $mdDialog.show({
                clickOutsideToClose: true,
                locals:(isNewUser) ? {isNewUser:true} : {usId:uProfileCtrl.currentUser.usId,
                    usEmail:uProfileCtrl.currentUser.usEmail} ,
                bindToController: true,
                templateUrl: '/ng-app/admin-app/website-admin/users/user-change-password.partial.html',
                controllerAs:'pwdChangeCtrl',
                controller: function PasswordChangeController($mdDialog, $state, appConfig, usersSrv) {

                    var pwdChangeCtrl = this;

                    pwdChangeCtrl.changePassword = function() {

                        var usPassword = null;

                        if(angular.isDefined(pwdChangeCtrl.usPassword)){
                            if (pwdChangeCtrl.usPassword == '' || pwdChangeCtrl.usPassword.length < 8) {
                                alert('Password not valid. Please select a new one.');
                                return;
                            } else {
                                usPassword = pwdChangeCtrl.usPassword;
                            }
                        }

                        var configObj = {
                            usEmail: pwdChangeCtrl.usEmail,
                            usId: pwdChangeCtrl.usId,
                            usPassword: usPassword,
                            empStatus: (isNewUser) ? '1' : null,
                            authRole: (isNewUser) ? 'technicians' : null,
                            usEmailSend: (pwdChangeCtrl.sendEmail) ? true: null
                        };

                        usersSrv.updateUsers(configObj)
                                .then(function successCallback(usersObj) {
                                    $mdDialog.hide();

                                    if(isNewUser){
                                        $state.go('users.userProfile', {usId:usersObj.usId});
                                    }
                                    return usersObj;
                                });

                    };

                    pwdChangeCtrl.cancel = function() {
                        $mdDialog.cancel();
                    }
                }
            })
                .then(
                    function(){
                        uProfileCtrl.getUser();
                    }
                );
        }



    }
})();
