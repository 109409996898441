(function () {
    'use strict';

    angular
        .module('pdgWp')
        .config(noCpConfig);

    /** @ngInject */
    function noCpConfig($stateProvider) {

        var noCp = {
            name : 'no-cp',
            url  : '/no-cp',
            resolve: {
                itemsList  : function (itemsSrv) {

                    var configObj = {
                        search   : true,
                        expandGid: true,
                        prices   : true,
                        gpModOdr : 'DESC',
                        getPaging: true,
                        limit    : "10",
                        page     : "1"

                    };

                    return itemsSrv.getItems(configObj)
                                   .then(function (itemsList) {
                                       return itemsList.data;
                                   });
                },
                itemDetails: function () {
                    return {};
                }
            },
            views: {
                'sideNavRight@': {
                    templateUrl : '/ng-app/admin-app/website-admin/items/items-list.partial.html',
                    controller  : 'ItemsListController',
                    controllerAs: 'itemsListCtrl'
                },
                'main@': {
                    templateUrl : '/ng-app/admin-app/website-admin/no-cp/no-cp.partial.html',
                    controller  : 'NoCpController',
                    controllerAs: 'noCpCtrl'
                }
            }
        };

        $stateProvider.state(noCp);
    }
})();
