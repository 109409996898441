(function () {
    'use strict';

    angular
        .module('pdgWp')
        .component('itemsStructure', {
            templateUrl: '/ng-app/admin-app/website-admin/items/items-structure.partial.html',
            transclude : true,

            bindings    : {
                gid                : '<',
                template           : '@',
                onStructureChanged : '&',
                onStructureInserted: '&',
            },
            controller  : ItemsStructureController,
            controllerAs: 'ctrl'

        });

    /** @ngInject */
    function ItemsStructureController($timeout, itemsSrv, structureSrv) {

        var ctrl = this;

        ctrl.structureArray = structureSrv.fullArray;
        ctrl.selectedChap = {};
        ctrl.selectedSect = {};
        ctrl.selectedCat = {};
        ctrl.itemsList = [];
        ctrl.isInsert = true;
        ctrl.orgList = [];

        ctrl.$onInit = function () {

            ctrl.gid = angular.copy(ctrl.gid);

            ctrl.template = angular.copy(ctrl.template);

            ctrl.newOrgItem = angular.copy({gpGidMod:ctrl.gid, gpStatus:'1'});

            ctrl.getOrgList();

            if(lodash.isEmpty(ctrl.structureArray)) structureSrv.loadFullArray();
        };

        ctrl.submit = function () {
            if (ctrl.isInsert) {
                ctrl.updateArticles();
            } else {
                ctrl.updateItems();
            }
        };

        ctrl.getOrgList = function () {

            var configObj = {
                gpGidFtr  : ctrl.gid,
                gpGroup: 'none',
                gpStatusFtr: 'all',
                gpStructureOdr: true
            };

            itemsSrv.getItems(configObj)
                    .then(function (itemObj) {
                        ctrl.orgList = itemObj;
                    });
        };

        ctrl.addItems = function () {

            ctrl.updateItems(ctrl.newOrgItem)
                .then(function successCallback(itemsObj) {
                    ctrl.getOrgList();
                    return itemsObj;
                });
        };


        ctrl.updateItems = function (orgItem) {

            return itemsSrv.updateItems(orgItem)
                    .then(function successCallback(itemsObj) {
                        ctrl.getOrgList();
                        return itemsObj;
                    });
        };

        ctrl.updateStructure = function (orgItem, level) {

            switch(level) {
                case '1':
                    orgItem.gpSectId = null;
                    orgItem.gpCateId = null;
                    break;
                case '2':
                    orgItem.gpCateId = null;
                    break;
                case '3':
                    ctrl.updateItems(orgItem);
                    break;
                default:
                    return;
            }

        };

        ctrl.deleteOrgItem = function (orgItemId) {

            return itemsSrv.deleteOrgItems(orgItemId)
                           .then(function successCallback(itemsObj) {
                               ctrl.getOrgList();
                               return itemsObj;
                           });
        };

        ctrl.getStructureName = function(stId){
            return structureSrv.getProperty(stId, 'stName_en');
        };

        ctrl.toggleStructureStatus = function(orgItem){

            orgItem.gpDraftStatus = (orgItem.gpDraftStatus === '0') ? '1': '0';

            ctrl.updateItems({gpId:orgItem.gpId, gpDraftStatus:orgItem.gpDraftStatus});
        };

    }
})();
