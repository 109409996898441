(function () {
    'use strict';

    angular
        .module('pdgWp')
        .component('itemsMediaPhoto', {
            templateUrl: '/ng-app/admin-app/components/items-media-photo/items-media-photo.partial.html',
            bindings    : {
                photos          : '<',
                gpGid          : '<',
                onMediaChange : '&',
                onEdit : '&',
            },
            controller  : itemsMediaPhotoController,
            controllerAs: 'ctrl'
        });

    /** @ngInject */
    function itemsMediaPhotoController($scope, $q, $mdDialog, mediaSrv, itemsSrv, $rootScope) {

        var ctrl = this;

        ctrl.updateCatalogImage = function(mdId){

            mediaSrv.updateCatalogImage(mdId)
                    .then(function (itemsObj) {

                        $rootScope.$broadcast('event:itemDetailsStale', ctrl.gpGid);
                        $rootScope.$broadcast('event:queue-message', 'The catalog image has been updated.');

                    });

        };

        ctrl.updateItemMediaOrder = function (index, item, external, type) {

            $scope.$apply(function () {

                ctrl.photos.splice(index, 0, item);
            });

            itemsSrv.updateItemMediaOrder({gpMediaJson: ctrl.photos})
                    .then(function (itemsObj) {
                        $rootScope.$broadcast('event:itemDetailsStale', ctrl.gpGid);
                        $rootScope.$broadcast('event:queue-message', 'Media re-ordered.');

                    });

            return true;
        };

        ctrl.updateMedia = function (mediaObj, uploadedFile) {

            return mediaSrv.updateMedia(mediaObj, uploadedFile)
                           .then(function successCallback(mediaObj) {

                               // ctrl.getItemMedia(mediaObj.gpGid);

                               return mediaObj;
                           });
        };

        ctrl.edit = function (mediaObj) {
            ctrl.onEdit({'mediaObj': mediaObj});
        };

    } // ===================================================
})();
