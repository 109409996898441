(function () {
    'use strict';

    angular
        .module('pdgWp')
        .component('zeroPrices', {
            templateUrl: '/ng-app/admin-app/website-admin/reports/zero-prices/zero-prices.partial.html',
            controller  : zeroPrices,
            controllerAs: 'ctrl'
        });

    /** @ngInject */
    function zeroPrices(appConfig, itemsSrv,$stateParams, $http) {

        var ctrl = this;
        
        ctrl.__env = __env;

        ctrl.itemUrl = ctrl.__env.baseUrl + 'articles/view/';

        ctrl.results = [];

        ctrl.includeDisabled = '0';

        ctrl.$onInit = function(){
            ctrl.getReport();
        };

        ctrl.getReport = function(switchStatus){

            var itemsStatus = (angular.isDefined(switchStatus) && switchStatus === '1') ? '1':'' ;

            return $http.get(ctrl.__env.siteUrl + 'reports/zero_prices/' + itemsStatus)
                        .then(
                            function(response){
                                ctrl.items = response.data.data;

                                return response.data;
                            },
                            function(response){
                                alert('Report Error!');
                            }
                        )
        };


        ctrl.updateItems = function (itemsObj) {

            itemsSrv.updateArticles(itemsObj)
                    .then(function successCallback(itemsObj) {
                        return itemsObj;
                    });
        };

    }
})();
