(function() {
    'use strict';

    angular
        .module('pdgWp')
        .factory('catalogSrv', catalogSrv);

    /** @ngInject */
    function catalogSrv($window, $q, $http, itemsSrv) {

        var catalogSrv = this;

        catalogSrv.__env = $window.__env;

        catalogSrv.preloadedItems = [];

// ===================================================================================================

        catalogSrv.getCatalogObj = function (configObj) {

            var httpConfig = {
                method: 'GET',
                url   : catalogSrv.__env.siteUrl + 'catalog_api/get_catalog_object/',
                params: configObj
            };

            return $http(httpConfig)
                .then(function (response) {

                    if(angular.isDefined(configObj.preloadItems)){

                        if(angular.isDefined(response.data.data.catDataJson)){
                            catalogSrv.preloadItems(response.data.data.catDataJson.flow.items);
                        } else {
                            catalogSrv.preloadItems(response.data.data);
                        }
                    }


                    return response.data.data;

                }, function (response) {

                    return catalogSrv.errorCallback(Promise, response)
                })
                .then()
        };

// ===================================================================================================

        catalogSrv.saveCatalogObj = function (configObj) {

            var httpConfig = {
                method: 'POST',
                url   : catalogSrv.__env.siteUrl + 'catalog_api/save_catalog_object/',
                data: configObj
            };

            return $http(httpConfig)
                .then(function (response) {

                    return response.data.data;

                }, function (response) {

                    return catalogSrv.errorCallback(Promise, response)

                })
        };

// ===================================================================================================

        catalogSrv.preloadItems = function(itemsFlow){

            var flatLayout = lodash.flatMapDeep(itemsFlow, function(n){

                if(angular.isDefined(n.catDataJson)){
                    return [[n.catDataJson.flow.items]];

                } else {
                    return [[n]];
                }
            });

            var items = lodash
                .chain(flatLayout)
                .filter('gpId')
                .map('gpId')
                .join(',')
                .value();

            var configObj = {
                expandGid   : true,
                gpStatusFtr : 'all',
                itStatusFtr : 'all',
                detailed    : true,
                prices      : true,
                limit       : 'all',
                media       : true,
                gpTitle_en  : true,
                gpTitle_fr  : true,
                gpDetails_en: true,
                gpDetails_fr: true,
                gpBatchIdFtr: items
            };

            configObj.progressBar = 'main@';

            return itemsSrv.getItems(configObj)
                           .then(function (itemObj) {

                               catalogSrv.preloadedItems = itemObj;

                               return itemObj;
                           });
        };

        // ===================================================================================================

        catalogSrv.getDraftObjs = function (configObj) {

            var httpConfig = {
                method: 'GET',
                // url   : catalogSrv.__env.siteUrl + 'catalog_api/get_draft_objects/',
                url   : catalogSrv.__env.siteUrl + 'api_v1_0/items/',
                params: configObj
            };

            return $http(httpConfig)
                .then(function (response) {

                    // if(angular.isDefined(configObj.preloadItems)){
                    //
                    //     if(angular.isDefined(response.data.data.catDataJson)){
                    //         catalogSrv.preloadItems(response.data.data.catDataJson.flow.items);
                    //     } else {
                    //         catalogSrv.preloadItems(response.data.data);
                    //     }
                    // }

                    return response.data.data;

                }, function (response) {

                    return catalogSrv.errorCallback(Promise, response)
                })
                .then()
        };

// ===================================================================================================




// ===================================================================================================

        catalogSrv.errorCallback = function (promise, response) {

            return promise.reject(response);
        };

// ===================================================================================================

        return catalogSrv;
    }
})();
