(function () {
    'use strict';

    angular
        .module('pdgWp')
        .factory('mediaSrv', mediaSrv);

    /** @ngInject */
    function mediaSrv($document, $rootScope, $http, $log, $q, $window, msgSrv, appConfig, Upload) {

        var mediaSrv = this;

// ===================================================================================================

        mediaSrv.getMedia = function (configObj) {

            var httpConfig = {
                method: 'GET',
                url   : appConfig.apiUrl + 'media/',
                params: configObj
            };

            return $http(httpConfig)
                .then(function (response) {

                    if (angular.isDefined(httpConfig.params.getRow) && httpConfig.params.getRow) {
                        return mediaSrv.returnMediaObj(response)
                    }
                    else {
                        return mediaSrv.returnMediaList(response)
                    }

                }, function (response) {
                    return mediaSrv.errorCallback(Promise, response)
                })
        };

        // ===================================================================================================

        mediaSrv.getMediaList = function (configObj) {

            var httpConfig = {
                method: 'GET',
                url   : appConfig.apiUrl + 'media/' + mdId,
                params: {
                    detailed: true
                }
            };

            return $http(httpConfig)
                .then(function (response) {
                    return mediaSrv.returnMediaObj(response)
                }, function (response) {
                    return mediaSrv.errorCallback(Promise, response)
                })
        };
// ===================================================================================================
        mediaSrv.isValidMedia = function (url) {
            var regxPattern = new RegExp(appConfig.validMediaExtRegx, "i");
            return regxPattern.test(url);
        };

// ===================================================================================================
        /**
         * JavaScript function to match (and return) the video Id
         * of any valid Youtube Url, given as input string.
         */
        mediaSrv.isYoutubeUrl = function (url) {
            var p = /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
            return (url.match(p)) ? RegExp.$1 : false;
        };

// ===================================================================================================

        mediaSrv.updateMedia = function (mediaObj, uploadedFile) {

            var mdId = (angular.isDefined(mediaObj.mdId)) ? mediaObj.mdId : '';

            var receivedFile = null;

            if (angular.isDefined(uploadedFile)) {
                if (mdId !== '') {

                    receivedFile = Upload.dataUrltoBlob(uploadedFile, mediaObj.mdId + mediaObj.mdExt);

                }
                else {
                    receivedFile = uploadedFile;
                }
            }

            var httpConfig = {
                url : appConfig.adminApiUrl + 'process_media/',
                data: mediaObj
            };

            if (angular.isDefined(uploadedFile)) {

                httpConfig.data['file'] = receivedFile;

                return Upload
                    .upload(httpConfig)
                    .then(function (response) {
                        return mediaSrv.returnMediaObj(response)
                    }, function (response) {
                        return mediaSrv.errorCallback(Promise, response)
                    }, function (evt) {
                        mediaSrv.uploadProgress(evt)
                    })

            }
            else {

                return $http
                    .post(httpConfig.url, httpConfig.data)
                    .then(function (response) {
                        return mediaSrv.returnMediaObj(response)
                    }, function (response) {
                        return mediaSrv.errorCallback(Promise, response)
                    })
            }
        };

// ===================================================================================================

        mediaSrv.getExtMedia = function (configObj) {

            var httpConfig = {
                method: 'POST',
                url   : appConfig.adminApiUrl + 'get_ext_media/',
                data  : configObj
            };

            return $http(httpConfig)
                .then(function (response) {
                    return mediaSrv.returnMediaObj(response)
                }, function (response) {
                    return mediaSrv.errorCallback(Promise, response)
                })

        };

// ===================================================================================================

        mediaSrv.revertMedia = function (mdId) {

            var httpConfig = {
                method: 'POST',
                url   : appConfig.adminApiUrl + 'revert_media/' + mdId
            };

            return $http(httpConfig)
                .then(function (response) {
                    return mediaSrv.returnMediaObj(response)
                }, function (response) {
                    return mediaSrv.errorCallback(Promise, response)
                })

        };

// ===================================================================================================

        mediaSrv.updateCatalogImage = function (mdId) {

            var httpConfig = {
                method: 'POST',
                url   : appConfig.adminApiUrl + 'update_catalog_image/' + mdId
            };

            return $http(httpConfig)
                .then(function (response) {
                    return ;
                }, function (response) {
                    return mediaSrv.errorCallback(Promise, response);
                });

        };


// ===================================================================================================

        mediaSrv.returnMediaObj = function (response) {

            return (angular.isDefined(response.data.data[0])) ? response.data.data[0] : response.data.data;
        };

// ===================================================================================================

        mediaSrv.returnMediaList = function (response) {

            return response.data.data;
        };


// ===================================================================================================

        mediaSrv.errorCallback = function (promise, response) {

            return promise.reject(response);
        };

// ===================================================================================================

        mediaSrv.uploadProgress = function (evt) {
            var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
        };


// ===================================================================================================

        mediaSrv.imgCacheBuster = function (urlArray) {

            var allPromises = [];

            var promise;

            lodash.forEach(urlArray, function (thisUrl) {

                promise = fetch(thisUrl, {cache: "reload"})
                    .then(function (response) {

                        var imgElementArray = $document[0].querySelectorAll("img[src='" + thisUrl + "']");

                        lodash.forEach(imgElementArray, function (imgElement) {

                            imgElement.src = thisUrl;
                        });

                        return response;
                    })

                allPromises.push(promise);
            });

            return $q.all(allPromises);


        };

// ===================================================================================================

        mediaSrv.forceImgReload = function (url, forceImgReloadCallback) {

            mediaSrv.imgCacheBuster([url]);
        };

// ===================================================================================================
        return mediaSrv;
    }
})();
