(function () {
    'use strict';

    angular
        .module('pdgWp')
        .run(runBlock);

    /** @ngInject */
    function runBlock($window, appConfig, webAuthSrv, $rootScope) {

        $rootScope.lodash = lodash;

        appConfig.siteUrl = $window.__env.siteUrl;

        appConfig.baseUrl = $window.__env.baseUrl;

        appConfig.catalogLink = $window.__env.catalogUrl + '#p=';

        appConfig.apiUrl = appConfig.siteUrl + 'api_v1_0/';

        appConfig.adminApiUrl = appConfig.siteUrl + 'admin_api/';

        appConfig.accessApiPath = appConfig.siteUrl + 'access/';

        appConfig.canEditPages = true;

        appConfig.validMediaExtRegx = "\.(pdf|jpg|png)$";

        appConfig.userSalutations = ['Mr.', 'Mrs.', 'Miss', 'Ms.', 'Dr.', 'Capt.'];

        appConfig.countries = ['Sint Maarten', 'St Martin', 'Anguilla', 'Antigua', 'Barbados', 'Bequia', 'Bonaire', 'Canouan', 'Curacao', 'Dominica', 'Grenada', 'Guadeloupe', 'Jamaica', 'Jost van Dyke', 'Martinique', 'Montserrat', 'Mustique', 'Nevis', 'Saba', 'St Barts', 'St Kitts', 'St Lucia', 'St Thomas', 'St Vincent', 'Statia', 'Suriname', 'Tortola', 'Trinidad', 'Virgin Gorda'];

        appConfig.pinkiesState = {};
        appConfig.itemsState = {};

        appConfig.catalogTemplate = [
            'Small',
            'Medium (Default)',
            'Large',
            'X-Large',
            'full-width-two-columns'
            ];

        appConfig.specsHeaderNames = [
            '',
            '#',
            '°C',
            '°F',
            'ø INCH',
            'ø CM',
            'ø INCH X H.',
            'ø CM X H.',
            'AIRTIGHT LID',
            'BASE CM',
            'BASE INCH',
            'BLADE CM',
            'BLADE INCH',
            'BTU',
            'CAPACITY',
            'CL',
            'CM',
            'COLOR',
            'CUP CM',
            'CUP INCH',
            'DESCRIPTION',
            'DOME LID',
            'FEET',
            'FLOWRATE GAL',
            'FLOW RATE GL/LTR',
            'FLOWRATE LITERS',
            'FT',
            'GAL',
            'GAS',
            'GAUGE',
            'GR',
            'H CM',
            'H INCH',
            'HOSE FT',
            'HOSE MTR',
            'HP',
            'INCH',
            'KG',
            'LBS/DZ',
            'KG/DZ',
            'Ltr',
            'LBS',
            'LENGTH CM',
            'LENGTH INCH',
            'LID',
            'LTR',
            'MATERIAL',
            'MAX LBS',
            'MAX KG',
            'MICRON',
            'ML',
            'MM',
            'MODEL NR',
            'MTR',
            'NECK INCH',
            'NO. SLICES',
            'ORT',
            'OZ',
            'PORTIONS',
            'POWER',
            'PRECISION',
            'QRT',
            'QTY',
            'QTY BOX',
            'QTY CS',
            'QTY PACK',
            'ROLLS',
            'SAUCER',
            'SERIES',
            'SIZE',
            'SIZE EUR',
            'SIZE US',
            'SLOTTED LID',
            'SOLD PER',
            'SPOUT CM',
            'SPOUT INCH',
            'TUBE CM',
            'VENTED LID'
        ];

        appConfig.sideNavePath = {
            'ordersList' : '/ng-app/admin-app/web-orders-mgr/orders/orders-list.partial.html',
            'itemsList'  : '/ng-app/admin-app/website-admin/items/items-list.partial.html',
            'pinkiesList'  : '/ng-app/admin-app/website-admin/pinkies/pinkies-list.partial.html',
            'clientsList': '/ng-app/admin-app/web-orders-mgr/clients/clients-list.partial.html',
            'usersList'  : '/ng-app/admin-app/website-admin/users/users-list.partial.html',
            'unitsConverter'  : '/ng-app/admin-app/tools/units-converter/units-converter.partial.html',
            'draftCatNav'  : '/ng-app/admin-app/catalog-admin/draft-catalog/draft-catalog-nav.partial.html'
        }

        webAuthSrv.init();

    }

})();
