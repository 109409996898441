(function () {
    'use strict';

    angular
        .module('pdgWp')
        .config(clientsConfig);

    /** @ngInject */
    function clientsConfig($stateProvider) {

        var clients = {
            name   : 'clients',
            url    : '/clients',
            resolve: {
                clientProfile: function () {
                    return {};
                }
            },
            views  : {
                'main@'        : {
                    templateUrl : '/ng-app/admin-app/web-orders-mgr/clients/clients-profile-landing.partial.html',
                    controller  : 'ClientProfileController',
                    controllerAs: 'cltProfileCtrl'
                }
            }
        };

        var clientProfile = {
            name   : 'clients.clientProfile',
            url    : '/{usId}',
            resolve: {
                clientProfile: function (usersSrv, $stateParams) {

                    var configObj = {
                        clients    : true,
                        usStatusFtr: 'all',
                        getRow     : true,
                        usIdFtr    : $stateParams.usId
                    };

                    configObj.progressBar = 'main@';

                    return usersSrv.getUsers(configObj)
                                   .then(function (clientObj) {
                                       return clientObj;
                                   });
                }
            },
            views  : {
                'main@': {
                    templateUrl : '/ng-app/admin-app/web-orders-mgr/clients/clients-profile.partial.html',
                    controller  : 'ClientProfileController',
                    controllerAs: 'cltProfileCtrl'
                }
            }
        };

        $stateProvider.state(clients);

        $stateProvider.state(clientProfile);

    }

})();
