(function () {
    'use strict';

    angular
        .module('pdgWp')
        .component('itemsMediaYoutube', {
            templateUrl: '/ng-app/admin-app/components/items-media-youtube/items-media-youtube.partial.html',
            bindings    : {
                items          : '<',
                gpGid          : '<',
                onClick : '&',
            },
            controller  : itemsMediaYoutubeController,
            controllerAs: 'ctrl'
        });

    /** @ngInject */
    function itemsMediaYoutubeController($scope, $rootScope, $q, mediaSrv, itemsSrv, webAuthSrv) {

        var ctrl = this;

        ctrl.levels = webAuthSrv.levels;

        ctrl.media = {};

        ctrl.updateItemMediaOrder = function (index, item, external, type) {

            $scope.$apply(function () {

                ctrl.items.splice(index, 0, item);
            });

            itemsSrv.updateItemMediaOrder({gpMediaJson: ctrl.items})
                    .then(function (itemsObj) {

                    });

            return true;
        };

        ctrl.getMedia = function (gpGid) {

            var configObj = {
                gpGidFtr         : gpGid,
                mdTypeFtr        : 'document',
                mdNotAuthLevelFtr: 'authPublic',
                jsonItem         : true
            };

            return mediaSrv.getMedia(configObj)
                           .then(function (mediaObj) {

                               ctrl.images.document = lodash.union(ctrl.images.document, mediaObj);

                               ctrl.images.document = lodash.orderBy(ctrl.images.document, ['mdOrder']);

                               return ctrl.images.document;
                           });
        };


        ctrl.updateMedia = function (mediaObj) {
            mediaSrv.updateMedia(mediaObj)
                    .then(function successCallback(mediaObj) {
                        $rootScope.$broadcast('event:queue-message', 'Modifications has been saved');
                    });
        };

        ctrl.onClickVideo = function (mediaObj) {
            ctrl.onClick({'mediaObj': mediaObj});
        };

    } // ===================================================
})();
