(function () {
    'use strict';

    angular
        .module('pdgWp')
        .filter('since', since);

    /** @ngInject */
    function since() {
        return function (date) {

            return moment(date).fromNow();
        };
    }
})();


(function () {
    'use strict';

    angular
        .module('pdgWp')
        .filter('trustAsHTML', trustAsHTML);

    /** @ngInject */
    function trustAsHTML($sce) {
        return function(text) {
            return $sce.trustAsHtml(text);
        };
    }
})();



(function () {
    'use strict';

    angular
        .module('pdgWp')
        .filter('newlines', newlines);

    /** @ngInject */
    function newlines() {
        return function(text) {
            return (angular.isUndefined(text) || lodash.isEmpty(text)) ? '' : text.replace(/\n/g, '<br/>');
        }
    }
})();

(function () {
    'use strict';

    angular
        .module('pdgWp')
        .filter('words', words);

    /** @ngInject */
    function words() {
        return function (input, words) {
            if (isNaN(words)) return input;
            if (words <= 0) return '';
            if (input) {
                var inputWords = input.split(/\s+/);
                if (inputWords.length > words) {
                    input = inputWords.slice(0, words).join(' ') + '…';
                }
            }
            return input;
        };
    }
})();
