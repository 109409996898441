(function () {
    'use strict';

    angular
        .module('pdgWp')
        .controller('DashboardController', DashboardController);

    /** @ngInject */
    function DashboardController($timeout, ordersList, $mdSidenav, $state, cartSrv, msgSrv) {

        var dashCtrl = this;

        dashCtrl.ordersList = ordersList;
        dashCtrl.isLoading = true;
        dashCtrl.cropperObj = {};

        dashCtrl.$onInit = function(){
            // rootCtrl.toggleSidenavLock('sideNavRight')
            $mdSidenav('sideNavRight').close();
        };


        dashCtrl.loadOrder = function (orId) {

            $state.go('orders.orderDetails', {orId: orId});
        };



        dashCtrl.showToast = function(type){
            msgSrv.showToast('info', 'this is a test');

            var image = document.getElementById('image');
            dashCtrl.cropperObj = new Cropper(image, {
                aspectRatio: 16 / 9,
                crop: function(e) {
                    // console.log(e.detail.x);
                    // console.log(e.detail.y);
                    // console.log(e.detail.width);
                    // console.log(e.detail.height);
                    // console.log(e.detail.rotate);
                    // console.log(e.detail.scaleX);
                    // console.log(e.detail.scaleY);
                }
            });
        };

        dashCtrl.cropperZoom = function(){
            dashCtrl.cropperObj.zoom(0.1);
        };

        dashCtrl.getOrder = function () {

            var configObj = {
                getRow : true,
                orIdFtr: '20',
                error  : true
            };

            return cartSrv.getOrders(configObj)
                          .then(function (orderObj) {
                              msgSrv.addMessage([{message:'success'}]);
                              return orderObj;
                          }, function (orderObj) {

                              return ;
                          })
        }
    }

})();
