(function () {
    'use strict';

    angular
        .module('pdgWp')
        .config(usersConfig);

    /** @ngInject */
    function usersConfig($stateProvider) {

        var users = {
            name   : 'users',
            url    : '/users',
            resolve: {
                userProfile: function () {
                    return {};
                }
            },
            views  : {
                'main@'        : {
                    templateUrl : '/ng-app/admin-app/website-admin/users/users-profile-landing.partial.html',
                    controller  : 'UserProfileController',
                    controllerAs: 'uProfileCtrl'
                }
            }
        };

        var userProfile = {
            name   : 'users.userProfile',
            url    : '/{usId}',
            resolve: {
                userProfile: function (usersSrv, $stateParams) {

                    var configObj = {
                        employees  : true,
                        usStatusFtr: 'all',
                        getRow     : true,
                        usIdFtr    : $stateParams.usId
                    };

                    configObj.progressBar = 'main@';

                    return usersSrv.getUsers(configObj)
                                   .then(function (userObj) {
                                       return userObj;
                                   });
                }
            },
            views  : {
                'main@': {
                    templateUrl : '/ng-app/admin-app/website-admin/users/users-profile.partial.html',
                    controller  : 'UserProfileController',
                    controllerAs: 'uProfileCtrl'
                }
            }
        };

        $stateProvider.state(users);
        $stateProvider.state(userProfile);
    }

})();
