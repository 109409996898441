(function () {
    'use strict';

    angular
        .module('pdgWp')
        .component('itemsMediaBin', {
            templateUrl: '/ng-app/admin-app/components/items-media-bin/items-media-bin.partial.html',
            bindings    : {
                gpGid          : '<',
                onRestored : '&',
                onClickVideo: '&',
            },
            controller  : itemsMediaBinController,
            controllerAs: 'ctrl'
        });

    /** @ngInject */
    function itemsMediaBinController($scope, mediaSrv) {

        var ctrl = this;

// ===================================================================================================

        ctrl.$onInit = function(){

            ctrl.getItemMedia();
        };

        ctrl.getItemMedia = function () {

            var configObj = {
                detailed: true,
                gpGidFtr: ctrl.gpGid,
                mdStatus: '0',
                jsonItem: true
            };

            mediaSrv.getMedia(configObj)
                    .then(function (mediaList) {

                        ctrl.items = mediaList;
                    });
        };

        ctrl.clickVideo = function (mediaObj) {
            ctrl.onClickVideo({'mediaObj': mediaObj});
        };


// ===================================================================================================

        ctrl.restoreMedia = function (index, mediaObj) {

            mediaObj.mdStatus = '1';

            mediaSrv.updateMedia(mediaObj)
                    .then(function successCallback(mediaObj) {

                        ctrl.items.splice(index, 1);

                        ctrl.onRestored({'mediaObj': mediaObj});
                    });
        };

    } // ===================================================
})();
