(function () {
    'use strict';

    angular
        .module('pdgWp')
        .factory('usersSrv', usersSrv);

    /** @ngInject */
    function usersSrv($http, msgSrv, appConfig, localStorageSvr) {

        var usersSrv = this;

        usersSrv.currentUser = {};

        localStorageSvr.register(usersSrv, 'currentUser', usersSrv.currentUser);

        // localStorageSvr.set('currentUser', usersSrv.currentUser);

// ===================================================================================================

        usersSrv.clearCurrentUser = function (){

            lodash.forIn(usersSrv.currentUser, function(value, key) {
                delete usersSrv.currentUser[key];
            });

            localStorageSvr.set('currentUser', usersSrv.currentUser);

        };

        usersSrv.getUsers = function (configObj) {

            var httpConfig = {
                method: 'GET',
                url   : appConfig.apiUrl + 'users/',
                params: configObj
            };

            return $http(httpConfig)
                .then(function (response) {

                    if(angular.isDefined(httpConfig.params.getRow) && httpConfig.params.getRow){

                        return usersSrv.returnUsersObj(response)

                    } else {

                        if(angular.isDefined(httpConfig.params.getPaging)){

                            return usersSrv.returnUsersPagingList(response)

                        } else {
                            return usersSrv.returnUsersList(response)
                        }

                    }

                }, function (response) {

                    return usersSrv.errorCallback(Promise, response)

                })
        };

// ===================================================================================================

        usersSrv.updateUsers = function (usersObj) {

            var httpConfig = {
                method: 'POST',
                url   : appConfig.apiUrl + 'users/',
                data  : usersObj
            };

            return $http(httpConfig)
                .then(
                    function(response){return usersSrv.returnUsersObj(response)},
                    function(response){return usersSrv.errorCallback(Promise, response)}
                )

        };


// ===================================================================================================

        usersSrv.returnUsersObj = function (response) {

            var userObj = (angular.isDefined(response.data.data[0])) ? response.data.data[0] : response.data.data;

            usersSrv.currentUser = lodash.merge(usersSrv.currentUser, userObj);

            localStorageSvr.set('currentUser', usersSrv.currentUser);

            return userObj;
        };


// ===================================================================================================

        usersSrv.returnUsersList = function (response){
            return response.data.data;
        };

// ===================================================================================================

        usersSrv.returnUsersPagingList = function (response){
            return response.data;
        };

// ===================================================================================================

        usersSrv.errorCallback = function (promise, response) {

            return promise.reject(response);
        };

// ===================================================================================================


        return usersSrv;
    }
})();
