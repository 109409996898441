var lodash = _.noConflict();

(function() {
  'use strict';

  angular
    .module('pdgWp', [
        'ngMessages',
        'ngMaterial',
        'http-auth-interceptor',
        'ui.router',
        'dndLists',
        'ngclipboard',
        'ngFileUpload',
        'ngYoutubeEmbed',
        'angularTrix'
    ]);

})();
