/**
 * Created by Jonathan on 17/04/19.
 */
(function () {
    'use strict';

    angular
        .module('pdgWp')
        .filter('trusted', trusted);

    /** @ngInject */
    function trusted($sce) {
        return function (url) {
            return $sce.trustAsResourceUrl(url);
        }
    }
})();

(function () {
    'use strict';

    angular
        .module('pdgWp')
        .filter('trustedHTML', trustedHTML);

    /** @ngInject */
    function trustedHTML($sce) {
        return function (text) {
            return $sce.trustAsHtml(text);
        }
    }
})();


(function () {
    'use strict';

    angular
        .module('pdgWp')
        .filter('excerpt', excerpt);

    /** @ngInject */
    function excerpt() {
        return function (value, wordwise, max, tail) {
            if (!value) return '';

            max = parseInt(max, 10);
            if (!max) return value;
            if (value.length <= max) return value;

            value = value.substr(0, max);
            if (wordwise) {
                var lastspace = value.lastIndexOf(' ');
                if (lastspace !== -1) {
                    //Also remove . and , so its gives a cleaner result.
                    if (value.charAt(lastspace - 1) === '.' || value.charAt(lastspace - 1) === ',') {
                        lastspace = lastspace - 1;
                    }
                    value = value.substr(0, lastspace);
                }
            }

            return value + (tail || ' …');
        };
    }
})();

(function () {
    'use strict';

    angular
        .module('pdgWp')
        .filter('numberFixedLen', numberFixedLen);

    /** @ngInject */
    function numberFixedLen() {
        return function (n, len) {
            var num = parseInt(n, 10);
            len = parseInt(len, 10);
            if (isNaN(num) || isNaN(len)) {
                return n;
            }
            num = '' + num;
            while (num.length < len) {
                num = '0' + num;
            }
            return num;
        };
    }
})();



// =================================================================================
// Generate values from 0 to 5
// <div ng-repeat="n in [5] | range">{{n}}</div>
//
// Generate values from 1 to 5
// <div ng-repeat="n in [1, 5] | range">{{n}}</div>
//
// Generate values from 5 to 1
// <div ng-repeat="n in [5, 1] | range">{{n}}</div>
// =================================================================================
(function () {
    'use strict';

    angular
        .module('pdgWp')
        .filter('range', range);

    /** @ngInject */
    function range () {
        return function (input) {
            var lowBound, highBound

            if (!input) {
                return null
            }

            switch (input.length) {
                case 1:
                    lowBound = 0
                    highBound = parseInt(input[0], 10) - 1
                    break
                case 2:
                    lowBound = parseInt(input[0], 10)
                    highBound = parseInt(input[1], 10)
                    break
                default:
                    return input
            }

            var result = []

            if (lowBound < highBound) {
                for (var i = lowBound; i <= highBound; i++) {
                    result.push(i)
                }
            } else {
                for (var j = lowBound; j >= highBound; j--) {
                    result.push(j)
                }
            }

            return result
        }
    }
})();
