(function () {
    'use strict';

    angular
        .module('pdgWp')
        .controller('DraftCatNavController', DraftCatNavController);

    /** @ngInject */
    function DraftCatNavController($mdSidenav, $state, $rootScope, structureSrv) {

        var draftNavCtrl = this;

        draftNavCtrl.navigation = [];

        draftNavCtrl.foldingArray = [];

        draftNavCtrl.$onInit = function () {

            draftNavCtrl.getNav();

        };

        draftNavCtrl.organizeStructure = function (chapterIndex, sectionIndex, index, item) {

            var tempObj = {};

            var configObj = {
                stId     : item.stId,
                stOrder  : (index * 10) + 5,
                stChapId : (chapterIndex !== false) ? draftNavCtrl.navigation[chapterIndex].stId : undefined,
                stSectId : (sectionIndex !== false) ? draftNavCtrl.navigation[chapterIndex].stItems[sectionIndex].stId : undefined,
                srcParent: item.stParent,
                srcChapId: item.stChapId,
                srcSectId: item.stSectId
            };

            if(sectionIndex !== false){

                if (configObj.stSectId != configObj.srcParent) {

                    alert("Item cannot be moved outside its parent container.")
                    return false;
                }

            } else if(chapterIndex !== false){

                if (configObj.stChapId != configObj.srcParent) {

                    alert("Item cannot be moved outside its parent container.")
                    return false;
                }

            } else {

            }

            configObj = lodash.merge(configObj, tempObj);

            // return;
            structureSrv.organizeStructure(configObj)
                        .then(function successCallback(structureObj) {

                            draftNavCtrl.getNav();

                            return true;

                        }, function errorCallback(structureObj) {

                            return false;
                        });

            return true;

        };

        draftNavCtrl.getNav = function () {

            var configObj = {
                stGetBranch: true,
                forceArray : true,
                stStatusFtr: 'all',
                stDraftStatusFtr: '1'
            };

            return structureSrv.getStructure(configObj)
                               .then(function (structureObj) {

                                   draftNavCtrl.navigation = structureObj.stItems;

                                   return ;
                               });
        };

        draftNavCtrl.loadDraft = function (stId) {

            $state.go('draftCatalog.details', {gpCateId:stId});

            if(! $mdSidenav('sideNavRight').isLockedOpen()){
                $mdSidenav('sideNavRight', true).close();
            }


        };

        draftNavCtrl.foldNav = function (id) {

            var idIndex = draftNavCtrl.foldingArray.indexOf(id);

            if(idIndex == -1){

                draftNavCtrl.foldingArray.push(id);

            } else {

                draftNavCtrl.foldingArray.splice(idIndex, 1);
            }

            return true;
        };

        draftNavCtrl.collapseNav = function () {

            draftNavCtrl.foldingArray = [];
        };

        draftNavCtrl.isExpended = function (id) {

            return (draftNavCtrl.foldingArray.indexOf(id) !== -1);
        }

    }
})();
