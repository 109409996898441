(function () {
    'use strict';

    angular
        .module('pdgWp')
        .controller('DraftCatalogController', DraftCatalogController);

    /** @ngInject */
    function DraftCatalogController($log, $rootScope, $state, $stateParams, $mdDialog, catalogSrv, itemsSrv, $timeout, appConfig) {

        var draftCatCtrl = this;
        
        draftCatCtrl.draftObjs = {};

        draftCatCtrl.loadedItems = [];

        draftCatCtrl.appConfig = appConfig;

        draftCatCtrl.showAll = '1';

        draftCatCtrl.showInfo = false;

        draftCatCtrl.$onInit = function () {

            if(angular.isDefined($stateParams.gpCateId)){
                draftCatCtrl.getDraftObjs();
            }

            $rootScope.$broadcast('event:setSideNavRight', 'draftCatNav');
        };


        draftCatCtrl.getDraftObjs = function(){

            var configObj = {
                gpCateIdFtr:$stateParams.gpCateId,
                gpDraftOrderOdr: 'ASC',
                fullProfile     : true,
                expandGid  : true,
                prices     : true, // itItemIdOdr: true,
                itStatusFtr: 'all',
                itDraftStatusFtr: draftCatCtrl.showAll,
                gpDraftStatusFtr: draftCatCtrl.showAll,
                limit      : 'all',
                media       : true,
                gpCatalogDescription_en: true,
                gpCatalogDescription_fr: true,
                gpCatalogExtra_en: true,
                adminOnly : (draftCatCtrl.showInfo) ? true : null
            };

            return catalogSrv.getDraftObjs(configObj)

                             .then(function (response) {

                                 draftCatCtrl.loadedItems = response;

                                 if($state.includes('*.print')){

                                     $timeout(function() {
                                         // Trigger the print dialog.
                                         window.print();

                                         // Close the window after printing (you might want to confirm with the user).
                                         window.close();
                                     },2000);
                                 }


                                 return ;
                             });

        };


        draftCatCtrl.updateArticles = function (itemsObj) {

            itemsSrv.updateArticles(itemsObj)
                    .then(function successCallback(response) {

                        // var tempObj = lodash.find(itemDetailsCtrl.currentItem.gpItems, ['itId', itemsObj.itId]);
                        // lodash.merge(tempObj, itemsObj);
                        // $rootScope.$broadcast('event:queue-message', 'Modifications has been saved');
                        // return response;

                        $rootScope.$broadcast('event:queue-message', 'Modifications has been saved');

                        draftCatCtrl.getDraftObjs();


                    }, function () {

                        // ERROR

                    });
        };

        draftCatCtrl.print = function(){
            var id = $stateParams.gpCateId;

            window.open('/print-draft.html#!/draft-catalog/print/' + id, '_blank');
        }

        draftCatCtrl.updateItems = function(itemsObj){


            itemsSrv.updateItems(itemsObj)
                    .then(function successCallback(response) {


                        $rootScope.$broadcast('event:queue-message', 'Modifications has been saved');

                        return response;
                    }, function () {


                    });

        }

        draftCatCtrl.organizeDraftItems = function (event, index, item){

            var configObj = {
                gpId     : item.gpId,
                gpDraftOrder  : (index * 10) + 5,
                doDraftOrder: item.gpCateId
            };

            itemsSrv.updateItems(configObj)
                    .then(function successCallback(itemsObj) {

                        $rootScope.$broadcast('event:queue-message', 'Modifications has been saved');

                        // $state.reload();

                        draftCatCtrl.getDraftObjs();
                    }, function () {

                        // ERROR

                    });

        };

            draftCatCtrl.editDraftItem = function (item) {

            $mdDialog.show({
                // clickOutsideToClose: true,
                locals          : {itemDetails: item},
                fullscreen      : true,
                bindToController: true,
                templateUrl     : '/ng-app/admin-app/catalog-admin/draft-catalog/draft-item-edit/draft-item-edit.partial.html',
                controllerAs    : 'itemDetailsCtrl',
                controller      : 'ItemDetailsController'
            })
                     .then(function (response) {

                         $rootScope.$broadcast('event:queue-message', 'Modifications has been saved');

                         draftCatCtrl.$onInit();

                     }, function (response) {


                     });
        };


        /*
                draftCatCtrl.getPageItems = function(){

                    var flatLayout = lodash.flatMapDeep(draftCatCtrl.flow.items, function(n){

                        return [[n]];
                    });

                    var items = lodash
                        .chain(flatLayout)
                        .filter('gpId')
                        .map('gpId')
                        .join(',')
                        .value();

                    var configObj = {
                        expandGid   : true,
                        gpStatusFtr : 'all',
                        itStatusFtr : 'all',
                        detailed    : true,
                        prices      : true,
                        limit       : 'all',
                        media       : true,
                        gpTitle_en  : true,
                        gpTitle_fr  : true,
                        gpDetails_en: true,
                        gpDetails_fr: true,
                        gpBatchIdFtr: items
                    };

                    configObj.progressBar = 'main@';

                    return itemsSrv.getItems(configObj)
                                   .then(function (itemObj) {

                                       draftCatCtrl.loadedItems = itemObj;

                                       return itemObj;
                                   });
                };

                draftCatCtrl.orderLayout = function (index, layoutObj) {

                    layoutObj.splice(index, 1);

                    saveCatalogObjDebounce({catId:catObj.catId, catDataJson: catObj.catDataJson});
                };

                draftCatCtrl.saveCatalogObj = function (catalogObj) {

                    catalogObj.progressBar = 'main@';

                    return catalogSrv.saveCatalogObj(catalogObj)
                            .then(function successCallback(catalogObj) {

                                $rootScope.$broadcast('event:queue-message', 'Modifications has been saved');

                                return catalogObj;
                            }, function () {

                                // $state.reload();

                            })
                };

                draftCatCtrl.getCopy = function (item){

                    return angular.copy(item)
                };

                draftCatCtrl.itemChanged = function (item, itemObj) {

                    lodash.remove(item, function(n) {
                        return true;
                    });

                    lodash.merge(item, itemObj);
                    saveCatalogObjDebounce({catId:catObj.catId, catDataJson: catObj.catDataJson})
                               // .then(function(){
                               //         // $state.reload();
                               // },
                               //     function(){
                               //
                               //     });
                    // $log.info(item);
                };

                var saveCatalogObjDebounce = lodash.debounce(draftCatCtrl.saveCatalogObj, 250)
        */

    }
})();
