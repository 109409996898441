(function () {
    'use strict';

    angular
        .module('pdgWp')
        .directive('pdgOnload', pdgOnload);

    /** @ngInject */
    function pdgOnload($parse) {
        return {
            restrict: 'A',
            link    : function (scope, elem, attrs) {
                var fn = $parse(attrs.pdgOnload);
                elem.on('load', function (event) {
                    scope.$apply(function () {
                        fn(scope, {$event: event});
                    });
                });
            }
        };
    }
})();
