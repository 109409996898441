(function () {
    'use strict';

    angular
        .module('pdgWp')
        .config(ordersConfig);

    /** @ngInject */
    function ordersConfig($stateProvider) {

        var orders = {
            name   : 'orders',
            url    : '/orders',
            resolve: {
                ordersList  : function (cartSrv) {

                    var configObj = {
                        getPaging        : "true",
                        limit            : "25",
                        orProStatusFtr   : "ongoing",
                        orCreatedOrd: 'ASC',
                        page             : "1"
                    };

                    return cartSrv.getOrders(configObj)
                                  .then(function (usersList) {
                                      return usersList.data;
                                  });
                },
                orderDetails: function () {
                    return {};
                }
            },
            views  : {
                'sideNavRight@': {
                    templateUrl : '/ng-app/admin-app/web-orders-mgr/orders/orders-list.partial.html',
                    controller  : 'OrdersListController',
                    controllerAs: 'oListCtrl'
                },
                'main@'        : {
                    templateUrl : '/ng-app/admin-app/web-orders-mgr/orders/order-details-landing.partial.html',
                    controller  : 'OrderDetailsController',
                    controllerAs: 'oDetailsCtrl'
                }
            }
        };

        var orderDetails = {
            name   : 'orders.orderDetails',
            url    : '/{orId}',
            resolve: {
                orderDetails: function (cartSrv, $stateParams) {

                    var configObj = {
                        orStatusFtr: 'all',
                        orCartJson : true,
                        orMergeSystem : true,
                        getRow     : true,
                        orIdFtr    : $stateParams.orId
                    };

                    configObj.progressBar = 'main@';

                    return cartSrv.getOrders(configObj)
                                  .then(function (orderObj) {
                                      return orderObj;
                                  });
                }
            },
            views  : {
                'main@': {
                    templateUrl : '/ng-app/admin-app/web-orders-mgr/orders/order-details.partial.html',
                    controller  : 'OrderDetailsController',
                    controllerAs: 'oDetailsCtrl'
                }
            }
        };

        $stateProvider.state(orders);

        $stateProvider.state(orderDetails);
    }

})();
