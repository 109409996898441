(function () {
    'use strict';

    angular
        .module('pdgWp')
        .controller('ItemsListController', ItemsListController);

    /** @ngInject */
    function ItemsListController($scope, $mdSidenav, $mdDialog, $state, structureSrv, itemsSrv, appConfig, searchSvr) {

        var itemsListCtrl = this;

        itemsListCtrl.itemsList = [];
        itemsListCtrl.limit = 10;
        itemsListCtrl.page = 1;
        itemsListCtrl.searchObj = {
            sort             : {gpModOdr: 'DESC'},
            filter           : {
                gpStatusFtr       : '1',
                itStatusFtr       : 'all',
                gpCatalogStatusFtr: 'all',
                gpDraftStatusFtr  : 'all',
                itDraftStatusFtr  : 'all',
                itBreStatusFtr  : 'all'
            },
            filteredStructure: {},
            structure: {
                gpChapId: '',
                gpSectId: '',
                gpCateId: ''
            },
            selectedTemplate: 'Default'
        };

        itemsListCtrl.structureArray = structureSrv.fullArray;

        itemsListCtrl.showImages = true;

        itemsListCtrl.$onInit = function () {

            if (lodash.isEmpty(appConfig.itemsState)){

                itemsListCtrl.searchItems();

            } else {
                itemsListCtrl.itemsList = appConfig.itemsState.itemsList;
                itemsListCtrl.limit = appConfig.itemsState.limit;
                itemsListCtrl.page = appConfig.itemsState.page;
                itemsListCtrl.searchObj = appConfig.itemsState.searchObj;
            }

        };

        itemsListCtrl.loadItem = function (gpGid) {

            $state.go('items.itemDetails', {gpGid: gpGid});

            if (!$mdSidenav('sideNavRight').isLockedOpen()) {
                $mdSidenav('sideNavRight', true).close();
            }
        };

        itemsListCtrl.setPage = function (page) {

            var page = 1 * page;

            if (-1 <= page <= 1) {
                itemsListCtrl.page = (itemsListCtrl.page + page >= 1) ? itemsListCtrl.page + page : 1;
            }
            else {
                itemsListCtrl.page = (page >= 1) ? page : 1;
            }

            itemsListCtrl.searchItems();
        };

        itemsListCtrl.updateSearch = function (searchText) {

            itemsListCtrl.searchText = (angular.isDefined(searchText)) ? searchText : itemsListCtrl.searchText;
            itemsListCtrl.page = 1;
            itemsListCtrl.searchItems();
        };

        itemsListCtrl.itemsSearchDlg = function () {

            $mdDialog.show({
                // clickOutsideToClose: true,
                locals          : {
                    searchObj: itemsListCtrl.searchObj
                },
                fullscreen      : true,
                bindToController: true,
                templateUrl     : '/ng-app/admin-app/website-admin/items/items-search-dlg/items-search-dlg.partial.html',
                controllerAs    : 'itemSearchDlgCtrl',
                controller      : 'ItemsSearchDlgController'
            })
                     .then(function (response) {

                         itemsListCtrl.searchItems(1);

                     }, function (response) {

                     });

        };

        itemsListCtrl.searchItems = function (page, limit) {

            itemsListCtrl.limit = (angular.isDefined(limit)) ? limit : itemsListCtrl.limit;

            itemsListCtrl.page = (angular.isDefined(page)) ? page : itemsListCtrl.page;

            var configObj = {
                search     : true,
                expandGid  : true,
                prices     : true, // itItemIdOdr: true,
                getPaging  : true,
                itStatusFtr: 'all',
                limit      : itemsListCtrl.limit,
                page       : itemsListCtrl.page
            };

            configObj = lodash.merge(configObj, itemsListCtrl.searchObj.sort, itemsListCtrl.searchObj.filter, itemsListCtrl.searchObj.filteredStructure, itemsListCtrl.searchObj.other);

            if (itemsListCtrl.searchText === undefined || itemsListCtrl.searchText === '') {

            }
            else if (itemsListCtrl.searchText.substring(0, 1) === '=') {

                if (itemsListCtrl.searchText.length >= 3) {

                    configObj.gpCpIdFlxFtr = itemsListCtrl.searchText.substring(1);

                }
                else {

                    return;
                }

            }
            else if (itemsListCtrl.searchText.substring(0, 2) === 't:') {

                if (itemsListCtrl.searchText.length >= 5) {

                    configObj.itemStarFtr = itemsListCtrl.searchText.substring(2);

                }
                else {

                    return;
                }

            }
            else if (itemsListCtrl.searchText.substring(0, 2) === 'v:') {

                if (itemsListCtrl.searchText.length >= 5) {

                    configObj.cpStarFtr = itemsListCtrl.searchText.substring(2);

                }
                else {

                    return;
                }

            }
            else if (isFinite(itemsListCtrl.searchText) && itemsListCtrl.searchText.length <= 3) {
                configObj.gpCatPageFtr = itemsListCtrl.searchText;
            }
            else if (isFinite(itemsListCtrl.searchText) && itemsListCtrl.searchText.length >= 4 && itemsListCtrl.searchText.length <= 7) {
                configObj.itItemIdFlxFtr = itemsListCtrl.searchText;

            }
            else {
                configObj.gpTitleFtr = itemsListCtrl.searchText;
            }

            configObj.progressBar = 'sideNavRight@';

            return itemsSrv.getItems(configObj)
                           .then(function (itemsList) {
                               itemsListCtrl.page = 1 * itemsList.config.page;
                               itemsListCtrl.limit = 1 * itemsList.config.limit;
                               itemsListCtrl.count = 1 * itemsList.count;

                               itemsListCtrl.itemsList = itemsList.data;

                               return itemsListCtrl.itemsList.data;
                           });
        };


        var deReg1;

        deReg1 = $scope.$on('event:itemListStale', function () {

            itemsListCtrl.searchItems();
        });


        itemsListCtrl.$onDestroy = function handler() {
            deReg1();

            appConfig.itemsState = {
                "itemsList" : itemsListCtrl.itemsList,
                "limit" : itemsListCtrl.limit,
                "page" : itemsListCtrl.page,
                "searchObj" : itemsListCtrl.searchObj
            };

        };

    }
})();
