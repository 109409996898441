(function () {
    'use strict';

    angular
        .module('pdgWp')
        .config(draftCatalogConfig);

    /** @ngInject */
    function draftCatalogConfig($stateProvider) {

        var draftCatalog = {
            name   : 'draftCatalog',
            url    : '/draft-catalog',
            views  : {
                'main@'        : {
                    templateUrl: '/ng-app/admin-app/catalog-admin/draft-catalog/draft-catalog.partial.html',
                    controller  : 'DraftCatalogController',
                    controllerAs: 'draftCatCtrl'
                }
            }
        };

        var draftCatalog_print = {
            name   : 'draftCatalog.print',
            url    : '/print/{gpCateId}',
            views  : {
                'main@'        : {
                    templateUrl: '/ng-app/admin-app/catalog-admin/draft-catalog/draft-catalog-print.partial.html',
                    controller  : 'DraftCatalogController',
                    controllerAs: 'draftCatCtrl'
                }
            }
        };

        var draftCatalog_details = {
            name   : 'draftCatalog.details',
            url    : '/{gpCateId}',
            views  : {
                'main@'        : {
                    templateUrl: '/ng-app/admin-app/catalog-admin/draft-catalog/draft-catalog.partial.html',
                    controller  : 'DraftCatalogController',
                    controllerAs: 'draftCatCtrl'
                }
            }
        };

        $stateProvider.state(draftCatalog);

        $stateProvider.state(draftCatalog_print);

        $stateProvider.state(draftCatalog_details);
    }

})();
