(function () {
    'use strict';

    angular
        .module('pdgWp')
        .component('catalogImg', {
            templateUrl : '/ng-app/admin-app/catalog-admin/catalog-templates/catalog-img.component.html',
            bindings    : {
                photoList    : '<',
                onItemChanged: '&'
            },
            controller  : CatalogImgController,
            controllerAs: 'ctrl'

        });

    /** @ngInject */
    function CatalogImgController($scope, $log, $element) {

        var ctrl = this;

        var targetElement, photoIndex, startMouseX, startMouseY, startItemX, startItemY;


        ctrl.currentPhotos = {};
        ctrl.$onInit = function () {

            var children = $element.children();
            ctrl.currentPhotos = angular.copy(ctrl.photoList);

            children.on('mousewheel', ctrl.zoomImg);

            // children.on('mousedown', ctrl.setMouseMove);
            $element.on('mousedown', ctrl.setMouseMove);


        };

        ctrl.zoomImg = function (event) {

            if(! event.altKey) return;

            event.preventDefault();

            targetElement = angular.element(event.target);

            photoIndex = targetElement.attr('index');

            if (angular.isUndefined(photoIndex)) return;

            var delta = Math.max(-1, Math.min(1, (event.wheelDelta || -event.detail)));

            var factor = (event.ctrlKey) ? 10 : 1;

            if (angular.isUndefined(ctrl.currentPhotos[photoIndex].zoom)) ctrl.currentPhotos[photoIndex].zoom = 100;

            ctrl.currentPhotos[photoIndex].zoom = ctrl.currentPhotos[photoIndex].zoom + (delta * factor);

            $scope.$apply();
        };

        ctrl.setMouseMove = function (event) {

            event.preventDefault();

            photoIndex = undefined;

            targetElement = angular.element(event.target);

            photoIndex = targetElement.attr('index');

            if (angular.isUndefined(photoIndex)) return;

            startMouseX = event.clientX;
            startMouseY = event.clientY;
            startItemX = ctrl.currentPhotos[photoIndex].left;
            startItemY = ctrl.currentPhotos[photoIndex].top;

            var bodyElement = angular.element(document).find('body');

            var children = $element.children();
            children.one('mouseup', ctrl.cxlMouseMove);

            bodyElement.one('mouseup', ctrl.cxlMouseMove);

            bodyElement.on('mousemove', ctrl.moveImg);

        };

        ctrl.cxlMouseMove = function (event) {

            event.preventDefault();

            photoIndex = undefined;

            var bodyElement = angular.element(document).find('body');

            var children = $element.children();

            bodyElement.off('mousemove');
            children.off('mouseup');
            bodyElement.off('mouseup');


            startMouseX = null;
            startMouseY = null;
            startItemX = null;
            startItemY = null;

        };

        ctrl.moveImg = function (event) {

            if(! event.altKey) return;


            event.preventDefault();

            var ev = event;
            // var el = angular.element(event.currentTarget);

            ctrl.currentPhotos[photoIndex].left = startItemX + (event.clientX - startMouseX);

            ctrl.currentPhotos[photoIndex].top = startItemY + (event.clientY - startMouseY);

            $scope.$apply();

        };

        ctrl.$destroy = function () {
            // clearBindings();
        };

        ctrl.itemChanged = function () {

            ctrl.onItemChanged({itemObj: ctrl.currentPhotos});
        };

        ctrl.$onChanges = function (changesObj) {

            // ctrl.currentPhotos = angular.copy(ctrl.photoList)

        }

    }
})();
