(function () {
    'use strict';

    angular
        .module('pdgWp')
        .controller('ItemsSearchDlgController', ItemsSearchDlgController);

    /** @ngInject */
    function ItemsSearchDlgController($mdDialog, structureSrv) {

        var itemSearchDlgCtrl = this;

        itemSearchDlgCtrl.sortOptions = [
            {itItemIdOdr: "ASC"},
            {gpCatPageOdr: "ASC"},
            {gpDraftOrderOdr: "ASC"},
            {gpCpIdOdr: "ASC"},
            {gpModOdr: "DESC"},
            {gpCreatedOdr: "DESC"}
        ];

        itemSearchDlgCtrl.templatesArray = [
            {
                "templateName": "Default",
                "searchObj"   : {
                    "sort"             : {
                        "gpModOdr": "DESC"
                    },
                    "filter"           : {
                        "gpStatusFtr"       : "1",
                        "itStatusFtr"       : "all",
                        "gpCatalogStatusFtr": "all",
                        "gpDraftStatusFtr"  : "all",
                        "itDraftStatusFtr"  : "all",
                        "itBreStatusFtr"  : "all"
                    },
                    "filteredStructure": {},
                    "structure": {
                        "gpChapId": "",
                        "gpSectId": "",
                        "gpCateId": ""
                    }
                }
            },
            {
                "templateName": "New Items",
                "searchObj"   : {
                    "sort"             : {
                        "gpModOdr": "DESC"
                    },
                    "filter"           : {
                        "gpStatusFtr"       : "1",
                        "itStatusFtr"       : "0",
                        "gpCatalogStatusFtr": "all",
                        "gpDraftStatusFtr"  : "all",
                        "itDraftStatusFtr"  : "all",
                        "itBreStatusFtr"  : "all"
                    },
                    "filteredStructure": {}
                }
            }
        ]

        itemSearchDlgCtrl.templateList = lodash.flatMap(itemSearchDlgCtrl.templatesArray, function (obj) {
            return obj.templateName
        });

        itemSearchDlgCtrl.setCustom = function(){
            lodash.merge(itemSearchDlgCtrl.searchObj, {selectedTemplate: 'Custom'});
        }

        itemSearchDlgCtrl.$onInit = function () {

            var a = itemSearchDlgCtrl;

            if (lodash.isEmpty(itemSearchDlgCtrl.structureArray)) {
                structureSrv.loadFullArray()
                            .then(function () {

                                itemSearchDlgCtrl.structureArray = structureSrv.fullArray;
                            });
            }
            else {
                // itemSearchDlgCtrl.structureVisible = !itemSearchDlgCtrl.structureVisible;
            }

        };

        itemSearchDlgCtrl.changeTemplate = function (templateName) {

            lodash.forEach(itemSearchDlgCtrl.searchObj, function (value, key) {

                if(key !== 'selectedTemplate') delete itemSearchDlgCtrl.searchObj[key];

            });

            var tplObj = lodash.find(itemSearchDlgCtrl.templatesArray, function (o) {
                return o.templateName === templateName;
            })

            itemSearchDlgCtrl.searchObj = lodash.merge(itemSearchDlgCtrl.searchObj, tplObj.searchObj);

        }

        itemSearchDlgCtrl.sortGetterSetterFunc = function (value) {
            if (arguments.length) {

                lodash.forEach(itemSearchDlgCtrl.searchObj.sort, function (value, key) {

                    delete itemSearchDlgCtrl.searchObj.sort[key];
                });

                itemSearchDlgCtrl.searchObj.sort = lodash.merge(itemSearchDlgCtrl.searchObj.sort, value);

            }
            else {
                return lodash.find(itemSearchDlgCtrl.sortOptions, angular.copy(itemSearchDlgCtrl.searchObj.sort))
            }
        }

        itemSearchDlgCtrl.filterStructure = function () {

            lodash.forEach(itemSearchDlgCtrl.searchObj.filteredStructure, function (value, key) {

                delete itemSearchDlgCtrl.searchObj.filteredStructure[key];
            });

            if (itemSearchDlgCtrl.searchObj.structure.gpCateId !== '') {

                itemSearchDlgCtrl.searchObj.filteredStructure = lodash.merge(itemSearchDlgCtrl.searchObj.filteredStructure, {gpCateIdFtr: itemSearchDlgCtrl.searchObj.structure.gpCateId})

            }
            else if (itemSearchDlgCtrl.searchObj.structure.gpSectId !== '') {

                itemSearchDlgCtrl.searchObj.filteredStructure = lodash.merge(itemSearchDlgCtrl.searchObj.filteredStructure, {gpSectIdFtr: itemSearchDlgCtrl.searchObj.structure.gpSectId})

            }
            else if (itemSearchDlgCtrl.searchObj.structure.gpChapId !== '') {

                itemSearchDlgCtrl.searchObj.filteredStructure = lodash.merge(itemSearchDlgCtrl.searchObj.filteredStructure, {gpChapIdFtr: itemSearchDlgCtrl.searchObj.structure.gpChapId})
            }

            itemSearchDlgCtrl.setCustom();
        };


        itemSearchDlgCtrl.save = function () {

            if (angular.isDefined(itemSearchDlgCtrl.searchObj.other) &&  itemSearchDlgCtrl.searchObj.other.cpStarFtr === '') delete itemSearchDlgCtrl.searchObj.other.cpStarFtr;
            if (angular.isDefined(itemSearchDlgCtrl.searchObj.gpInternalNotesFtr) &&  itemSearchDlgCtrl.searchObj.other.gpInternalNotesFtr === '') delete itemSearchDlgCtrl.searchObj.other.gpInternalNotesFtr;
            if (angular.isDefined(itemSearchDlgCtrl.searchObj.itemStarFtr) &&  itemSearchDlgCtrl.searchObj.other.itemStarFtr === '') delete itemSearchDlgCtrl.searchObj.other.itemStarFtr;

            $mdDialog.hide(itemSearchDlgCtrl.searchObj);
        };

        itemSearchDlgCtrl.cancel = function () {
            $mdDialog.cancel();
        }


    }
})();
