(function () {
    'use strict';

    angular
        .module('pdgWp')
        .controller('CpOrphansController', CpOrphansController);

    /** @ngInject */
    function CpOrphansController() {

        var cpOrphansCtrl = this;

    }

})();
