(function () {
    'use strict';

    angular
        .module('pdgWp')
        .factory('cartSrv', cartSrv);

    /** @ngInject */
    function cartSrv($window, $rootScope, $http, $log, appConfig, localStorageSvr, itemsSrv) {

        var cartSrv = this;

        cartSrv.__env = $window.__env;

        cartSrv.incPrc = true;

        cartSrv.cart = {};

        localStorageSvr.register(cartSrv, 'cart', {
            orCartJson: [],
            panelOpen : false
        });
// ===================================================================================================

        cartSrv.clearCart = function () {

            lodash.forIn(cartSrv.cart, function (value, key) {
                delete cartSrv.cart[key];
            });

            cartSrv.cart = lodash.merge(cartSrv.cart, {
                orCartJson: [],
                panelOpen : false
            });

            localStorageSvr.set('cart', cartSrv.cart);
        };

// ===================================================================================================

        cartSrv.sumItems = function (itemsArray) {
            return lodash.reduce(itemsArray, function (sum, item) {
                return sum + item.cartQty;
            }, 0);
        };

// ===================================================================================================

        cartSrv.saveCart = function () {

            cartSrv.calculateCart();

            localStorageSvr.set('cart', cartSrv.cart);
        };

// ===================================================================================================

        cartSrv.calculateCart = function () {

            cartSrv.cart.orItemsCount = lodash.reduce(cartSrv.cart.orCartJson, function (sum, item) {
                return sum + item.cartQty;
            }, 0);

            cartSrv.cart.orCartTotal = lodash.reduce(cartSrv.cart.orCartJson, function (sum, item) {

                if (!lodash.has(item, 'gpPrice') || !lodash.isNumber(1 * item.gpPrice)) return sum;

                if (!lodash.isNumber(1 * item.cartQty)) return sum;

                return sum + (1 * item.cartQty * item.gpPrice);
            }, 0);


            localStorageSvr.set('cart', cartSrv.cart);
        };


// ===================================================================================================

        cartSrv.changeQty = function (inputItem, offset, newQty) {

            var item = lodash.find(cartSrv.cart.orCartJson, {'itId': inputItem.itId});

            if (angular.isUndefined(item)) {

                cartSrv.cart.orCartJson.push(inputItem);

                item = lodash.find(cartSrv.cart.orCartJson, {'itId': inputItem.itId});
            }

            if (angular.isUndefined(item.cartQty)) item.cartQty = 0;

            if (angular.isDefined(newQty)) {

                item.cartQty = 1 * newQty

            } else if (angular.isDefined(offset)) {

                item.cartQty = 1 * item.cartQty + offset;

            } else {

                item.cartQty = 1 * item.cartQty + 1;

            }

            if (item.cartQty < 0) item.cartQty = 0;


            cartSrv.saveCart();
        };

        cartSrv.refreshCart = function () {


            var allCartItId = lodash.flatMap(cartSrv.cart.orCartJson, function (item) {
                return item.itId;
            });

            var allCartGpId = lodash.flatMap(cartSrv.cart.orCartJson, function (item) {
                return item.gpId;
            });

            var configObj = {
                Orders      : true,
                itBatchIdFtr: allCartItId.join(','),
                gpBatchIdFtr: allCartGpId.join(',')
            };

            if (cartSrv.incPrc) configObj.prices = true;

            itemsSrv.getItems(configObj)
                    .then(function (itemsObj) {

                        lodash.forEach(cartSrv.cart.orCartJson, function (value, index, collection) {
                            delete cartSrv.cart.orCartJson[index]['gpPrice'];
                        });

                        lodash.merge(cartSrv.cart.orCartJson, itemsObj);

                        cartSrv.calculateCart();

                        $log.debug('The cart has been refreshed.')
                    });

        };

// ===================================================================================================

        cartSrv.removeItem = function (inputItem) {
            var index = lodash.findIndex(cartSrv.cart.orCartJson, {'itId': inputItem.itId});
            cartSrv.cart.orCartJson.splice(index, 1);
            cartSrv.saveCart();
        };

        cartSrv.removeAllItems = function () {
            cartSrv.clearCart();
            // cartSrv.cart.orCartJson.splice(0);
            // cartSrv.saveCart();
        };

        // cartSrv.placeOrder = function () {
        //
        //     var httpConfig = angular.copy(cartSrv.cart);
        //
        //     httpConfig.orSendEmail = true;
        //
        //     cartSrv.updateOrders(httpConfig);
        // };

        cartSrv.updateOrders = function (configObj) {

            var httpConfig = {
                method: 'POST',
                url   : appConfig.apiUrl + 'cart',
                data  : configObj
            };

            return $http(httpConfig)
                .then(function (response) {

                    return cartSrv.returnCartObj(response)
                }, function (response) {
                    return cartSrv.errorCallback(Promise, response);
                });

        };

        cartSrv.processOrder = function (orId) {

            var httpConfig = {
                method: 'POST',
                url   : cartSrv.__env.siteUrl + 'update_cp/process/',
                data  : {orId: orId}
            };

            return $http(httpConfig)
                .then(function (response) {

                    return cartSrv.returnCartObj(response)

                }, function (response) {
                    return cartSrv.errorCallback(Promise, response);
                });

        };


        cartSrv.getOrders = function (configObj) {

            var httpConfig = {
                method: 'GET',
                url   : appConfig.apiUrl + 'cart',
                params: configObj
            };

            return $http(httpConfig)
                .then(function (response) {

                    if (angular.isDefined(httpConfig.params.getRow) && httpConfig.params.getRow) {

                        return cartSrv.returnCartObj(response)

                    } else {

                        if (angular.isDefined(httpConfig.params.getPaging)) {

                            return cartSrv.returnOrdersPagingList(response)

                        } else {
                            return cartSrv.returnOrdersList(response)
                        }

                    }
                }, function (response) {
                    return cartSrv.errorCallback(Promise, response);
                });
        };


// ===================================================================================================

        cartSrv.returnCartObj = function (response) {


            return (angular.isDefined(response.data.data[0])) ? response.data.data[0] : response.data.data;
        };


// ===================================================================================================

        cartSrv.returnOrdersList = function (response) {
            return response.data.data;
        };

// ===================================================================================================

        cartSrv.returnOrdersPagingList = function (response) {
            return response.data;
        };

// ===================================================================================================

        cartSrv.errorCallback = function (promise, response) {

            return promise.reject(response);
        };

// ===================================================================================================


        return cartSrv;
    }
})();
