(function () {
    'use strict';

    angular
        .module('pdgWp')
        .config(structureConfig);

    /** @ngInject */
    function structureConfig($stateProvider) {

        var structure = {
            name   : 'structure',
            url    : '/structure',
            resolve: {
                structureObj: function (structureSrv) {

                    var configObj = {
                        editLang   : true,
                        stGetBranch: true,
                        forceArray : true,
                        stStatusFtr: 'all'
                    };

                    return structureSrv.getStructure(configObj)
                                       .then(function (structureObj) {
                                           return structureObj;
                                       });
                },
                strData     : function (structureSrv) {

                    var configObj = {
                        stId       : 1,
                        stGetBranch: true
                    };

                    configObj.progressBar = 'main@';

                    return structureSrv.getStructure(configObj)
                                       .then(function (structureObj) {
                                           return structureObj;
                                       });
                }
            },
            views  : {
                'main@': {
                    templateUrl : '/ng-app/admin-app/website-admin/structure/structure.partial.html',
                    controller  : 'StructureController',
                    controllerAs: 'structureCtrl'
                }
            }
        };

        $stateProvider.state(structure);

    }

})();
