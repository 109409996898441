(function () {
    'use strict';

    angular
        .module('pdgWp')
        .controller('DraftItemEditController', DraftItemEditController);

    /** @ngInject */
    function DraftItemEditController($mdDialog, $rootScope, item, itemsSrv) {

        var draftItemEditCtrl = this;

        draftItemEditCtrl.catalogPartial = '/ng-app/admin-app/catalogs-templates/pdg-catalog-2017-2018/single-template.partial.html';
        draftItemEditCtrl.catalogStyle = 'pdg-catalog-2017-2018';



        draftItemEditCtrl.done = function () {


            $mdDialog.hide(false);
        };

        draftItemEditCtrl.cancel = function () {

            $mdDialog.cancel();
        };


    }
})();
