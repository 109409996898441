(function () {
    'use strict';

    angular
        .module('pdgWp')
        .controller('CatalogPageController', CatalogPageController);

    /** @ngInject */
    function CatalogPageController($log, $rootScope, $state, catalogSrv, itemsSrv, catalogPage) {

        var catPageCtrl = this;
        
        catPageCtrl.pageObj = catalogPage;

        catPageCtrl.layout = catPageCtrl.pageObj.catDataJson.layout;

        catPageCtrl.loadedItems = [];

        catPageCtrl.getPageItems = function(){

            var flatLayout = lodash.flatMapDeep(catPageCtrl.layout, function(n){
                return [[[n]]]
            });

            var items = lodash
                .chain(flatLayout)
                .filter('gpId')
                .map('gpId')
                .join(',')
                .value();

            var configObj = {
                expandGid   : true,
                gpStatusFtr : 'all',
                itStatusFtr : 'all',
                detailed    : true,
                prices      : true,

                media       : true,
                gpTitle_en  : true,
                gpTitle_fr  : true,
                gpDetails_en: true,
                gpDetails_fr: true,
                gpBatchIdFtr: items
            };

            configObj.progressBar = 'main@';

            return itemsSrv.getItems(configObj)
                           .then(function (itemObj) {

                               catPageCtrl.loadedItems = itemObj;

                               return itemObj;
                           });
        };

        catPageCtrl.getPageItems();

        catPageCtrl.orderLayout = function (index, layoutObj) {
            layoutObj.splice(index, 1);

            catPageCtrl.saveCatalogObj({catId:catPageCtrl.pageObj.catId, catDataJson: catPageCtrl.pageObj.catDataJson});
        };

        catPageCtrl.saveCatalogObj = function (catalogObj) {

            catalogObj.progressBar = 'main@';

            return catalogSrv.saveCatalogObj(catalogObj)
                    .then(function successCallback(catalogObj) {

                        $rootScope.$broadcast('event:queue-message', 'Modifications has been saved');

                        return catalogObj;
                    }, function () {

                        $state.reload();

                    })
        };

        catPageCtrl.itemChanged = function (item, itemObj) {

            lodash.remove(item, function(n) {
                return true;
            });

            lodash.merge(item, itemObj);
            catPageCtrl.saveCatalogObj({catId:catPageCtrl.pageObj.catId, catDataJson: catPageCtrl.pageObj.catDataJson})
                       .then(function(){
                               $state.reload();
                       },
                           function(){

                           });
            // $log.info(item);
        }
    }
})();
