/**
 * Created by Jonathan on 17/07/01.
 */
(function () {
    'use strict';

    angular
        .module('pdgWp')
        .factory('localStorageSvr', localStorageSvr);

    /** @ngInject */
    function localStorageSvr($rootScope, $window, $timeout) {

        var lclStr = this;

        lclStr.prefix = 'lclStr-';
        lclStr.prefixTs = 'lclStrTs-';
        lclStr.registeredKeys = [];

        $window.addEventListener('storage', $timeout);

        lclStr.register = function (keyParent, key, defaultData) {

            var keyTs = lclStr.prefixTs + key;

            if ($window.localStorage.getItem(keyTs) === null) {

                defaultData = angular.isDefined(defaultData) ? defaultData : {};

                $window.localStorage.setItem(lclStr.prefix + key, angular.toJson(defaultData));

                $window.localStorage.setItem(keyTs, Date.now());

            }

            lclStr.registeredKeys[keyTs] = $rootScope.$watch(function () {

                return $window.localStorage.getItem(keyTs);

            }, function () {
                var output = parseJSON($window.localStorage.getItem(lclStr.prefix + key));
                $rootScope.$broadcast(lclStr.prefix + key + '-updated', angular.copy(output));
                lodash.merge(keyParent[key], angular.copy(output));
            });

            if(angular.isUndefined(keyParent[key])) keyParent[key] = {};

            return lodash.merge(keyParent[key],parseJSON($window.localStorage.getItem(lclStr.prefix + key)));

        };

        lclStr.set = function (key, data) {

            var keyTs = lclStr.prefixTs + key;

            if ($window.localStorage.getItem(keyTs) === null) {

                return false;
            }

            $window.localStorage.setItem(lclStr.prefix + key, angular.toJson(data));
            $window.localStorage.setItem(keyTs, Date.now());

            return true;
        };

        function parseJSON(input) {

            if (input !== 'undefined' && input !== 'null') {

                return angular.fromJson(input);
            }

            return null;

        }

        lclStr.remove = function (keyParent, key) {

            var keyTs = lclStr.prefixTs + key;

            if (angular.isDefined(lclStr.registeredKeys[keyTs])) {

                lclStr.registeredKeys[keyTs]();
                $window.localStorage.removeItem(lclStr.prefix + key);
                $window.localStorage.removeItem(keyTs);
                delete lclStr.registeredKeys[keyTs];
                keyParent[key] = null;
            }
        };

        return lclStr;

    }
})();