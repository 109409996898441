(function () {
    'use strict';

    angular
        .module('pdgWp')
        .directive('rdsAutofocus', rdsAutofocus);

    /** @ngInject */
    function rdsAutofocus($timeout) {
        return {
            restrict: 'A',
            link : function($scope, $element) {
                $timeout(function() {
                    $element[0].focus();
                },2500);
            }
        }
    }
})();
