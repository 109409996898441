(function () {
    'use strict';

    angular
        .module('pdgWp')
        .component('noCp', {
            templateUrl: '/ng-app/admin-app/website-admin/reports/no-cp/no-cp.partial.html',
            controller  : noCp,
            controllerAs: 'ctrl'
        });

    /** @ngInject */
    function noCp(appConfig, itemsSrv,$stateParams, $http) {

        var ctrl = this;
        
        ctrl.__env = __env;

        ctrl.itemUrl = ctrl.__env.baseUrl + 'articles/view/';

        ctrl.results = [];

        ctrl.itemsFilter = '';

        ctrl.includeDisabled = '0';

        ctrl.orderBy = 'itItemId';

        ctrl.$onInit = function(){
            ctrl.getReport();
        };

        ctrl.getReport = function(switchStatus){

            var itemsStatus = (angular.isDefined(switchStatus) && switchStatus === '1') ? '1':'' ;

            return $http.get(ctrl.__env.siteUrl + 'reports/no_cp/' + itemsStatus)
                        .then(
                            function(response){
                                ctrl.items = response.data.data;

                                return response.data;
                            },
                            function(response){
                                alert('Report Error!');
                            }
                        );
        };


        ctrl.updateItems = function (itemsObj) {

            itemsSrv.updateArticles(itemsObj)
                    .then(function successCallback(itemsObj) {
                        return itemsObj;
                    });
        };

        ctrl.deleteItems = function (toolboxObj) {

            if (! lodash.isEmpty(toolboxObj.gid) && ! /^(\d{1,4})$/.test(toolboxObj.gid)) {
                alert("Invalid Group Id: Make sure to use a destination Group Id, NOT AN ARTICLE'S CATALOG NUMBER!!!");

                return;
            }

            itemsSrv.useToolbox(toolboxObj)
                    .then(function successCallback(itemsObj) {
                        lodash.pullAllBy(ctrl.items, [toolboxObj], 'idxItId');

                    }, function (response) {

                    });
        };


        ctrl.sorterFunc = function(row){
            var orderBy = parseInt(row[ctrl.orderBy]);
            return (Number.isInteger(orderBy)) ? orderBy:0 ;
        };

    }
})();
