(function () {
    'use strict';

    angular
        .module('pdgWp')
        .controller('WebAuthModalController', WebAuthModalController);

    /** @ngInject */
    function WebAuthModalController($rootScope, $mdDialog, messageInput) {

        var authCtrl = this;

        authCtrl.message = messageInput;

        authCtrl.doLogin = function (username, password){

            $rootScope.$emit('event:webAuthSrv-doLogin', {username:username, password:password});

            $mdDialog.hide();
        };

        authCtrl.cancel = function () {

            $rootScope.$emit('event:webAuthSrv-cancelLogin');

            $mdDialog.hide();
        };

    }
})();
