(function() {
  'use strict';

  angular
    .module('pdgWp')
    .controller('ClientsListController', ClientsListController);

  /** @ngInject */
  function ClientsListController($log, $timeout, appConfig, usersSrv, $mdSidenav, $state) {

    var cltListCtrl = this;

      cltListCtrl.clientsList = [];
      cltListCtrl.limit = 25;
      cltListCtrl.page = 1;
      cltListCtrl.sort = {uscOrganisationOdr:'ASC'};
      cltListCtrl.filterBy = {};

      cltListCtrl.$onInit = function(){

          cltListCtrl.searchUsers();
      };

      cltListCtrl.loadUser = function(usId){

          $state.go('clients.clientProfile', {usId:usId});

          if(! $mdSidenav('sideNavRight').isLockedOpen()){
              $mdSidenav('sideNavRight', true).close();
          }
      };

      cltListCtrl.filterUsers = function(filterObj){

          cltListCtrl.filterBy = filterObj;

          cltListCtrl.updateSearch();
      };

      cltListCtrl.sortBy = function(sortObj){

          cltListCtrl.sort = lodash.forIn(cltListCtrl.sort, function(value, key) {
              if(lodash.has(sortObj, key)){

                  cltListCtrl.sort[key] = (value == 'ASC') ? 'DESC' : 'ASC';

              } else {
                  cltListCtrl.sort = lodash.merge(cltListCtrl.sort, sortObj);

                  delete cltListCtrl.sort[key];
              }
          });

          if (lodash.isEmpty(cltListCtrl.sort)) cltListCtrl.sort = sortObj;

          cltListCtrl.updateSearch();
      };

      cltListCtrl.setPage = function (page){

          var page = 1 * page;

          if(-1 <= page <= 1){
              cltListCtrl.page = (cltListCtrl.page + page >= 1) ?  cltListCtrl.page + page : 1;
          } else {
              cltListCtrl.page = (page >= 1) ?  page : 1;
          }

          cltListCtrl.searchUsers();
      };

      cltListCtrl.updateSearch = function (searchText) {

          cltListCtrl.searchText = (angular.isDefined(searchText)) ? searchText : cltListCtrl.searchText;
          cltListCtrl.page = 1;
          cltListCtrl.searchUsers();
      };

      cltListCtrl.searchUsers = function(page, limit){

          cltListCtrl.limit = (angular.isDefined(limit)) ? limit : cltListCtrl.limit;

          cltListCtrl.page = (angular.isDefined(page)) ? page : cltListCtrl.page;

          var configObj = {
              clients: true,
              usSearchFtr:cltListCtrl.searchText,
              getPaging: true,
              limit: cltListCtrl.limit,
              page: cltListCtrl.page

          };

          configObj = lodash.merge(configObj, cltListCtrl.sort, cltListCtrl.filterBy);

          configObj.progressBar = 'sideNavRight@';

          return usersSrv.getUsers(configObj)
                         .then(function (clientsList) {
                             cltListCtrl.page = 1 * clientsList.config.page;
                             cltListCtrl.limit = 1 * clientsList.config.limit;
                             cltListCtrl.count = 1 * clientsList.count;

                             cltListCtrl.clientsList = clientsList.data ;

                             return cltListCtrl.clientsList.data;
                         });
      }



  }
})();
