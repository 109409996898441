(function () {
    'use strict';

    angular
        .module('pdgWp')
        .component('itemsCreator', {
            templateUrl: '/ng-app/admin-app/components/items-creator/items-creator.partial.html',
            transclude : true,

            bindings    : {
                item          : '<',
                onItemCreated : '&',
                onItemInserted: '&'
            },
            controller  : itemsCreatorController,
            controllerAs: 'ctrl'

        });

    /** @ngInject */
    function itemsCreatorController($timeout, $rootScope, $mdDialog, $state, appConfig, itemsSrv, structureSrv, deepL) {

        var ctrl = this;

        ctrl.structure = {};
        ctrl.selectedChap = {};
        ctrl.selectedSect = {};
        ctrl.selectedCat = {};
        // ctrl.itemsList = [];
        ctrl.action = 'create';
        ctrl.sort = {};
        ctrl.filter = {};
        ctrl.searchText = '';
        ctrl.isItemIdValid = false;
        ctrl.newItemId = null;
        ctrl.gpGidProvided = false;
        ctrl.itItemIdProvided = false;
        ctrl.openInManager = false;

        // ctrl.selectedGroup = {};

        ctrl.$onInit = function () {

            ctrl.localItem = angular.copy(ctrl.item);

            if (angular.isDefined(ctrl.localItem.gpGid)) {

                ctrl.gpGidProvided = true;

                ctrl.action = 'insert';

                ctrl.localGpGid = ctrl.localItem.gpGid;

            } else {
                ctrl.localGpGid = null;
            }

            if (angular.isDefined(ctrl.localItem.itItemId)) {

                ctrl.itItemIdProvided = true;

                ctrl.action = 'create';

                ctrl.newItemId = ctrl.localItem.itItemId;

            } else {
                ctrl.newItemId = null;
            }

            if (angular.isDefined(ctrl.localItem.gpCpDesc)) {

                ctrl.localItem.gpTitle_en = angular.copy(ctrl.localItem.gpCpDesc);
            }


            ctrl.getStructure();
        };

        ctrl.cancel = function () {
            $mdDialog.cancel();
        };

        ctrl.getStructure = function () {

            var configObj = {
                stId       : 1,
                stGetBranch: true
            };

            structureSrv.getStructure(configObj)
                        .then(function (stBranchObj) {
                            ctrl.structure = stBranchObj;
                        }, function () {

                        })
        };

        ctrl.getCpOrphans = function (text) {

            var configObj = {cpOrphansFlxFtr: text};

            return itemsSrv.getCpOrphans(configObj)
                           .then(function (itemsList) {
                               return itemsList;
                           });
        };

        ctrl.searchItems = function (searchText) {

            ctrl.searchText = searchText || '';

            var configObj = {
                search     : true,
                itemsReq   : true,
                itStatusFtr: 'all',
                gpCpId  : true,
                expandGid  : true
            };

            configObj = lodash.merge(configObj, ctrl.sort, ctrl.filter);

            if (ctrl.searchText === undefined || ctrl.searchText === '') {

            } else if (ctrl.searchText.substring(0, 1) === '=') {

                if (ctrl.searchText.length >= 4) {

                    configObj.gpCpIdFlxFtr = ctrl.searchText.substring(1);

                } else {

                    return [];
                }

            } else if (isFinite(ctrl.searchText) && ctrl.searchText.length <= 3) {
                configObj.gpCatPageFtr = ctrl.searchText;
            } else if (isFinite(ctrl.searchText) && ctrl.searchText.length >= 4 && ctrl.searchText.length <= 5) {
                configObj.itItemIdFlxFtr = ctrl.searchText;

            } else if (isFinite(ctrl.searchText) && ctrl.searchText.length === 6) {
                configObj.gpFullItemId = ctrl.searchText;

            } else {
                configObj.gpTitleFtr = ctrl.searchText;
            }

            return ctrl.search(configObj)
                       .then(function (itemsList) {
                           // ctrl.itemsList = itemsList;
                           // return ctrl.itemsList;
                           return itemsList;
                       });
        };

        ctrl.search = function (configObj) {
            return itemsSrv.getItems(configObj)
                           .then(function (itemsList) {
                               return itemsList;
                           });
        };

        ctrl.submit = function (openInManager) {

            ctrl.openInManager = openInManager;

            if (ctrl.action === 'insert') {
                ctrl.updateArticles();
            } else {
                ctrl.updateItems();
            }
        };

        ctrl.getGroupTitle = function (itemObj) {

            if (angular.isUndefined(itemObj.gpItems)) return '';

            var itemsIdStr = itemObj.gpItems.map(function (item) {
                return item.itItemId;
            }).join(', ');

            return itemObj.gpTitle + ' (' + itemsIdStr + ' - page: ' + itemObj.gpCatPage + ')';
        };

        ctrl.updateItems = function () {

            var config = angular.copy(ctrl.localItem);

            config.itItemId = ctrl.newItemId;

            itemsSrv.updateItems(config)
                    .then(function successCallback(itemsObj) {
                        $mdDialog.hide({
                            itemsObj     : itemsObj,
                            openInManager: ctrl.openInManager
                        });

                        $rootScope.$broadcast('event:queue-message', {
                            type   : 'info',
                            delay  : 2000,
                            message: 'The item has been created.'
                        });

                        return itemsObj;
                    }, function (response) {
                        $rootScope.$broadcast('event:queue-message', {
                            type   : 'error',
                            message: 'No items have been created.'
                        });
                    });
        };

        ctrl.updateArticles = function () {

            itemsSrv.updateArticles({
                idxGidMod: ctrl.localGpGid,
                itItemId : ctrl.newItemId
            })
                    .then(function successCallback(itemsObj) {
                        $mdDialog.hide({
                            itemsObj     : itemsObj,
                            openInManager: ctrl.openInManager
                        });

                        $rootScope.$broadcast('event:queue-message', {
                            type   : 'info',
                            delay  : 2000,
                            message: 'The item has been inserted.'
                        });


                        return itemsObj;
                    }, function (response) {
                        $rootScope.$broadcast('event:queue-message', {
                            type   : 'error',
                            message: 'No items have been inserted.'
                        });
                    });
        };

        ctrl.titleCase = function (str) {
            return itemsSrv.titleCase(str);
        };

        ctrl.translate = function (input, output, focusId) {

            focusId = focusId || false;

            deepL.translate(input)
                 // .then(function (response) {
                 //
                 //     output = response;
                 //
                 // }, function () {
                 //
                 //     if(focusId) itemDetailsCtrl.setFocus(focusId);
                 // });
        };
    }
})();
