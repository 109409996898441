(function () {
    'use strict';

    angular
        .module('pdgWp')
        .controller('RootController', RootController);

    /** @ngInject */
    function RootController($rootScope, $log, appConfig, webAuthSrv, $mdSidenav, $mdToast, $mdDialog, $location, msgSrv) {

        var rootCtrl = this,deReg1,deReg10;

        var eventAuthLoginRequired, eventAuthLevelsLoaded, eventAuthLogout, eventWebAuthSrvDoLogin;

        rootCtrl.msgSrv = msgSrv;

        rootCtrl.sideNavLeft = appConfig.sideNavLeft;

        // rootCtrl.sideNavRightUrl = "/ng-app/admin-app/root/side-nav-blank.partial.html";
        rootCtrl.sideNavRightUrl = "/ng-app/admin-app/website-admin/items/items-list.partial.html";

        // ==============================================
        // Site Wide Login/Logout - START
        // ==============================================

        rootCtrl.auth = webAuthSrv.auth;

        rootCtrl.levels = webAuthSrv.levels;

        rootCtrl.isAuth = webAuthSrv.isAuth;

        rootCtrl.login = function () {

            rootCtrl.getLoginDialog();
        };

        rootCtrl.getLoginDialog = function (message) {

            var templateUrl = "/ng-app/admin-app/web-auth/rds-auth-login.partial.html";

            $mdDialog.show({
                controller  : "WebAuthModalController",
                controllerAs: 'authCtrl',
                templateUrl : templateUrl,
                locals      : {
                    messageInput: message || ''
                }
            })
        };

        eventAuthLoginRequired = $rootScope.$on('event:auth-loginRequired', function (event, message) {

            rootCtrl.getLoginDialog(message);
        });

        eventWebAuthSrvDoLogin = $rootScope.$on('event:webAuthSrv-doLogin', function (event, loginObj) {

            rootCtrl.doLogin(loginObj);

        });


        rootCtrl.doLogin = function (loginObj) {

            webAuthSrv.doLogin(event, loginObj)
                      .then(function (loginObj) {


                      }, function () {
                          $log.debug('REJECTED: rootCtrl.doLogin')
                      })

        };


        rootCtrl.logout = function () {

            webAuthSrv.logout()
                      .then(function (response) {

                      });
        };

        rootCtrl.accessFilter = function(value, index, array){

            var a = rootCtrl.isAuth(value.access);

            return a;

        };

        // eventAuthLoginRequired = $rootScope.$on('event:auth-loginRequired', function (event, data) {
        //
        //     if (angular.isObject(data)) {
        //         rootCtrl.onLoginRequired(data.data.messages[0].message);
        //     } else {
        //         rootCtrl.onLoginRequired(data);
        //     }
        // });

        eventAuthLogout = $rootScope.$on('event:webAuthSrv-logout', function () {

            rootCtrl.changeState('logout');

            rootCtrl.login();

        });
//===========================================
// Common Functions
//===========================================
        rootCtrl.mailTo = function(emailAddress){

            location.href = "mailto:" + emailAddress;
        };

//===========================================
// Navigation
//===========================================

        rootCtrl.sideNavRightLocked = true;
        rootCtrl.sideNavLeftLocked = true;

        rootCtrl.toggleSidenav = function (sideNavId) {

            if ($mdSidenav(sideNavId).isLockedOpen()) {

                rootCtrl.toggleSidenavLock(sideNavId);

            } else {
                $mdSidenav(sideNavId).toggle();
            }
        };

        rootCtrl.toggleSidenavLock = function (sideNavId) {

            if ($mdSidenav(sideNavId).isLockedOpen()) {
                rootCtrl[sideNavId + 'Locked'] = false;

                $mdSidenav(sideNavId).close();

            } else {
                rootCtrl[sideNavId + 'Locked'] = true;

                $mdSidenav(sideNavId).open();
            }

        };

        rootCtrl.changeState = function (stateUrl) {
            $location.path(stateUrl);
            if (!$mdSidenav('sideNavLeft').isLockedOpen()) {
                $mdSidenav('sideNavLeft', true).close();
            }

        };


        rootCtrl.setSideNavRight = function(templatename){
            rootCtrl.sideNavRightUrl = appConfig.sideNavePath[templatename];
            $mdSidenav('sideNavRight').open();
        }

//===========================================
// Toast
//===========================================

        rootCtrl.toast = function (content) {
            var toast = $mdToast.simple().content(content).position('bottom right');
            $mdToast.show(toast);
        };

//===========================================
// Controller Lifecycle
//===========================================

        rootCtrl.$onInit = function () {

            eventAuthLevelsLoaded = $rootScope.$on('event:webAuthSrv-levelsLoaded', function () {

                if (!webAuthSrv.isAuth('authWebOrders')) {

                    rootCtrl.login();
                }

                eventAuthLevelsLoaded();
            });
        };

        deReg1 = $rootScope.$on('event:setSideNavRight', function (event, templatename) {

            rootCtrl.setSideNavRight(templatename);

        });


        deReg10 = rootCtrl.$onDestroy = function () {

            eventAuthLoginRequired();
            deReg1();
            deReg10();
        }

    }
})();
