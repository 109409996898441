(function () {
    'use strict';

    angular
        .module('pdgWp')
        .config(itemsConfig);

    /** @ngInject */
    function itemsConfig($stateProvider) {

        var items = {
            name   : 'items',
            url    : '/items',
            resolve: {
                itemDetails: function () {
                    return {};
                }
            },
            views  : {
                'main@'        : {
                    templateUrl : '/ng-app/admin-app/website-admin/items/item-details-landing.partial.html',
                    controller  : 'ItemDetailsController',
                    controllerAs: 'itemDetailsCtrl'
                }
            }
        };

        var itemDetails = {
            name   : 'items.itemDetails',
            // url    : '/{itItemId}',
            url    : '/{gpGid}',
            resolve: {
                itemDetails: function (itemsSrv, $stateParams, msgSrv) {

                    var configObj = {
                        expandGid   : true,
                        gpStatusFtr : 'all',
                        itStatusFtr : 'all',
                        detailed    : true,
                        // itemsReq    : true,
                        prices      : true,
                        adminOnly   : true,
                        media       : true,
                        gpTitle_en  : true,
                        gpTitle_fr  : true,
                        gpCatalogDescription_en: true,
                        gpCatalogDescription_fr: true,
                        gpDetails_en: true,
                        gpDetails_fr: true, // gpStatusFtr:'1',
                        gpCatalogExtra_en: true,
                        // itStatusFtr: '1',
                        getRow      : true,
                        gpGidFtr: $stateParams.gpGid
                    };

                    configObj.progressBar = 'main@';

                    return itemsSrv.getItems(configObj)
                                   .then(function (itemObj) {
                                       return itemObj;
                                   });
                }
            },
            views  : {
                'main@': {
                    templateUrl : '/ng-app/admin-app/website-admin/items/item-details.partial.html',
                    controller  : 'ItemDetailsController',
                    controllerAs: 'itemDetailsCtrl'
                }
            }
        };

        $stateProvider.state(items);

        $stateProvider.state(itemDetails);
    }

})();
