(function () {
    'use strict';

    angular
        .module('pdgWp')
        .component('itemsMediaDocument', {
            templateUrl: '/ng-app/admin-app/components/items-media-document/items-media-document.partial.html',
            bindings    : {
                items          : '<',
                gpGid          : '<',
                onSelect : '&'
            },
            controller  : itemsMediaDocumentController,
            controllerAs: 'ctrl'
        });

    /** @ngInject */
    function itemsMediaDocumentController($scope, $rootScope, $q, mediaSrv, itemsSrv, webAuthSrv) {

        var ctrl = this;

        ctrl.levels = webAuthSrv.levels;

        ctrl.updateItemMediaOrder = function (index, item, external, type) {

            $scope.$apply(function () {

                ctrl.items.splice(index, 0, item);
            });

            itemsSrv.updateItemMediaOrder({gpMediaJson: ctrl.items})
                    .then(function (itemsObj) {

                    });

            return true;
        };

        ctrl.updateMedia = function (mediaObj, uploadedFile) {

            return mediaSrv.updateMedia(mediaObj, uploadedFile)
                           .then(function successCallback(mediaObj) {

                               // ctrl.getItemMedia(mediaObj.gpGid);

                               return mediaObj;
                           });
        };

        ctrl.inlineInputUpdate = function (value, mediaObj, path ) {
            var tempObj = {};
            tempObj[path] = value;
            mediaObj.mdMetaJson = lodash.merge(mediaObj.mdMetaJson, tempObj);
            ctrl.updateMedia({mdId:mediaObj.mdId, mdMetaJson:mediaObj.mdMetaJson});
        };

        ctrl.edit = function (mediaObj) {
            ctrl.onSelect({'mediaObj': mediaObj});
        };

    } // ===================================================
})();
