(function () {
    'use strict';

    angular
        .module('pdgWp')
        .controller('CatalogLayoutController', CatalogLayoutController);

    /** @ngInject */
    function CatalogLayoutController($log, $rootScope, $state, catalogSrv, itemsSrv, chapterFlow) {

        var catLayoutCtrl = this;

        var tmplObj = {
            'two-columns-page': {
                url   : 'two-columns-page.partial.html',
                layout: [
                    {
                        container: 'leftColumn',
                        maxHeight: 1056
                    }, {
                        container: 'rightColumn',
                        maxHeight: 1056
                    }
                ]
            }
        };

        catLayoutCtrl.flow = chapterFlow;

        catLayoutCtrl.mergeFlow = [];
        catLayoutCtrl.pages = [];
        catLayoutCtrl.loadedItems = [];

        function reFlowPage() {

            var pageMaker = makePage(tmplObj);

            lodash.forEach(catLayoutCtrl.flow, function (category) {

                pageMaker.addItems(lodash.omit(category.catDataJson.flow, ['items']));

                lodash.forEach(category.catDataJson.flow.items, function (item) {
                    pageMaker.addItems(item);

                });
            });

            catLayoutCtrl.pages = pageMaker.getPages();
            var a = catLayoutCtrl.pages;
        }

        reFlowPage();

        function makePage(templatesList) {

            var crntTmplName;
            var crntTmplObj;
            var height;
            var maxHeight;
            var crntItems;
            var crntContainer;
            var crntContainerIndex;
            var crntLayoutObj = {};
            var pages = [];

            function setTemplate(name) {
                crntTmplName = name;
                crntTmplObj = templatesList[crntTmplName];
                crntContainerIndex = 0;
                resetContainer();
            }

            function changeContainer() {

                crntLayoutObj[crntContainer.container] = crntItems;

                if (crntContainerIndex < crntTmplObj.layout.length - 1) {
                    crntContainerIndex++;
                    resetContainer();
                } else {
                    resetPage();
                }


            }

            function resetContainer() {
                crntContainer = crntTmplObj.layout[crntContainerIndex];
                maxHeight = crntContainer.maxHeight;
                height = 0;
                crntItems = [];
            }


            function resetPage() {
                pages.push({
                    layout  : crntLayoutObj,
                    template: crntTmplName
                });
                crntLayoutObj = {};

                crntContainerIndex = 0;
                resetContainer();

            }

            return {
                addItems: function (itemObj) {

                    if (angular.isDefined(itemObj.template)) {

                        if (itemObj.template !== crntTmplName) setTemplate(itemObj.template);

                    }

                    if (angular.isDefined(itemObj.pageBreak)) {

                    }

                    if (height + itemObj.height > maxHeight) {

                        changeContainer();
                    }

                    height += itemObj.height;

                    crntItems.push(itemObj)
                },
                getPages: function () {
                    crntLayoutObj[crntContainer.container] = crntItems;
                    resetContainer();
                    resetPage();
                    return pages;
                }
            }

        }

        // function mergeFlow(){
        //
        //     catLayoutCtrl.mergeFlow = lodash.flatMapDeep(catLayoutCtrl.flow, function(n){
        //
        //         return n.catDataJson.flow;
        //     });
        //
        // }
        //
        // mergeFlow();

        catLayoutCtrl.getPageItems = function () {

            var flatLayout = lodash.flatMapDeep(catLayoutCtrl.flow, function (n) {

                return [[n.catDataJson.flow.items]];
            });

            var items = lodash
                .chain(flatLayout)
                .filter('gpId')
                .map('gpId')
                .join(',')
                .value();

            var configObj = {
                expandGid   : true,
                gpStatusFtr : 'all',
                itStatusFtr : 'all',
                detailed    : true,
                prices      : true,
                limit       : 'all',
                media       : true,
                gpTitle_en  : true,
                gpTitle_fr  : true,
                gpDetails_en: true,
                gpDetails_fr: true,
                gpBatchIdFtr: items
            };

            configObj.progressBar = 'main@';

            return itemsSrv.getItems(configObj)
                           .then(function (itemObj) {

                               catLayoutCtrl.loadedItems = itemObj;

                               return itemObj;
                           });
        };

        catLayoutCtrl.getPageItems();

        catLayoutCtrl.orderLayout = function (index, layoutObj) {

            layoutObj.splice(index, 1);

            // saveCatalogObjDebounce({
            //     catId      : catObj.catId,
            //     catDataJson: catObj.catDataJson
            // });
        };

        catLayoutCtrl.saveCatalogObj = function (catalogObj) {

            catalogObj.progressBar = 'main@';

            return catalogSrv.saveCatalogObj(catalogObj)
                             .then(function successCallback(catalogObj) {

                                 $rootScope.$broadcast('event:queue-message', 'Modifications has been saved');

                                 return catalogObj;
                             }, function () {

                                 // $state.reload();

                             })
        };

        catLayoutCtrl.getCopy = function (item) {

            return angular.copy(item)
        };

        catLayoutCtrl.itemChanged = function (item, itemObj) {

            lodash.remove(item, function (n) {
                return true;
            });

            lodash.merge(item, itemObj);
            saveCatalogObjDebounce({
                catId      : catObj.catId,
                catDataJson: catObj.catDataJson
            })
            // .then(function(){
            //         // $state.reload();
            // },
            //     function(){
            //
            //     });
            // $log.info(item);
        };

        var saveCatalogObjDebounce = lodash.debounce(catLayoutCtrl.saveCatalogObj, 250)

    }
})();
