(function () {
    'use strict';

    angular
        .module('pdgWp')
        .factory('webAuthSrv', webAuthSrv);

    /** @ngInject */
    function webAuthSrv($rootScope, $log, $http, $window, $timeout, appConfig, authService, localStorageSvr) {

        var webAuthSrv = this;

        var eventWebAuthSrvDoLogin, eventWebAuthSrvCancelLogin, eventHttpProviderRequest;

        webAuthSrv.levels = {};

        webAuthSrv.defaultLevel = 'authPublic';

        webAuthSrv.auth = {};

        webAuthSrv.init = function () {

            webAuthSrv.setLevels();

            webAuthSrv.default = {
                isLoggedIn: false,
                token     : false,
                role      : 1,
                data      : {}
            };

            localStorageSvr.register(webAuthSrv, 'auth', webAuthSrv.default);

            webAuthSrv.refreshToken();
        };

        webAuthSrv.setLevels = function () {

            var httpConfig = {
                method: 'get',
                url   : appConfig.accessApiPath + 'levels/'
            };

            return $http(httpConfig)
                .then(function (response) {

                    webAuthSrv.levels = lodash.merge(webAuthSrv.levels, response.data);

                    $rootScope.$broadcast('event:webAuthSrv-levelsLoaded');

                }, function (response) {

                    return webAuthSrv.errorCallback(response)
                })
        };

        webAuthSrv.refreshToken = function (forceRefresh) {


            if (! webAuthSrv.auth.isLoggedIn) return false;
            var created = 1 * webAuthSrv.auth.data.iat;
            var expires = 1 * webAuthSrv.auth.data.exp;
            var tokenHalfLife = Math.round((expires - created) / 2);
            var tsNow = Math.round(Date.now() / 1000);

            if (tsNow > expires) {

                webAuthSrv.clearToken();

                $rootScope.$broadcast('event:webAuthSrv-tokenExpired');
                $rootScope.$broadcast('event:webAuthSrv-statusChanged');

                return false;
            }

            if ((tsNow > expires - tokenHalfLife) || forceRefresh) {

                $http.get(appConfig.accessApiPath + 'refresh')
                     .then(function (response) {

                         webAuthSrv.setToken(response.data.data);

                     }, function () {

                         webAuthSrv.clearToken();
                     });

            } else if(angular.isUndefined($http.defaults.headers.common.Authorization)){

                webAuthSrv.setToken(webAuthSrv.auth.token);
            }

            // $log.debug('Token was refresh requested...');

            return true;
        };

        webAuthSrv.setToken = function (tokenData) {

            var decoded = jwt_decode(tokenData);
            webAuthSrv.auth.isLoggedIn = true;
            webAuthSrv.auth.role = decoded.authRoleId;
            webAuthSrv.auth.token = tokenData;
            webAuthSrv.auth.data = decoded;

            localStorageSvr.set('auth', webAuthSrv.auth);

            $http.defaults.headers.common.Authorization = 'Bearer ' + tokenData;

            // $log.info('Token was set', webAuthSrv.auth.data);

        };

        webAuthSrv.clearToken = function () {

            delete $http.defaults.headers.common.Authorization;

            lodash.unset(webAuthSrv.auth, 'data');

            webAuthSrv.auth = lodash.merge(webAuthSrv.auth, webAuthSrv.default);

            localStorageSvr.set('auth', webAuthSrv.auth);

            authService.loginCancelled();

            $rootScope.$broadcast('event:webAuthSrv-statusChanged');

            $log.info('Token was cleared');

        };

        webAuthSrv.logout = function () {

            var eventData = angular.copy(webAuthSrv.auth);

            webAuthSrv.clearToken();

            var httpConfig = {
                method: 'GET',
                url   : appConfig.accessApiPath + 'logout/'
            };

            return $http(httpConfig)
                .then(function (response) {

                    $rootScope.$broadcast('event:webAuthSrv-logout', eventData);

                    $rootScope.$broadcast('event:webAuthSrv-statusChanged');

                    return response;

                }, function (response) {

                    return response;
                });
        };

        webAuthSrv.cancelLogin = function () {

            authService.loginCancelled();

        };

        webAuthSrv.doLogin = function (event, loginObj) {

            if (angular.isUndefined(loginObj.username) || angular.isUndefined(loginObj.password)) {

                return webAuthSrv.cancelLogin();
            }

            var httpConfig = {
                method: 'POST',
                // withCredentials: true,
                url   : appConfig.accessApiPath + 'login/',
                data  : {
                    usPassword: loginObj.password,
                    usEmail   : loginObj.username
                }
            };

            return $http(httpConfig)
                .then(function (response) {

                    webAuthSrv.setToken(response.data.data);

                    var token = $http.defaults.headers.common.Authorization;

                    authService.loginConfirmed('success', function(config){

                        config.headers["Authorization"] = token;

                        return config;
                    });

                    $rootScope.$broadcast('event:webAuthSrv-loginSuccessful');

                    $rootScope.$broadcast('event:webAuthSrv-statusChanged');

                    return {username:loginObj.username, password:loginObj.password}

                }, function (response) {

                    $rootScope.$broadcast('event:auth-loginRequired', response.data.messages[0].message);

                    return Promise.reject(response);


                })
        };

        webAuthSrv.wpLogin = function (username, password) {
            var winName='WPLogin';
            var winURL= appConfig.baseUrl + 'wp-login.php';
            var windowoption='';
            var params = { 'log' : username,'pwd' :password};
            var form = document.createElement("form");
            form.setAttribute("method", "post");
            form.setAttribute("action", winURL);
            form.setAttribute("target",winName);
            for (var i in params) {
                if (params.hasOwnProperty(i)) {
                    var input = document.createElement('input');
                    input.type = 'hidden';
                    input.name = i;
                    input.value = params[i];
                    form.appendChild(input);
                }
            }
            document.body.appendChild(form);
            $window.open('', winName,windowoption);
            form.target = winName;
            form.submit();
            document.body.removeChild(form);
        };

        webAuthSrv.isAuth = function (level, role) {

            if(angular.isUndefined(role)){

                role = webAuthSrv.auth.role;

                if (angular.isUndefined(webAuthSrv.auth.isLoggedIn) || ! webAuthSrv.auth.isLoggedIn) return false
            }

            level = (angular.isUndefined(level)) ? webAuthSrv.defaultLevel : level;

            return Boolean(webAuthSrv.levels[level] & role);
        };

        eventHttpProviderRequest = $rootScope.$on('event:httpProvider-request', function(event, configObj){

            if(configObj.url.indexOf(appConfig.accessApiPath) === -1) webAuthSrv.refreshToken();
        });

        return webAuthSrv;

    }

})();
